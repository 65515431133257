import React, { FC, ReactNode } from "react";
import * as s from "./Step-royals-styles";

export type StepRoyalsType = {
  className?: string;
  image: ReactNode;
  index?: number;
  firstWord: string | ReactNode;
  text: string | ReactNode;
};

export const StepRoyals: FC<StepRoyalsType> = ({
  className,
  image,
  index,
  firstWord,
  text,
}) => {
  return (
    <s.StepRoyals className={className}>
      <s.IndexShape>
        <s.Icon>{image}</s.Icon>
        <s.Index>{index}</s.Index>
      </s.IndexShape>

      <s.Item key={index}>
        <s.Content>
          <s.FirstWord>{firstWord}</s.FirstWord>
          <br />
          <s.Text>{text}</s.Text>
        </s.Content>
      </s.Item>
    </s.StepRoyals>
  );
};
