import React, {FC} from "react";

export const IconCalendar: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    fill="none"
    {...props}
  >
    <rect width={36} height={36} fill="#EEE" rx={10}/>
    <path
      stroke="#444"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M14 8v3M22 8v3M9.5 15.09h17M27 14.5V23c0 3-1.5 5-5 5h-8c-3.5 0-5-2-5-5v-8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5Z"
    />
    <path
      stroke="#444"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21.695 19.7h.009M21.695 22.7h.009M17.995 19.7h.01M17.995 22.7h.01M14.294 19.7h.01M14.294 22.7h.01"
    />
  </svg>
);
