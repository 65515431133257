import {
  type ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { type NoInfer } from "react-redux";
import {
  initialStatePet as initialState,
  PetStateType,
  PetType,
} from "../type/pet-type";
import { getPetsThunk, updatePetsThunk } from "../thunk/pet-thunk";

const petSlice = createSlice({
  name: "pet",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<any>>) => {
    builder.addCase(
      getPetsThunk.fulfilled.type,
      (state: PetStateType, action: PayloadAction<Array<PetType>>) => {
        state.pets = action.payload;
        state.isPetsYoung = action.payload.some(
          (pet) => pet.time_to_adult.months >= 0
        );
        state.isPetsAdult = action.payload.some(
          (pet) => pet.time_to_adult.months < 0
        );
      }
    );

    builder.addCase(
      updatePetsThunk.fulfilled.type,
      (state: PetStateType, action: PayloadAction<PetType>) => {
        state.pets = state.pets.map((el) =>
          el.id === action.payload.id ? action.payload : el
        );
      }
    );
  },
  selectors: {
    selectPet: (state) => state,
    selectPets: (state) => state.pets,
  },
});

export const { reducer } = petSlice;
export const petReducer = reducer;
export const { selectPet, selectPets } = petSlice.selectors;
