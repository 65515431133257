import styled, {css} from "styled-components";
import {ButtonIcon, ButtonText, ButtonTextIcon, IconArrowRow} from "../../atom";
import {breakpoints} from "../../../config";

type CardInviteType = {
  $isActive: boolean;
};

const MobileButtonText = styled(ButtonText)`
    font: var(--font-16);
    color: var(--text);
    text-align: start;
    letter-spacing: -0.32px;
`;

const IconArrow = styled(IconArrowRow)`
    width: 20px;
    transform: scaleX(-1);
    transition: ease-in-out 300ms;
`;

const CardInvite = styled.article<CardInviteType>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 36px 24px;
    background-color: var(--light-pink);
    border-radius: 40px;

    @media (max-width: ${breakpoints.XXL}) {
        padding: 24px;
        border-radius: 36px;
    }

    @media (max-width: ${breakpoints.XL}) {
        padding: 32px 28px;
    }

    @media (max-width: ${breakpoints.LG}) {
        align-items: center;
        padding: 24px;
        background: linear-gradient(92.18deg, #FFCCE1 1.63%, #FFF1F7 117.74%);

        &:hover ${MobileButtonText} {
            color: 2px solid var(--brand-2);
        }

        &:hover ${IconArrow} {
            color: 2px solid var(--brand-2);
        }

        &:active ${IconArrow} {
            transform: scaleX(-0.9) scaleY(0.9);
        }

        & svg {
            color: var(--iconography);
        }
    }

    @media (max-width: ${breakpoints.XL}) {
        padding: 20px;
        border-radius: 32px;
    }

    ${(props) =>
            props.$isActive &&
            css`
                transition: transform 300ms ease;
                cursor: pointer;

                &:active {
                    transform: scale(0.98);
                }
            `}
`;

const ButtonCardIcon = styled(ButtonIcon)`
    position: absolute;
    top: 16px;
    right: 16px;
    color: #44444499;

    @media (max-width: ${breakpoints.LG}) {
        display: none;
    }

    &:hover {
        color: var(--hyperlink);
    }
`;

const IconRoyal = styled(ButtonTextIcon)`
    margin-bottom: 16px;

    @media (max-width: ${breakpoints.LG}) {
        margin-bottom: 10px;
    }
`

const Title = styled.h4`
    margin-bottom: 36px;
    font: var(--font-4);
    letter-spacing: -1.68px;
    color: var(--interface-dark);

    @media (max-width: ${breakpoints.XL}) {
        margin-bottom: 40px;
    }

    @media (max-width: ${breakpoints.LG}) {
        margin-bottom: 24px;
        letter-spacing: -0.44px;
        text-align: center;
    }
`;

const MobileButtonWrap = styled.div`
    display: flex;
    align-items: center;
    margin-top: auto;
`;

const List = styled.ul`
    display: grid;
    grid-auto-rows: var(--minmax);
    gap: 12px;
`;

const Item = styled.li``;

export {
  CardInvite,
  ButtonCardIcon,
  Title,
  IconRoyal,
  MobileButtonWrap,
  MobileButtonText,
  IconArrow,
  List,
  Item,
};
