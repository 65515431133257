import styled from "styled-components";
import {
  Animation,
  BannerProfileInfo,
  IconInfo,
  Status,
} from "../../../shared";
import { breakpoints, Container } from "../../../shared/config";

const PersonalAreaCheks = styled.section`
  display: grid;
  grid-template-columns: 390px 1fr;
  gap: 64px;

  @media (max-width: ${breakpoints.XXL}) {
    grid-template-columns: 340px 1fr;
    gap: 78px;
  }

  @media (max-width: ${breakpoints.XL}) {
    grid-template-columns: 300px 1fr;
    gap: 48px;
  }

  @media (max-width: ${breakpoints.LG}) {
    grid-template-columns: 1fr;
    grid-auto-rows: var(--minmax);
    gap: 4px;
  }

  @media (max-width: ${breakpoints.MD}) {
    grid-template-columns: 1fr;
    gap: 28px;
    margin-bottom: 60px;
  }
`;

const BannerInfo = styled(BannerProfileInfo)`
  margin-bottom: 60px;

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 40px;
  }
`;

const PersonalAreaContainer = styled(Container)``;

const Title = styled.h2`
  margin-bottom: 30px;
  font: var(--font-3);
  color: var(--interface-dark);

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 20px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 24px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 20x;
  }
`;

const BoldText = styled.span`
  font: var(--font-32);
  color: var(--interface-dark);
`;

const ReceiptList = styled.ul``;

const ReceiptWrap = styled(Animation)`
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid var(--inactive);

  @media (max-width: ${breakpoints.MD}) {
    justify-content: space-between;
  }
`;

const ReceiptItem = styled.li`
  &:last-of-type {
    ${ReceiptWrap} {
      border-bottom: 1px solid transparent;
    }
  }
`;

const ReceiptItemDate = styled.p`
  font: var(--font-15);
  color: var(--interface-dark);
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: ${breakpoints.MD}) {
    align-items: end;
  }
`;

const ReceiptItemBonus = styled.div`
  font: var(--font-16);
  letter-spacing: -0.36px;
  display: flex;
  align-items: center;
  margin-right: 48px;
  margin-left: auto;

  p {
    margin-right: 8px;
  }
`;

const StatusCheck = styled(Status)`
  width: 166px;

  @media (max-width: ${breakpoints.MD}) {
    width: 144px;
  }
`;

const IconInfoBonus = styled(IconInfo)`
  cursor: pointer;
  color: currentColor;
`;

const IconInfoBonusTooltip = styled.div`
  font: var(--font-16);
  position: absolute;
  bottom: 30px;
  left: -70px;
  display: flex;
  align-items: center;
  width: max-content;
  padding: 4px 12px 4px 12px;
  background-color: var(--brand-3);
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);

  span {
    margin-right: 4px;
  }
`;

const IconErrorInfoTooltip = styled(IconInfoBonusTooltip)`
  position: absolute;
  top: -50px;
  right: 0;
  left: auto;

  @media (max-width: ${breakpoints.MD}) {
    max-width: 320px;
  }
`;

const ReceiptListMoreButton = styled.button`
  font: var(--font-14);
  margin-top: 24px;
`;

const IconInfoButton = styled.button`
  position: relative;
  height: 24px;
  cursor: pointer;
  color: currentColor;

  @media (max-width: ${breakpoints.MD}) {
    width: 16px;
    height: 16px;
  }
`;

export {
  PersonalAreaCheks,
  PersonalAreaContainer,
  BannerInfo,
  Title,
  BoldText,
  ReceiptList,
  ReceiptItem,
  ReceiptWrap,
  ReceiptItemDate,
  ReceiptItemBonus,
  StatusCheck,
  IconInfoBonus,
  IconInfoBonusTooltip,
  IconErrorInfoTooltip,
  ReceiptListMoreButton,
  IconInfoButton,
};
