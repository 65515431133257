import React, {FC} from "react";
import * as s from "./Modal-check-email-styles";

type ModalCheckEmailType = {
  className?: string;
  text?: string
  onShowModal: () => void;
};

export const ModalCheckEmail: FC<ModalCheckEmailType> = ({className, text, onShowModal}) => {
  return (
    <s.ModalCheckEmail className={className}>
      <s.Modal onClickButtonBg={onShowModal} onClickButtonIcon={onShowModal}>
        <s.Title>
          <s.Icon/>
          {text}</s.Title>
      </s.Modal>
    </s.ModalCheckEmail>
  );
};
