import styled, { css } from "styled-components";
import { IconRoyals } from "../_icons";
import { breakpoints } from "../../../config";
import ticketRoyalsFrame from "../../../config/assets/images/ticket-royals-frame.svg";

const BodyStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  overflow: hidden;
  will-change: transform;
  transition: transform 500ms ease;

  @media (max-width: ${breakpoints.MD}) {
    position: relative;
    border-radius: 26px;
  }
`;

const Body = styled.div`
  ${BodyStyle};
  padding: 50px 40px;
  background-color: var(--pink);

  @media (max-width: ${breakpoints.XXL}) {
    padding: 50px 32px;
  }

  @media (max-width: ${breakpoints.XL}) {
    padding: 30px 26px 32px 32px;
  }

  @media (max-width: ${breakpoints.LG}) {
    padding: 20px;
  }

  @media (max-width: ${breakpoints.MD}) {
    min-height: 150px;
    padding: 39px 20px;
  }

  & svg {
    z-index: -5;
  }
`;

const CardRoyals = styled.article`
  display: block;
  position: relative;
  width: 100%;
  min-height: 188px;
  border-radius: 32px;
  cursor: pointer;
  transition: ease-in-out 300ms;

  @media (max-width: ${breakpoints.XL}) {
    min-height: 220px;
  }

  @media (max-width: ${breakpoints.LG}) {
    min-height: 210px;
  }

  @media (max-width: ${breakpoints.MD}) {
    min-height: 150px;
  }

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.99);
  }
`;

const Title = styled.h3`
  font: var(--font-8);
  letter-spacing: -0.48px;
  color: var(--interface-dark);
  max-width: 211px;
  text-align: start;
  z-index: 1;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 100%;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 74%;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: 50%;
    min-width: 175px;
  }
`;

const WrapCaption = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  padding-left: 30px;
  width: 197px;
  height: 112px;
  background-image: url(${ticketRoyalsFrame});
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: ${breakpoints.XXL}) {
    padding-left: 22px;
    width: 177px;
    height: 100px;
  }

  @media (max-width: ${breakpoints.LG}) {
    width: 160px;
    height: 87px;
  }

  & svg {
    z-index: 0;
  }
`;

const Caption = styled.div`
  font: var(--font-31);
  color: var(--brand-1);
`;

const Icon = styled(IconRoyals)`
  width: 55px;
  height: 55px;

  @media (max-width: ${breakpoints.XXL}) {
    width: 47px;
    height: 47px;
  }

  @media (max-width: ${breakpoints.LG}) {
    width: 42px;
    height: 42px;
  }
`;

export { CardRoyals, Body, Title, WrapCaption, Caption, Icon };
