export enum ModalsProperty {
  MODAL_CONFIRMATION_EMAIL = "Modal-confirmation-email",
  MODAL_CONFIRMATION_PHONE = "Modal-confirmation-phone",
  MODAL_CONFIRMATION_PHONE_ANON = "Modal-confirmation-phone-anon",
  MODAL_CONFIRMATION_SUCCESSFULLY = "Modal-confirmation-successfully",
  MODAL_CONFIRMATION_SUCCESSFULLY_ANON = "Modal-confirmation-successfully-anon",
  MODAL_SEND_PHONE = "Modal-send-phone",
  MODAL_SEND_PHONE_ANON = "Modal-send-phone-anon",
  MODAL_SUCCESSFULLY_REGISTERED = "Modal-successfully-registered",
  MODAL_REF_EMAIL_FRIEND = "Modal-ref-email-friend",
  MODAL_CONFIRMATION_REF_EMAIL_FRIEND = "Modal-confirmation-ref-email-friend",
  MODAL_ABOUT_INVITATION_ANIMAL = "Modal-about-invitation-animal",
  MODAL_ABOUT_INVITATION_SOCIAL_MEDIA = "Modal-about-invitation-social-media",
  MODAL_ABOUT_INVITATION_ANIMAL_BUTTONS = "Modal-about-invitation-animal-buttons",
  MODAL_ABOUT_INVITATION_SOCIAL_MEDIA_BUTTONS = "Modal-about-invitation-social-media-buttons",
  MODAL_HISTORY_OPERATIONS = "Modal-history-operations",
  MODAL_ADD_ANIMAL = "Modal-add-animal",
  MODAL_FEEDING_TYPES_NATURAL_NEEDS = "Modal-feeding-types-natural-needs",
  MODAL_FEEDING_TYPES_OPTIMAL_WEIGHT = "Modal-feeding-types-optimal-weight",
  MODAL_FEEDING_TYPES_UROLITHIASIS = "Modal-feeding-types-urolithiasis",
  MODAL_FEEDING_TYPES_DIABETES = "Modal-feeding-types-diabetes",
  MODAL_FEEDING_TYPES_OLD_PETS = "Modal-feeding-types-old-pets",
  MODAL_ADDITIONAL_ROYALS_HEART = "Modal-additional-royals-heart",
  MODAL_ADDITIONAL_ROYALS_WINGS = "Modal-additional-royals-wings",
  MODAL_ADDITIONAL_ROYALS_STAR = "Modal-additional-royals-star",
  MODAL_ADDITIONAL_ROYALS = "Modal-additional-royals",
  MODAL_RECEIPT = "Modal-receipt",
  MODAL_PROMO_CODE = "Modal-promo-code",
  MODAL_PROMO_CODE_TYPE = "Modal-promo-code-type",
  MODAL_QUESTION = "Modal-question",
  MODAL_QUESTION_SENT = "Modal-question-sent",
  MODAL_FREQUENTLY_ASKED_QUESTION_QUESTION = "Modal-frequently-asked-question",
  MODAL_EXIT = "Modal-exit",
  MODAL_CHECK_EMAIL = "Modal-check-email",
  MODAL_CHECK_PHONE = "Modal-check-phone",
  MODAL_CHECK_EMAIL_PHONE = "Modal-check-email-phone",
  MODAL_ERROR_IMAGE = "Modal-error-image",
}
