import styled from "styled-components";
import { PopupBody } from "../../../../shared";
import { breakpoints } from "../../../../shared/config";
import {
  BodyAnimation,
  ButtonIconBody,
} from "../../../../shared/ui/molecules/popup-body/Popup-body-styles";

const ModalFeedingTypes = styled.div``;

const Modal = styled(PopupBody)`
  ${BodyAnimation} {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
    padding: 40px 40px 24px;
    max-width: 616px;
    min-height: 288px;
    border-radius: 32px;
    box-shadow: 0 145px 41px 0 rgba(0, 0, 0, 0),
      0 93px 37px 0 rgba(0, 0, 0, 0.01), 0 52px 31px 0 rgba(0, 0, 0, 0.02),
      0 23px 23px 0 rgba(0, 0, 0, 0.03), 0 6px 13px 0 rgba(0, 0, 0, 0.04);
  }

  @media (max-width: ${breakpoints.XXL}) {
    ${BodyAnimation} {
      max-width: 576px;
      min-height: 272px;
    }
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      gap: 24px;
      padding: 56px 56px 70px;
      max-width: 100%;
      border-radius: 40px 40px 0 0;
      box-shadow: none;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    ${BodyAnimation} {
      gap: 15px;
      padding: 55px 20px 30px;
      border-radius: 28px 28px 0 0;
    }

    ${ButtonIconBody} {
      top: 16px;
      right: 16px;
    }
  }
`;

const List = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 480px;

  @media (max-width: ${breakpoints.XXL}) {
    max-width: 450px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 90%;
    gap: 24px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
    gap: 15px;
  }
`;

const Item = styled.li``;

const Paragraph = styled.p`
  font: var(--font-10);
  color: var(--text);

  & span {
    font: var(--font-12);
    color: var(--interface-dark);
  }
`;

const Footnote = styled.p`
  font: var(--font-19);
  color: var(--brand-2);
  max-width: 350px;

  @media (max-width: ${breakpoints.LG}) {
    max-width: none;
  }
`;

export { ModalFeedingTypes, Modal, List, Item, Paragraph, Footnote };
