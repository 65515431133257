import styled from "styled-components";
import {breakpoints} from "../../../shared/config";
import {FormPets, FormUser, FormUserPassword} from "../../../widgets";

const Registration = styled.section`
    padding-bottom: 120px;
    --input-width: 450px;

    @media (max-width: ${breakpoints.XXL}) {
        padding-bottom: 100px;
    }

    @media (max-width: ${breakpoints.XL}) {
        --input-width: 400px;
    }

    @media (max-width: ${breakpoints.LG}) {
        padding-top: 16px;
        padding-bottom: 80px;
        --input-width: 380px;
    }

    @media (max-width: ${breakpoints.MD}) {
        padding-bottom: 60px;
        --input-width: 450px;
    }
`;

const ImageBg = styled.img`
    position: absolute;
    top: 110px;
    right: 0;
    z-index: -1;
    width: 588px;
    height: 737px;

    @media (max-width: ${breakpoints.XXL}) {
        width: 464px;
        height: 599px;
    }

    @media (max-width: ${breakpoints.XL}) {
        right: 0;
        width: 355px;
        height: 452px;
    }

    @media (max-width: ${breakpoints.LG}) {
        display: none;
    }
`;

const Title = styled.h2`
    margin-bottom: 40px;
    font: var(--font-3);
    color: var(--brand-1);

    @media (max-width: ${breakpoints.MD}) {
        margin-bottom: 32px;
    }
`;

const FormRegUser = styled(FormUser)`
    margin-bottom: 100px;

    @media (max-width: ${breakpoints.XL}) {
        margin-bottom: 80px;
    }

    @media (max-width: ${breakpoints.LG}) {
        margin-bottom: 70px;
    }
`;

const Text = styled.p`
    font: var(--font-19);
    color: var(--brand-2);
`;

const FormAnimal = styled(FormPets)`
    margin-bottom: 85px;

    @media (max-width: ${breakpoints.XL}) {
        margin-bottom: 80px;
    }

    @media (max-width: ${breakpoints.MD}) {
        margin-bottom: 60px;
    }
`;

const FormPassword = styled(FormUserPassword)``;

export {
  Registration,
  ImageBg,
  Title,
  FormRegUser,
  Text,
  FormAnimal,
  FormPassword,
};
