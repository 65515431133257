const getNumberYear = (year: number) => {
  if (year < 0) {
    return ``;
  }

  switch (year) {
    case 0:
      return "";
    case 1:
      return "1 год ";
    case 2:
      return "2 года ";
    case 3:
      return "3 года ";
    case 4:
      return "4 года ";
    case 5:
      return "5 лет ";
    default:
      return `${year} лет `;
  }
};

const getNumberMonth = (month: number) => {
  if (month < 0) {
    return ``;
  }

  switch (month) {
    case 0:
      return "";
    case 1:
      return "1 месяц";
    case 2:
      return "2 месяца";
    case 3:
      return "3 месяца";
    case 4:
      return "4 месяца";
    case 5:
      return "5 месяцев";
    default:
      return `${month} месяцев`;
  }
};

export {getNumberYear, getNumberMonth};
