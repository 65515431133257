import styled, {css} from "styled-components";
import {breakpoints, Container} from "../../../shared/config";
import {
  BannerCardProfile,
  BannerProfileCat,
  BannerProfileInfo,
  ButtonCardAdd,
  ButtonTextIcon,
  CardInvite,
  CardWallet,
  IconOk,
  IconPromosCard,
  IconRoyalsBig,
  IconUserAdd,
  IconVk,
} from "../../../shared";

import imageArrow from "./../../../shared/config/assets/images/icons/arrow.svg";
import imageBannerCardSmall from "./../../../shared/config/assets/images/banner-card-profile-small.png";
import imageBannerCardSmallXxl from "./../../../shared/config/assets/images/banner-card-profile-small-xxl.png";
import imageBannerCardSmallXl from "./../../../shared/config/assets/images/banner-card-profile-small-xl.png";
import imageBannerCardSmallLg from "./../../../shared/config/assets/images/banner-card-profile-small-lg.png";
import imageBannerCardSmallMd from "./../../../shared/config/assets/images/banner-card-profile-small-md.png";
import imageBannerCardBig from "./../../../shared/config/assets/images/banner-card-profile-big.png";
import imageBannerCardBigXxl from "./../../../shared/config/assets/images/banner-card-profile-big-xxl.png";
import imageBannerCardBigXl from "./../../../shared/config/assets/images/banner-card-profile-big-xl.png";
import imageBannerCardBigLg from "./../../../shared/config/assets/images/banner-card-profile-big-lg.png";
import imageBannerCardBigMd from "./../../../shared/config/assets/images/banner-card-profile-big-md.png";
import bannerImageCat from "./../../../shared/config/assets/images/icons/banner-image-cat-1.svg"
import bannerImageCatXxl from "./../../../shared/config/assets/images/icons/banner-image-cat.svg"
import bannerImageCatXl from "./../../../shared/config/assets/images/icons/banner-image-cat-xl.svg"
import bannerImageCatLg from "./../../../shared/config/assets/images/icons/banner-image-cat-lg.svg"
import bannerImageCatMd from "./../../../shared/config/assets/images/icons/banner-image-cat-md.svg"
import * as s from "./../../../shared/ui/atom/banner-profile-cat/Banner-profile-cat-styles";

const PersonalAreaProfile = styled.section`
    display: grid;
    grid-template-columns: 390px 1fr;
    gap: 54px;

    @media (max-width: ${breakpoints.XXL}) {
        grid-template-columns: 340px 1fr;
    }

    @media (max-width: ${breakpoints.XL}) {
        grid-template-columns: 300px 1fr;
        gap: 48px;
    }

    @media (max-width: ${breakpoints.LG}) {
        grid-template-columns: 1fr;
        grid-auto-rows: var(--minmax);
        gap: 32px;
    }

    @media (max-width: ${breakpoints.MD}) {
        grid-template-columns: 1fr;
        gap: 70px;
    }
`;

const PersonalAreaContainer = styled(Container)``;

const WrapFooter = styled.div``;

const Footer = styled.footer`
    margin-top: 54px;

    @media (max-width: ${breakpoints.XXL}) {
        margin-top: 24px;
    }

    @media (max-width: ${breakpoints.XL}) {
        margin-top: 32px;
    }

    @media (max-width: ${breakpoints.LG}) {
        margin-top: 60px;
    }

    @media (max-width: ${breakpoints.MD}) {
        margin-top: 50px;
    }
`;

const List = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    @media (max-width: ${breakpoints.LG}) {
        display: flex;
        gap: 12px;
    }

    @media (max-width: ${breakpoints.MD}) {
        flex-direction: column;
    }
`;

const Item = styled.li`
    @media (max-width: ${breakpoints.LG}) {
        width: 100%;
    }
`;

const Card = styled(CardInvite)``;

const IconButtonVk = styled(IconVk)`
    width: 32px;
    height: 32px;
    color: var(--blue-icon-1);
`;

const IconButtonOk = styled(IconOk)`
    width: 32px;
    height: 32px;
    color: var(--blue-icon-1-1);
`;

const IconUser = styled(IconUserAdd)`
    width: 32px;
    height: 32px;
    color: var(--brand-1);
`;

const WrapContent = styled.div``;

const BannerInfo = styled(BannerProfileInfo)`
    margin-bottom: 40px;

    @media (max-width: ${breakpoints.XXL}) {
        margin-bottom: 36px;
    }

    @media (max-width: ${breakpoints.XL}) {
        margin-bottom: 24px;
    }
`;

const MyWallet = styled.section`
    padding: 36px;
    margin-bottom: 60px;
    border-radius: 48px;
    background: linear-gradient(93.16deg, #fccee1 0.38%, #fb6ba6 101.63%);

    @media (max-width: ${breakpoints.XXL}) {
        padding: 30px;
    }

    @media (max-width: ${breakpoints.XL}) {
        padding: 30px 24px;
    }

    @media (max-width: ${breakpoints.LG}) {
        margin-bottom: 80px;
    }

    @media (max-width: ${breakpoints.MD}) {
        padding: 24px 16px;
        margin-bottom: 70px;
        border-radius: 40px;
    }
`;

const Title = styled.h2`
    margin-bottom: 24px;
    font: var(--font-9);
    color: var(--interface-dark);

    @media (max-width: ${breakpoints.XL}) {
        margin-bottom: 32px;
    }

    @media (max-width: ${breakpoints.MD}) {
        margin-bottom: 24px;
    }
`;

const WalletList = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media (max-width: ${breakpoints.XL}) {
        gap: 16px;
    }

    @media (max-width: ${breakpoints.MD}) {
        grid-template-columns: 1fr;
    }
`;

const WalletItem = styled.li``;

const WalletItemButton = styled.button`
    width: 100%;
    height: 100%;
    display: block;
    will-change: transform;
    transition: transform 300ms ease;

    &:hover {
        transform: scale(1.02);
    }

    &:active {
        transform: scale(0.98);
    }
`;

const WalletCard = styled(CardWallet)``;

const IconStyle = css`
    width: 164px;
    transform: rotate(-10deg);

    @media (max-width: ${breakpoints.XXL}) {
        width: 168px;
    }

    @media (max-width: ${breakpoints.XL}) {
        width: 156px;
    }

    @media (max-width: ${breakpoints.LG}) {
        width: 170px;
    }

    @media (max-width: ${breakpoints.MD}) {
        width: 150px;
    }
`;

const IconWalletCardRoyals = styled(IconRoyalsBig)`
    position: absolute;
    bottom: -50px;
    right: -34px;
    z-index: 0;
    ${IconStyle};
`;
const IconWalletCardPromos = styled(IconPromosCard)`
    position: absolute;
    bottom: -50px;
    right: -29px;
    z-index: 0;
    ${IconStyle};

    @media (max-width: ${breakpoints.XXL}) {
    }

    @media (max-width: ${breakpoints.XL}) {
    }

    @media (max-width: ${breakpoints.LG}) {
    }

    @media (max-width: ${breakpoints.MD}) {
    }
`;

const MyAnimal = styled.section`
    margin-bottom: 40px;
    padding: 36px;
    background-color: var(--brand-4);
    border-radius: 40px;

    @media (max-width: ${breakpoints.XXL}) {
        margin-bottom: 36px;
        padding: 30px;
        border-radius: 48px;
    }

    @media (max-width: ${breakpoints.XL}) {
        padding: 32px 24px;
        margin-bottom: 24px;
    }

    @media (max-width: ${breakpoints.LG}) {
        margin-bottom: 32px;
    }

    @media (max-width: ${breakpoints.MD}) {
        padding: 24px 16px;
        border-radius: 40px;
        margin-bottom: 40px;
    }
`;

const AnimalList = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: var(--minmax);
    gap: 20px;

    @media (max-width: ${breakpoints.XL}) {
        gap: 16px;
    }

    @media (max-width: ${breakpoints.MD}) {
        grid-template-columns: 1fr;
    }
`;

const AnimalItem = styled.li``;

const ButtonCardAddPet = styled(ButtonCardAdd)`
    max-width: 100%;
`;

const ItemAddPet = styled.li`
    position: relative;

    &::before {
        content: "";
        position: absolute;
        bottom: 31px;
        left: 27px;
        width: 195px;
        height: 75px;
        background: url(${imageArrow}) no-repeat center center/100%;
    }

    @media (max-width: ${breakpoints.XXL}) {
        &::before {
            bottom: 32px;
            left: 11px;
        }
    }

    @media (max-width: ${breakpoints.XL}) {
        &::before {
            bottom: 11px;
            left: 7px;
            width: 159px;
            height: 83px;
        }
    }

    @media (max-width: ${breakpoints.LG}) {
        &::before {
            bottom: 22px;
            left: 41px;
        }
    }

    @media (max-width: ${breakpoints.MD}) {
        &::before {
            display: none;
        }
    }
`;

const WrapAddPet = styled.div`
    padding-top: 48px;
    padding-left: 85px;

    @media (max-width: ${breakpoints.XXL}) {
        padding-top: 31px;
        padding-left: 69px;
    }

    @media (max-width: ${breakpoints.XL}) {
        padding-top: 35px;
        padding-left: 25px;
    }

    @media (max-width: ${breakpoints.LG}) {
        padding-top: 25px;
        padding-left: 59px;
    }

    @media (max-width: ${breakpoints.MD}) {
        padding-top: 24px;
        padding-left: unset;
        text-align: center;
    }
`;

const TitleAddPet = styled.h2`
    margin-bottom: 12px;
    font: var(--font-8);
    color: var(--interface-dark);
`;

const TextAddPet = styled.p`
    font: var(--font-18);
    color: var(--hyperlink);
`;

const Wrap = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    @media (max-width: ${breakpoints.MD}) {
        justify-content: center;
    }
`;

const Icon = styled(ButtonTextIcon)`
    display: inline-flex;
    cursor: unset;
`;

const Banners = styled.section``;

const BannersList = styled.ul`
    display: grid;
    grid-template-columns: 0.78fr 0.52fr;
    gap: 16px;

    @media (max-width: ${breakpoints.XXL}) {
        grid-template-columns: 0.59fr 0.5fr;
    }

    @media (max-width: ${breakpoints.XL}) {
        grid-template-columns: 1fr;
        gap: 12px;
    }

    @media (max-width: ${breakpoints.LG}) {
        gap: 16px;
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: ${breakpoints.MD}) {
        row-gap: 16px;
        column-gap: 8px;
    }
`;

const BannersItem = styled.li`
    &:nth-of-type(1) {
        grid-row: 1/2;
    }

    &:nth-of-type(2) {
        grid-row: 1/3;
    }

    &:nth-of-type(3) {
        grid-row: 2/3;
    }

    @media (max-width: ${breakpoints.XL}) {
        &:nth-of-type(1) {
            grid-column: 1/2;
            grid-row: 1/2;
        }

        &:nth-of-type(2) {
            grid-row: 3/4;
            grid-column: 1/2;
        }

        &:nth-of-type(3) {
            grid-row: 2/3;
            grid-column: 1/2;
        }
    }

    @media (max-width: ${breakpoints.LG}) {
        &:nth-of-type(1) {
            grid-row: 1/2;
            grid-column: 1/2;
        }

        &:nth-of-type(2) {
            grid-row: 2/3;
            grid-column: 1/3;
        }

        &:nth-of-type(3) {
            grid-row: 1/2;
            grid-column: 2/3;
        }
    }
`;

const BannerOne = styled(BannerCardProfile)`
    position: relative;
    overflow: hidden;
    background: linear-gradient(97deg, #ffe1ee 1.9%, #f2efff 56.58%);

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        width: 293px;
        height: 260px;

        background: url(${imageBannerCardSmall}) no-repeat top right/100%;
    }

    @media (max-width: ${breakpoints.XXL}) {
        &::before {
            width: 274px;
            background: url(${imageBannerCardSmallXxl}) no-repeat top right/100%;
        }
    }

    @media (max-width: ${breakpoints.XL}) {
        &::before {
            width: 343px;
            height: 192px;
            background: url(${imageBannerCardSmallXl}) no-repeat top right/100%;
        }
    }

    @media (max-width: ${breakpoints.LG}) {
        &::before {
            width: 217px;
            height: 182px;
            background: url(${imageBannerCardSmallLg}) no-repeat top right/100%;
        }
    }

    @media (max-width: ${breakpoints.MD}) {
        &::before {
            width: 172px;
            height: 161px;
            left: 50%;
            bottom: unset;
            top: 50px;
            transform: translateX(-50%);
            background: url(${imageBannerCardSmallMd}) no-repeat top right/100%;
        }
    }
`;

const BannerTwo = styled(BannerCardProfile)`
    position: relative;
    overflow: hidden;
    background: linear-gradient(97deg, #ffe9ec -3.2%, #ffd1d6 109.08%);

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        width: 293px;
        height: 260px;

        background: url(${imageBannerCardBig}) no-repeat top right/100%;
    }

    @media (max-width: ${breakpoints.XXL}) {
        &::before {
            width: 264px;
            background: url(${imageBannerCardBigXxl}) no-repeat top right/100%;
        }
    }

    @media (max-width: ${breakpoints.XL}) {
        &::before {
            width: 334px;
            height: 192px;
            background: url(${imageBannerCardBigXl}) no-repeat top right/100%;
        }
    }

    @media (max-width: ${breakpoints.LG}) {
        &::before {
            width: 173px;
            height: 182px;
            background: url(${imageBannerCardBigLg}) no-repeat top right/100%;
        }
    }

    @media (max-width: ${breakpoints.MD}) {
        &::before {
            width: 171px;
            height: 161px;
            left: 50%;
            bottom: unset;
            top: 50px;
            transform: translateX(-50%);
            background: url(${imageBannerCardBigMd}) no-repeat top right/100%;
        }
    }
`;

const BannerSpan = styled.span`
    @media (max-width: ${breakpoints.XL}) {
        display: block;
    }

    @media (max-width: ${breakpoints.MD}) {
        display: inline-block;
    }
`;

const BannerSpan2 = styled.span``;

const BannersList1 = styled.ul`
    display: grid;
    grid-template-columns: 345px 1fr;
    gap: 24px;

    @media (max-width: ${breakpoints.XXL}) {
        gap: 16px;
    }

    @media (max-width: ${breakpoints.XL}) {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    @media (max-width: ${breakpoints.LG}) {
        gap: 16px;
    }

    @media (max-width: ${breakpoints.MD}) {
        gap: 34px;
    }
`

const BannersItem1 = styled.li``

const BannersItem1Span = styled.span`
    display: block;

    @media (max-width: ${breakpoints.MD}) {
        display: inline;
    }
`

const BannerProfileCat1 = styled(BannerProfileCat)`
    padding: 34px 36px;

    &::before {
        top: 33px;
        right: 22px;
        left: unset;
        bottom: unset;
    }

    &::after {
        width: 164px;
        height: 170px;
        background: url(${bannerImageCat}) no-repeat center center/100%;
    }

    ${s.Text} {
        margin-bottom: 24px;
    }

    ${s.List} {
        gap: 12px;
        margin-bottom: 12px;
    }


    @media (max-width: ${breakpoints.XXL}) {
        padding: 30px 36px;

        &::before {
            right: 18px;
        }

        &::after {
            width: 132px;
            height: 120px;
            background: url(${bannerImageCatXxl}) no-repeat center center/100%;
        }
    }

    @media (max-width: ${breakpoints.XL}) {
        padding: 36px 240px 36px 32px;

        &::before {
            top: 36px;
            right: 38px;
        }

        &::after {
            width: 239px;
            height: 221px;
            background: url(${bannerImageCatXl}) no-repeat center center/100%;
        }
    }

    @media (max-width: ${breakpoints.LG}) {
        padding: 36px 300px 53px 32px;

        &::before {
            top: 36px;
            right: 38px;
        }

        &::after {
            left: 290px;
            right: unset;
            width: 160px;
            height: 121px;
            background: url(${bannerImageCatLg}) no-repeat center center/100%;
        }
    }

    @media (max-width: ${breakpoints.MD}) {
        padding: 24px 24px 102px;

        &::before {
            top: unset;
            right: unset;
            left: 27px;
            bottom: 26px;
        }

        &::after {
            right: 0;
            left: unset;
            width: 138px;
            height: 110px;
            background: url(${bannerImageCatMd}) no-repeat center center/100%;
        }
    }
`

export {
  PersonalAreaProfile,
  PersonalAreaContainer,
  WrapFooter,
  Footer,
  List,
  Item,
  Card,
  IconButtonVk,
  IconButtonOk,
  IconUser,
  WrapContent,
  BannerInfo,
  MyWallet,
  Title,
  WalletList,
  WalletItem,
  WalletItemButton,
  WalletCard,
  IconWalletCardRoyals,
  IconWalletCardPromos,
  MyAnimal,
  AnimalList,
  AnimalItem,
  ItemAddPet,
  ButtonCardAddPet,
  WrapAddPet,
  TitleAddPet,
  TextAddPet,
  Wrap,
  Icon,
  Banners,
  BannersList,
  BannersItem,
  BannerOne,
  BannerTwo,
  BannerSpan,
  BannerSpan2,
  BannersList1,
  BannersItem1,
  BannersItem1Span,
  BannerProfileCat1,
};
