import React, { FC } from "react";
import * as s from "./Duo-feeding-styles";
import { CardsFeedings } from "../../molecules";
import { ModalsEnumType, ModalsProperty } from "../../../config";
import feedingCardPhysical1440 from "../../../config/assets/images/feeding-card-physical-1440.svg";
import feedingCardWeight1440 from "../../../config/assets/images/feeding-card-weight-1440.svg";
import feedingCardUrolithiasis1440 from "../../../config/assets/images/feeding-card-urolithiasis-1440.svg";
import feedingCardDiabetes1440 from "../../../config/assets/images/feeding-card-diabetes-1440.svg";
import feedingCardOldPets1440 from "../../../config/assets/images/feeding-card-old-pets-1440.svg";
import feedingCardPhysical1024 from "../../../config/assets/images/feeding-card-physical-1024.svg";
import feedingCardWeight1024 from "../../../config/assets/images/feeding-card-weight-1024.svg";
import feedingCardUrolithiasis1024 from "../../../config/assets/images/feeding-card-urolithiasis-1024.svg";
import feedingCardDiabetes1024 from "../../../config/assets/images/feeding-card-diabetes-1024.svg";
import feedingCardOldPets1024 from "../../../config/assets/images/feeding-card-old-pets-1024.svg";
import feedingCardPhysical768 from "../../../config/assets/images/feeding-card-physical-768.svg";
import feedingCardWeight768 from "../../../config/assets/images/feeding-card-weight-768.svg";
import feedingCardUrolithiasis768 from "../../../config/assets/images/feeding-card-urolithiasis-768.svg";
import feedingCardDiabetes768 from "../../../config/assets/images/feeding-card-diabetes-768.svg";
import feedingCardOldPets768 from "../../../config/assets/images/feeding-card-old-pets-768.svg";
import feedingCardPhysical375 from "../../../config/assets/images/feeding-card-physical-375.svg";
import feedingCardWeight375 from "../../../config/assets/images/feeding-card-weight-375.svg";
import feedingCardUrolithiasis375 from "../../../config/assets/images/feeding-card-urolithiasis-375.svg";
import feedingCardDiabetes375 from "../../../config/assets/images/feeding-card-diabetes-375.svg";
import feedingCardOldPets375 from "../../../config/assets/images/feeding-card-old-pets-375.svg";
import { useIsMobile } from "../../../lib";

type DuoFeedingType = {
  className?: string;
  id?: string;
  titleDuoFeeding: string;
  textDuoFeeding: string;
  onClickCardFeedings: (modalInfo: ModalsEnumType) => () => void;
};

export const DuoFeeding: FC<DuoFeedingType> = ({
  className,
  id,
  titleDuoFeeding,
  textDuoFeeding,
  onClickCardFeedings,
}) => {
  const [is375] = useIsMobile(575);
  const [is575] = useIsMobile(1024);
  const [is1024] = useIsMobile(1280);

  const cardsFeedingsMock = [
    {
      text: "Соответствует естественным потребностям",
      icon: !is375
        ? feedingCardPhysical375
        : !is575
        ? feedingCardPhysical768
        : !is1024
        ? feedingCardPhysical1024
        : feedingCardPhysical1440,
      modal: ModalsProperty.MODAL_FEEDING_TYPES_NATURAL_NEEDS,
    },

    {
      text: "Способствует поддержанию оптимального веса",
      icon: !is375
        ? feedingCardWeight375
        : !is575
        ? feedingCardWeight768
        : !is1024
        ? feedingCardWeight1024
        : feedingCardWeight1440,
      modal: ModalsProperty.MODAL_FEEDING_TYPES_OPTIMAL_WEIGHT,
    },

    {
      text: "Обеспечивает профилактику мочекаменной болезни",
      icon: !is375
        ? feedingCardUrolithiasis375
        : !is575
        ? feedingCardUrolithiasis768
        : !is1024
        ? feedingCardUrolithiasis1024
        : feedingCardUrolithiasis1440,
      modal: ModalsProperty.MODAL_FEEDING_TYPES_UROLITHIASIS,
    },

    {
      text: "Помогает предотвратить развитие диабета",
      icon: !is375
        ? feedingCardDiabetes375
        : !is575
        ? feedingCardDiabetes768
        : !is1024
        ? feedingCardDiabetes1024
        : feedingCardDiabetes1440,
      modal: ModalsProperty.MODAL_FEEDING_TYPES_DIABETES,
    },

    {
      text: "Соответствует потребностям животных всех возрастов",
      icon: !is375
        ? feedingCardOldPets375
        : !is575
        ? feedingCardOldPets768
        : !is1024
        ? feedingCardOldPets1024
        : feedingCardOldPets1440,
      modal: ModalsProperty.MODAL_FEEDING_TYPES_OLD_PETS,
    },
  ];

  return (
    <s.DuoFeeding className={className} id={id}>
      <s.HeadText>
        <s.Title>{titleDuoFeeding}</s.Title>
        <s.Text>{textDuoFeeding}</s.Text>
      </s.HeadText>

      <CardsFeedings
        cards={cardsFeedingsMock}
        onClickCardFeedings={onClickCardFeedings}
      />
    </s.DuoFeeding>
  );
};
