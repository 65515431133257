import styled, { css } from "styled-components";
import { breakpoints } from "../../../config";

const BodyStyle = css`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  overflow: hidden;
  will-change: transform;
  transition: transform 500ms ease;

  @media (max-width: ${breakpoints.MD}) {
    position: relative;
    border-radius: 26px;
  }
`;

const Body = styled.div`
  ${BodyStyle};
  padding: 30px 27px 28px;
  background-color: var(--brand-4);

  @media (max-width: ${breakpoints.XXL}) {
    padding: 24px 30px;
  }

  @media (max-width: ${breakpoints.XL}) {
    padding: 24px 10px 24px 24px;
  }

  @media (max-width: ${breakpoints.LG}) {
    padding: 20px;
  }

  @media (max-width: ${breakpoints.MD}) {
    min-height: 150px;
    padding: 39px 20px;
  }

  & svg {
    z-index: -5;
  }
`;

const CardDiscount = styled.article`
  display: block;
  position: relative;
  width: 100%;
  min-height: 340px;
  border-radius: 32px;
  cursor: pointer;
  transition: ease-in-out 300ms;

  @media (max-width: ${breakpoints.XXL}) {
    min-height: 326px;
  }

  @media (max-width: ${breakpoints.XL}) {
    min-height: 275px;
  }

  @media (max-width: ${breakpoints.LG}) {
    min-height: 220px;
  }

  @media (max-width: ${breakpoints.MD}) {
    min-height: auto;
    height: 150px;
  }

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.99);
  }
`;

const Title = styled.h3`
  font: var(--font-6);
  height: 100%;
  display: flex;
  align-items: flex-end;
  color: var(--interface-dark);
  letter-spacing: -0.56px;

  @media (max-width: ${breakpoints.MD}) {
    max-width: 63%;
    align-items: center;
  }
`;

export { CardDiscount, Body, Title };
