import styled from "styled-components";
import { Button, ModalBody } from "../../../../shared";
import { BodyAnimation } from "../../../../shared/ui/molecules/modal-body/Modal-body-styles";
import { breakpoints } from "../../../../shared/config";

const ModalConfirmationRefEmailFriend = styled.div``;

const Modal = styled(ModalBody)`
  ${BodyAnimation} {
    max-width: 692px;
    border-radius: 36px;
  }

  @media (max-width: ${breakpoints.XL}) {
    ${BodyAnimation} {
      max-width: 652px;
    }
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      max-width: 100%;
    }
  }
`;

const Title = styled.h2`
  max-width: 520px;
  margin-bottom: 40px;
  font: var(--font-3);
  color: var(--brand-1);

  @media (max-width: ${breakpoints.LG}) {
    max-width: unset;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ButtonCommon = styled(Button)`
  max-width: 450px;
`;

const ButtonRedirect = styled(Button)`
  max-width: 450px;
`;

export {
  ModalConfirmationRefEmailFriend,
  Modal,
  Title,
  ButtonWrap,
  ButtonCommon,
  ButtonRedirect,
};
