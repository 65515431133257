import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {esAnimal, esAuth, esDiscount, esPet, esRecovery,} from "../../entities";
import {sd} from "../../shared";
import {ws} from "../../widgets";
import {user} from "../../entities/auth/model";

const token = JSON.parse(localStorage.getItem("token") as string);

const rootReducer = combineReducers({
  shared: sd.model.sharedReducer,
  modals: ws.model.modalsReducer,
  animal: esAnimal.model.animalReducer,
  auth: esAuth.model.authReducer,
  recovery: esRecovery.model.recoveryReducer,
  discount: esDiscount.model.discountReducer,
  pet: esPet.model.petReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: {
    auth: {
      isAuthorization: !!token,
      isDisabledButton: false,
      error: null,
      user,
    },
  },
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
