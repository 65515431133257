import React, {FC, useEffect} from "react";
import * as s from "./Registration-styles";
import {useFormik} from "formik";
import {ValidationFormRegistration} from "../config";
import {esAnimal, esAuth} from "../../../entities";
import {AnimalPetType} from "../../../entities/animal/model";
import {deleteMaskPhone, useAppDispatch, useAppSelector, useScrollToTop,} from "../../../shared/lib";
import {RegistrationRequestBodyType} from "../../../entities/auth/model";
import bg from "./../../../shared/config/assets/images/auth-bg.svg";
import {setModals} from "../../../widgets/modals/model";
import {ModalsProperty} from "../../../shared/config";
import {checkEmailThunk, checkPhoneThunk} from "../../../shared/model";

type RegistrationType = {};

export type InitialValuesFormikRegistrationType = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  personal_data_handling: boolean;
  age_confirmation: boolean;
  promotional_mailing: boolean;
  loyalty_card: boolean;
  pets: Array<{
    id: string;
    animal: string;
    name: string;
    date_of_birth: string;
    breed: string;
    gender: string;
  }>;
};

export const Registration: FC<RegistrationType> = ({}) => {
  useScrollToTop();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const invitationToken = urlParams.get("invitation_token");

  const dispatch = useAppDispatch();
  const {cat, dog} = useAppSelector(esAnimal.model.selectStateAnimals);

  const storedFormData = JSON.parse(
    sessionStorage.getItem("miniFormData") || "{}"
  );

  const formik = useFormik({
    initialValues: {
      first_name: storedFormData.first_name || "",
      last_name: storedFormData.last_name || "",
      phone: "",
      email: storedFormData.email || "",
      personal_data_handling: false,
      age_confirmation: false,
      promotional_mailing: false,
      loyalty_card: false,
      pets: [],
    },

    validationSchema: ValidationFormRegistration(cat, dog),

    onSubmit: async (body: InitialValuesFormikRegistrationType) => {
      const codeSms = localStorage.getItem("anon-sms-code");
      const jsonCodeSms = codeSms ? JSON.parse(codeSms) : "";
      const requestBody: RegistrationRequestBodyType = {
        ...body,
        code: "",
        phone: deleteMaskPhone(body.phone),
        code_sms: jsonCodeSms,
        pets: body.pets.map((pet: AnimalPetType) => {
          return {
            name: pet.name,
            date_of_birth: pet.date_of_birth,
            breed: Number(
              [...cat, ...dog].find(
                (el) => el.value.toLowerCase() === pet.breed.toLowerCase()
              )?.id
            ),
            gender: pet.gender,
          };
        }),
      };

      try {
        const response = await dispatch(
          checkEmailThunk({email: formik.values.email})
        );

        const responsePhone = !formik.values.phone ? {payload: {availible: true}} : await dispatch(
          checkPhoneThunk({phone: deleteMaskPhone(formik.values.phone)})
        );

        // @ts-ignore
        if (response.payload?.availible === false && responsePhone?.payload.availible === true) {
          dispatch(setModals({action: ModalsProperty.MODAL_CHECK_EMAIL}));
          // @ts-ignore
        } else if (response.payload?.availible === true && responsePhone?.payload.availible === false) {
          dispatch(setModals({action: ModalsProperty.MODAL_CHECK_PHONE}));
          // @ts-ignore
        } else if (response.payload?.availible === false && responsePhone?.payload.availible === false) {
          dispatch(setModals({action: ModalsProperty.MODAL_CHECK_EMAIL_PHONE}));
        } else {
          await esAuth.api.authApi.email_confirmation({
            email: requestBody.email,
            name: requestBody.first_name,
          });

          await dispatch(
            setModals({
              action: ModalsProperty.MODAL_CONFIRMATION_EMAIL,
              content: {body: requestBody, invitationToken: invitationToken},
            })
          );

          localStorage.removeItem("anon-sms-code");
        }
      } catch (e) {
      }
    },
  });

  useEffect(() => {
    dispatch(esAnimal.model.getAnimalsThunk());

    formik.setFieldTouched("personal_data_handling", false);
    formik.setFieldTouched("age_confirmation", false);
    formik.values.pets.forEach((pet, index) => {
      formik.setFieldTouched(`pets.${index}.animal`, false);
    });
  }, []);

  return (
    <s.Registration>
      <s.ImageBg src={bg} alt={"животное"}/>

      <s.Title>Регистрация</s.Title>

      <s.FormRegUser formik={formik}/>

      <s.FormAnimal
        formik={formik}
        pets={formik.values.pets}
        cat={cat}
        dog={dog}
      />

      <s.FormPassword formik={formik}/>
    </s.Registration>
  );
};
