import React, {ChangeEvent, FC} from "react";
import * as s from "./Avatar-styles";

type AvatarType = {
  className?: string;
  src: string;
  alt: string;
  onChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const Avatar: FC<AvatarType> = ({
                                         className,
                                         src,
                                         alt,
                                         onChangeInput,
                                       }) => {
  return (
    <s.Avatar className={className}>
      <s.Input
        type="file"
        accept=".png, .jpeg, .jpg, .webp"
        onChange={onChangeInput}
      />

      <s.Image src={src} alt={alt}/>

      <s.IconPlus/>
    </s.Avatar>
  );
};
