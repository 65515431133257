import styled from "styled-components";
import { IconLogo } from "../../atom";
import { breakpoints } from "../../../config";

const PartnersSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: ${breakpoints.XL}) {
    gap: 16px;
  }

  @media (max-width: ${breakpoints.LG}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${breakpoints.MD}) {
    display: flex;
    flex-direction: column;
  }
`;

const CardPartnerLogo = styled(IconLogo)`
  width: 100%;
  height: auto;
  transition: ease-in-out 300ms;

  @media (max-width: ${breakpoints.LG}) {
    max-width: 250px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: 190px;
    min-width: 125px;
  }
`;

const CardPartner = styled.article`
  flex: 1;
  width: 100%;
  background: var(--gradient-zoozavr);
  border-radius: 36px;
  transition: ease-in-out 300ms;

  @media (max-width: ${breakpoints.LG}) {
    grid-column: span 2;
    max-height: 104px;
  }

  @media (max-width: ${breakpoints.MD}) {
    border-radius: 28px;
  }

  &:hover {
    transform: scale(0.97);
  }

  &:hover ${CardPartnerLogo} {
    transform: scale(1.2);

    @media (max-width: ${breakpoints.XXL}) {
      transform: scale(1.1);
      transform-origin: 95px 0;
    }

    @media (max-width: ${breakpoints.XL}) {
      transform-origin: 150px 0;
    }

    @media (max-width: ${breakpoints.LG}) {
      transform-origin: 150px 0;
    }
  }

  &:active {
    transform: scale(0.99);
  }

  &:active ${CardPartnerLogo} {
    transform: scale(1.1);

    @media (max-width: ${breakpoints.XXL}) {
      transform: scale(1.05);
    }
  }
`;

const CardPartnerLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 104px;
  gap: 21px;
  padding: 30px 56px 27px;

  @media (max-width: ${breakpoints.XL}) {
    padding: 32px 22px 24px 32px;
    gap: 43px;
  }

  @media (max-width: ${breakpoints.LG}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    padding: 25px 84px 25px 32px;
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 16px 16px 16px 24px;
    gap: 0;
  }
`;

const CardPartnerText = styled.p`
  font: var(--font-29);
  color: var(--interface-dark);
  max-width: 208px;
  text-align: center;
  will-change: transform;

  @media (max-width: ${breakpoints.MD}) {
    text-align: left;
  }
`;

export {
  PartnersSection,
  CardPartner,
  CardPartnerLink,
  CardPartnerText,
  CardPartnerLogo,
};
