import {FC} from "react";
import * as s from "./Banner-profile-cat-styles";

type BannerProfileCatType = {
  className?: string;
};

export const BannerProfileCat: FC<BannerProfileCatType> = ({className}) => {
  return (
    <s.BannerProfileCat className={className}>
      <s.Title>Выиграйте главный приз</s.Title>

      <s.Text>Участвуйте в программе лояльности «Здоровый питомец»</s.Text>

      <s.List>
        <s.Item>
          <s.WrapIcon>
            <s.IconStep status={1}/>
            <s.Index>1</s.Index>
          </s.WrapIcon>

          <s.WrapText>
            <s.SubTitle>Покупайте рационы Royal Canin</s.SubTitle>

            <s.SubText>для котят и щенков в Зоозавре</s.SubText>
          </s.WrapText>
        </s.Item>

        <s.Item>
          <s.WrapIcon>
            <s.IconStep status={2}/>
            <s.Index>2</s.Index>
          </s.WrapIcon>

          <s.WrapText>
            <s.SubTitle>Регистрируйте чеки</s.SubTitle>

            <s.SubText>Каждый чек - возможность выиграть приз</s.SubText>
          </s.WrapText>
        </s.Item>
      </s.List>

      <s.WrapGift>
        <s.IconGift/>

        <s.WrapText>
          <s.SubTitle>
            <s.Span>Участвуйте</s.Span>
            {" "}в розыгрыше призов
          </s.SubTitle>

          <s.SubText>1 из 50 страховок PetStory</s.SubText>
        </s.WrapText>
      </s.WrapGift>
    </s.BannerProfileCat>
  );
};
