import React, {FC, useEffect} from "react";
import * as s from "./Personal-area-profile-styles";
import {
  Animation,
  BannerCardProfile1,
  ButtonInvite,
  CardPet,
  IconAdd,
  IconCalendar,
  IconDoc,
  IconFemale,
  IconFolderCloud,
  IconMale,
  IconQr,
} from "../../../shared";
import {
  getNumberMonth,
  getNumberYear,
  useAppDispatch,
  useAppSelector,
  useIsMobile,
  useSendImage,
} from "../../../shared/lib";
import {setModals} from "../../../widgets/modals/model";
import {breakpointsTs, ModalsProperty, pages} from "../../../shared/config";
import {useNavigate} from "react-router-dom";
import {SidebarPersonalArea} from "../../../widgets";
import {esAuth, esDiscount, esPet} from "../../../entities";
import {editUserThunk, getUserThunk} from "../../../entities/auth/model";
import {findDateDifference} from "../../../shared/lib/function/find-Date-Difference";
import {updatePetsThunk} from "../../../entities/pet/model";
import {useScrollToTop} from "../../../shared/lib/hooks/use-scroll-to-top";
import {getOkRepostThunk, getVkRepostThunk} from "../../../shared/model";
import {getDiscountReceiptIdThunk} from "../../../entities/discount/model";

type PersonalAreaProfileType = {};

export const PersonalAreaProfile: FC<PersonalAreaProfileType> = ({}) => {
  useScrollToTop();

  const [isMobile] = useIsMobile(breakpointsTs.LG);
  const promoCodes = useAppSelector(esDiscount.model.selectDiscountPromoCodes);
  const user = useAppSelector(esAuth.model.selectAuthUser);
  const pets = useAppSelector(esPet.model.selectPets);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [cb] = useSendImage(
    (image) => {
      dispatch(
        editUserThunk({
          ...user,
          image: image,
        })
      );
    },
  )

  const onEditAvatar = cb()

  const [onEditAvatarPet] = useSendImage(
    (image, id) => {
      dispatch(
        updatePetsThunk({
          body: {image: image},
          id: id ? id : 0,
        })
      );
    },
  )

  const onOpenModalHistory = () => {
    dispatch(setModals({action: ModalsProperty.MODAL_HISTORY_OPERATIONS}));
  };

  const onRedirectPagePromoCode = () => {
    navigate(pages.personalAreaPromoCode.link);
  };

  const onOpenModalAddAnimal = () => {
    dispatch(setModals({action: ModalsProperty.MODAL_ADD_ANIMAL}));
  };

  const onShowModalRef = () => {
    dispatch(setModals({action: ModalsProperty.MODAL_REF_EMAIL_FRIEND}));
  };

  const onClickCardButtonIconSocialMedia = () => {
    dispatch(
      setModals({action: ModalsProperty.MODAL_ABOUT_INVITATION_SOCIAL_MEDIA})
    );
  };

  const onClickCardButtonIconSocialMediaButtons = () => {
    dispatch(
      setModals({
        action: ModalsProperty.MODAL_ABOUT_INVITATION_SOCIAL_MEDIA_BUTTONS,
      })
    );
  };

  const onClickCardButtonIconAnimal = () => {
    dispatch(
      setModals({action: ModalsProperty.MODAL_ABOUT_INVITATION_ANIMAL})
    );
  };

  const onClickCardButtonIconAnimalButtons = () => {
    dispatch(
      setModals({
        action: ModalsProperty.MODAL_ABOUT_INVITATION_ANIMAL_BUTTONS,
      })
    );
  };

  const getNoun = (number: number, one: string, two: string, five: string) => {
    if (number < 0) {
      return "Ошибка: число не может быть отрицательным";
    }

    const lastTwoDigits = number % 100;
    const lastDigit = number % 10;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return five;
    }

    if (lastDigit === 1) {
      return one;
    }
    if (lastDigit >= 2 && lastDigit <= 4) {
      return two;
    }

    return five;
  };

  const getDatePet = (date: string) => {
    const {yearsDifference, monthsDifference} = findDateDifference(date);
    return `${getNumberYear(yearsDifference)} ${getNumberMonth(
      monthsDifference
    )}`;
  };

  const getDatePetAdult = (years: number, months: number) => {
    return `${getNumberYear(years)}${getNumberMonth(months)}`;
  };
  const animation = {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0},
  };

  useEffect(() => {
    if (pets?.length === 0) {
      dispatch(esPet.model.getPetsThunk());
    }

    if (promoCodes?.length === 0) {
      dispatch(esDiscount.model.getPromoCodesThunk());
    }
  }, []);

  const onRepostVk = async () => {
    await dispatch(getVkRepostThunk());
    await dispatch(getUserThunk());
  };

  const onRepostOk = async () => {
    await dispatch(getOkRepostThunk());
    await dispatch(getUserThunk());
  };

  const onShowPopupReceipt = (): void => {
    dispatch(setModals({action: ModalsProperty.MODAL_RECEIPT}));
  };

  useEffect(() => {
    dispatch(getDiscountReceiptIdThunk());
  }, []);

  return (
    <s.PersonalAreaContainer>
      <s.PersonalAreaProfile>
        <s.WrapFooter>
          <SidebarPersonalArea
            isMobile={isMobile}
            onChangeInput={onEditAvatar}
            onClickMenuUpload={onShowPopupReceipt}
          />

          <Animation isShow={true} delay={0.1} variants={animation}>
            <s.Footer>
              <s.List>
                <s.Item>
                  <s.Card
                    isMobile={!isMobile}
                    title={"Пригласите друга с питомцем"}
                    numberRoyal={"+10"}
                    mobileButtonText={"Подробнее"}
                    buttons={[
                      <ButtonInvite
                        icon={<s.IconUser/>}
                        onClickButtonInvite={onShowModalRef}
                        text="Пригласить друга"
                        type="button"
                      />,
                    ]}
                    onClickCardButtonIcon={onClickCardButtonIconAnimal}
                    onClickMobileButton={onClickCardButtonIconAnimalButtons}
                  />
                </s.Item>

                {(!user.vk_repost || !user.ok_repost) && (
                  <s.Item>
                    <s.Card
                      isMobile={!isMobile}
                      title={"Сделайте репост в\u00A0соц.сетях"}
                      numberRoyal={"+5"}
                      mobileButtonText={"Подробнее"}
                      buttons={[
                        !user.ok_repost && (
                          <a
                            href="https://connect.ok.ru/offer?url=https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai"
                            target={"_blank"}
                          >
                            <ButtonInvite
                              icon={<s.IconButtonOk/>}
                              onClickButtonInvite={onRepostOk}
                              text="Одноклассники"
                              type="button"
                            />
                          </a>
                        ),
                        !user.vk_repost && (
                          <a
                            href="https://vk.com/share.php?url=https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai&title=%D0%9F%D1%80%D0%B8%D1%81%D0%BE%D0%B5%D0%B4%D0%B8%D0%BD%D1%8F%D0%B9%D1%82%D0%B5%D1%81%D1%8C%20%D0%BA%20%D0%B0%D0%BA%D1%86%D0%B8%D0%B8%20%D0%97%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D1%8B%D0%B9%20%D0%BF%D0%B8%D1%82%D0%BE%D0%BC%D0%B5%D1%86.&image=https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/banners/banner-vk.jpg&noparse=true"
                            target={"_blank"}
                          >
                            <ButtonInvite
                              icon={<s.IconButtonVk/>}
                              onClickButtonInvite={onRepostVk}
                              text="Вконтакте"
                              type="button"
                            />
                          </a>
                        ),
                      ]}
                      onClickCardButtonIcon={onClickCardButtonIconSocialMedia}
                      onClickMobileButton={
                        onClickCardButtonIconSocialMediaButtons
                      }
                    />
                  </s.Item>
                )}
              </s.List>
            </s.Footer>
          </Animation>
        </s.WrapFooter>

        <s.WrapContent>
          <Animation isShow={isMobile}>
            <s.BannerInfo
              list={[
                {
                  title: "Требования к чеку",
                  list: [
                    {
                      text: "На фото только один чек",
                      icon: <IconDoc width={36} height={36}/>,
                    },

                    {
                      text: "Формат JPEG/PNG/PDF",
                      subText: "До 20Мб",
                      icon: <IconFolderCloud width={36} height={36}/>,
                    },
                  ],
                },

                {
                  title: "В чеке должно быть",
                  list: [
                    {text: "QR-код", icon: <IconQr width={36} height={36}/>},

                    {
                      text: "Дата и время",
                      subText: "(чек принимается к загрузке в\u00A0течение 10 дней с момента покупки)",
                      icon: <IconCalendar width={36} height={36}/>,
                    },
                  ],
                },
              ]}
              onClickButtonUpload={onShowPopupReceipt}
            />
          </Animation>

          <Animation isShow={true} delay={0.3} variants={animation}>
            <s.MyWallet>
              <s.Title>Мой кошелек</s.Title>

              <s.WalletList>
                <s.WalletItem>
                  <s.WalletItemButton
                    type={"button"}
                    onClick={onOpenModalHistory}
                  >
                    <s.WalletCard
                      icon={<s.IconWalletCardRoyals/>}
                      title={`${user?.available_royals} ${getNoun(
                        user?.available_royals,
                        "роялс",
                        "роялса",
                        "роялсов"
                      )}`}
                      text={"История операций"}
                    />
                  </s.WalletItemButton>
                </s.WalletItem>

                <s.WalletItem>
                  <s.WalletItemButton
                    type={"button"}
                    onClick={onRedirectPagePromoCode}
                  >
                    <s.WalletCard
                      icon={<s.IconWalletCardPromos/>}
                      title={`${promoCodes?.length || "0"} ${getNoun(
                        promoCodes?.length,
                        "промокод",
                        "промокода",
                        "промокодов"
                      )}`}
                      text={"Перейти в раздел"}
                    />
                  </s.WalletItemButton>
                </s.WalletItem>
              </s.WalletList>
            </s.MyWallet>
          </Animation>

          <Animation isShow={true} delay={0.5} variants={animation}>
            <s.MyAnimal>
              <s.Title>Мои питомцы</s.Title>

              <s.AnimalList>
                {pets?.map((pet, index) => (
                  <CardPet
                    key={index}
                    src={pet?.image || ""}
                    alt={`Питомец ${index + 1}`}
                    breed_class={pet?.breed?.breed_class || ""}
                    date={getDatePet(pet?.date_of_birth || "") || ""}
                    name={pet?.name || ""}
                    genderIcon={
                      pet?.gender == "male" ? <IconMale/> : <IconFemale/>
                    }
                    breed={pet?.breed?.title || ""}
                    dateEnd={
                      (pet?.time_to_adult?.years === 0 && pet?.time_to_adult?.months === 0) ? null :
                        getDatePetAdult(
                          pet?.time_to_adult?.years || 0,
                          pet?.time_to_adult?.months || 0
                        ) || ""
                    }
                    onChangeInput={onEditAvatarPet(pet?.id)}
                  />
                ))}
                <s.AnimalItem>
                  <s.ButtonCardAddPet
                    icon={<IconAdd/>}
                    onClickButtonCardAdd={onOpenModalAddAnimal}
                    text={"Добавить  питомца"}
                  />
                </s.AnimalItem>

                {pets.length === 0 && (
                  <s.ItemAddPet>
                    <s.WrapAddPet>
                      <s.TitleAddPet>
                        <s.Wrap>
                          Получите <s.Icon number={"15"} disabled={true}/>
                        </s.Wrap>
                        за первого питомца
                      </s.TitleAddPet>
                      <s.TextAddPet>
                        И потратьте их на скидки и промокоды
                      </s.TextAddPet>
                    </s.WrapAddPet>
                  </s.ItemAddPet>
                )}
              </s.AnimalList>
            </s.MyAnimal>
          </Animation>

          <Animation isShow={true} delay={0.8}>
            <s.Banners>
              <s.BannersList1>
                <s.BannersItem1>
                  <BannerCardProfile1 title={<>
                    <s.BannersItem1Span>Рационы</s.BannersItem1Span>
                    {" "}
                    для котят и щенков</>} text={"Купить"}
                                      href={"https://zoozavr.ru/catalog/index/name/royal_canin_pitomec_zoo_action/?sis=7690"}/>

                </s.BannersItem1>

                <s.BannersItem1>
                  <s.BannerProfileCat1/>
                </s.BannersItem1>
              </s.BannersList1>
              {/*<s.BannersList>*/}
              {/*  <s.BannersItem>*/}
              {/*    <s.BannerOne*/}
              {/*      title={*/}
              {/*        <>*/}
              {/*          <s.BannerSpan>Рацион</s.BannerSpan>*/}
              {/*          {" "}*/}
              {/*          <s.BannerSpan2>*/}
              {/*            для&nbsp;котят и&nbsp;щенков*/}
              {/*          </s.BannerSpan2>*/}
              {/*        </>*/}
              {/*      }*/}
              {/*      href={""}*/}
              {/*    />*/}
              {/*  </s.BannersItem>*/}

              {/*  <s.BannersItem>*/}
              {/*    <BannerProfileCat/>*/}
              {/*  </s.BannersItem>*/}

              {/*  <s.BannersItem>*/}
              {/*    <s.BannerTwo*/}
              {/*      title={*/}
              {/*        <>*/}
              {/*          <s.BannerSpan>Рацион</s.BannerSpan>*/}
              {/*          {" "}*/}
              {/*          <s.BannerSpan2>*/}
              {/*            для&nbsp;взрослого питомца*/}
              {/*          </s.BannerSpan2>*/}
              {/*        </>*/}
              {/*      }*/}
              {/*      href={""}*/}
              {/*    />*/}
              {/*  </s.BannersItem>*/}
              {/*</s.BannersList>*/}
            </s.Banners>
          </Animation>
        </s.WrapContent>
      </s.PersonalAreaProfile>
    </s.PersonalAreaContainer>
  );
};
