import {FC, ReactNode} from "react";
import * as s from "./Banner-card-profile-styles";

type BannerCardProfileType = {
  className?: string;
  title: ReactNode;
  href: string;
};

export const BannerCardProfile: FC<BannerCardProfileType> = ({className, title, href}) => {
  return (
    <s.BannerCardProfile className={className} href={href} target={"_blank"}>
      <s.Title>{title}</s.Title>

      <s.Button>Купить</s.Button>
    </s.BannerCardProfile>
  );
};