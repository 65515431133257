import React, {FC, ReactNode} from "react";
import * as s from "./Modal-about-invitation-styles";

type ModalAboutInvitationType = {
  className?: string;
  onShowModal: () => void;
  title: ReactNode;
  list: Array<{ text: string }>;
};

export const ModalAboutInvitation: FC<ModalAboutInvitationType> = ({
                                                                     className,
                                                                     onShowModal,
                                                                     title,
                                                                     list,
                                                                   }) => {
  return (
    <s.ModalAboutInvitation className={className}>
      <s.Modal onClickButtonBg={onShowModal} onClickButtonIcon={onShowModal}>
        <s.Title>{title}</s.Title>

        <s.List>
          {list.map((item, index) => (
            <s.Item key={index}>


              <s.Text>
                <s.Number>{index + 1}.</s.Number>
                {item.text}</s.Text>
            </s.Item>
          ))}
        </s.List>
      </s.Modal>
    </s.ModalAboutInvitation>
  );
};
