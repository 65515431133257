import { FC } from "react";
import * as s from "./Banner-promo-code-animal-styles";

type BannerPromoCodeAnimalType = {
  className?: string;
  title: string;
};

export const BannerPromoCodeAnimal: FC<BannerPromoCodeAnimalType> = ({
  className,
  title,
}) => {
  return (
    <s.BannerPromoCodeAnimal className={className}>
      <s.Title>{title}</s.Title>
    </s.BannerPromoCodeAnimal>
  );
};
