import {AxiosResponse} from "axios";
import {InitialValuesFormikRecoverEmailType} from "../../../pages/recover-password-email";
import {instance} from "../../../shared/api";

export const recoveryApi = {
  recovery_new_password: async (body: InitialValuesFormikRecoverEmailType): Promise<AxiosResponse<unknown>> => {
    const response = await instance.post<unknown>("/new_password/", body);
    return response as AxiosResponse<unknown>;
  },
};
