import React, { FC } from "react";

export const IconsCardsRoyals: FC<
  React.SVGProps<SVGSVGElement> & { status: 1 | 2 | 3 | 4 }
> = ({ status, ...props }) => (
  <>
    {/* Icon Heart */}
    {status === 1 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 380 188"
        {...props}
      >
        <path
          d="M-110.827 102.768C-245.106 239.669 140.513 315.89 264.958 350.362L332.507 234.406C408.825 96.5106 385.855 19.6815 310.835 -11.923C243.897 -40.1225 140.425 -40.8052 96.7976 94.8852C62.7875 72.0397 -43.6973 34.3269 -110.827 102.768Z"
          fill="currentColor"
        />
      </svg>
    )}

    {/* Icon Wing */}
    {status === 2 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 424 188"
        {...props}
      >
        <path
          d="M267.461 -115.444C267.461 -115.444 -161.619 -29.2065 19.1579 191.757C165.827 371.002 465.429 187.135 442.224 136.392C432.848 115.99 307.105 134.696 307.105 134.696C307.105 134.696 394.734 71.8853 373.837 63.8525C352.941 55.8198 294.877 70.7775 294.877 70.7775C294.877 70.7775 374.005 -7.66908 329.538 -16.918C285.072 -26.1669 225.978 16.9757 225.978 16.9757C225.978 16.9757 341.414 -133.219 267.461 -115.444Z"
          fill="currentColor"
        />
      </svg>
    )}

    {/* Icon Star */}
    {status === 3 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 424 160"
        {...props}
      >
        <path
          d="M14.145 176.528L-6.43161 130.895L172.505 137.854L97.0707 27.5825L140.514 7.32296L218.595 138.925L209.902 -0.000752302L246.748 3.30537L257.125 129.47L351.714 13.2723L399.121 44.1388L279.987 155.422L436.399 126.815L470.931 178.643L280.504 181.335L331.163 268.904L282.591 280.747L241.989 190.714L144.057 291.295L102.223 268.651L204.579 173.834L14.145 176.528Z"
          fill="currentColor"
        />
      </svg>
    )}
  </>
);
