import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { sharedApi } from "../../api";
import {
  CheckEmailType,
  CheckPhoneType,
  InviteType,
  QuestionType,
  SmsCheckType,
  SmsType,
} from "../type/shared-type";
import { setModals } from "../../../widgets/modals/model";
import { ModalsProperty } from "../../config";
import { setError } from "../slices/shared-slice";

export const sendQuestionThunk = createAsyncThunk(
  "shared/send-question",
  async (body: QuestionType, { rejectWithValue, dispatch }) => {
    try {
      await sharedApi.sendQuestion(body);
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error.response?.data.error);
    }
  }
);

export const sendInviteThunk = createAsyncThunk(
  "shared/send-invite",
  async (body: InviteType, { rejectWithValue, dispatch }) => {
    try {
      await sharedApi.sendInvite(body);
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error.response?.data.error);
    }
  }
);

export const sendSmsThunk = createAsyncThunk(
  "shared/send-sms",
  async (body: SmsType, { rejectWithValue, dispatch }) => {
    try {
      await sharedApi.sendSms(body);
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error.response?.data.error);
    }
  }
);

export const checkSmsThunk = createAsyncThunk(
  "shared/check-sms",
  async (body: SmsCheckType, { rejectWithValue, dispatch }) => {
    try {
      await sharedApi.checkSms(body);

      await dispatch(
        setModals({
          action: ModalsProperty.MODAL_CONFIRMATION_SUCCESSFULLY,
        })
      );

      await dispatch(setError(null));
    } catch (e) {
      const error = e as AxiosError<any>;
      await dispatch(setError(error.response?.data));
      return rejectWithValue(error.response?.data);
    }
  }
);

export const checkSmsAnonThunk = createAsyncThunk(
  "shared/check-sms-anon",
  async (body: SmsCheckType, { rejectWithValue, dispatch }) => {
    try {
      await sharedApi.checkSmsAnon(body);

      await dispatch(
        setModals({
          action: ModalsProperty.MODAL_CONFIRMATION_SUCCESSFULLY_ANON,
        })
      );

      await dispatch(setError(null));
    } catch (e) {
      const error = e as AxiosError<any>;
      await dispatch(setError(error.response?.data));
      return rejectWithValue(error.response?.data);
    }
  }
);

export const checkEmailThunk = createAsyncThunk(
  "shared/check-email",
  async (body: CheckEmailType, { rejectWithValue, dispatch }) => {
    try {
      const response = await sharedApi.checkEmail(body);
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error.response?.data.error);
    }
  }
);

export const checkPhoneThunk = createAsyncThunk(
  "shared/check-phone",
  async (body: CheckPhoneType, { rejectWithValue, dispatch }) => {
    try {
      const response = await sharedApi.checkPhone(body);
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error.response?.data.error);
    }
  }
);

export const getVkRepostThunk = createAsyncThunk(
  "shared/get-vk-repost",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await sharedApi.getVkRepost();
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error.response?.data.error);
    }
  }
);

export const getOkRepostThunk = createAsyncThunk(
  "shared/get-ok-repost",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await sharedApi.getOkRepost();
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error.response?.data.error);
    }
  }
);

export const getQrTransition = createAsyncThunk(
  "shared/qr-transition",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await sharedApi.qr_transition();
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error.response?.data.error);
    }
  }
);
