import styled from "styled-components";
import {breakpoints} from "../../../config";
import imageBefore from "./../../../config/assets/images/banner-card-profile-before.png"
import imageAfter from "./../../../config/assets/images/banner-card-profile-after.png"
import imageBeforeXl from "./../../../config/assets/images/banner-card-profile-before-xl.png"
import imageAfterXl from "./../../../config/assets/images/banner-card-profile-after-xl.png"
import imageBeforeLg from "./../../../config/assets/images/banner-card-profile-before-lg.png"
import imageAfterLg from "./../../../config/assets/images/banner-card-profile-after-lg.png"
import imageBeforeMd from "./../../../config/assets/images/banner-card-profile-before-md.png"
import imageAfterMd from "./../../../config/assets/images/banner-card-profile-after-md.png"

const BannerCardProfile1 = styled.a`
    position: relative;
    display: block;
    width: 100%;
    padding: 36px 36px 24px;
    border-radius: 32px;
    background: linear-gradient(97deg, #FFE1EE 1.9%, #F2EFFF 56.58%);
    overflow: hidden;
    transition: transform 300ms ease;
    will-change: transform;

    &:hover {
        transform: scale(1.02);
    }

    &::before {
        content: "";
        position: absolute;
        left: 21px;
        bottom: 56px;
        z-index: 1;
        width: 169px;
        height: 199px;
        background: url(${imageBefore}) no-repeat center center/100%;
    }

    &::after {
        content: "";
        position: absolute;
        right: 30px;
        bottom: 80px;
        z-index: 0;
        width: 168px;
        height: 214px;
        background: url(${imageAfter}) no-repeat center center/100%;
    }

    @media (max-width: ${breakpoints.XXL}) {
        padding: 36px 30px 20px;

        &::before {
            left: 16px;
            bottom: 38px;
            width: 192px;
            height: 232px;
        }

        &::after {
            right: 11px;
            bottom: 49px;
            width: 178px;
            height: 235px;
        }
    }

    @media (max-width: ${breakpoints.XL}) {
        height: 190px;
        padding: 36px;

        &::before {
            left: 0;
            bottom: 0;
            width: 195px;
            height: 192px;
            background: url(${imageBeforeXl}) no-repeat center center/100%;
        }

        &::after {
            right: 0;
            bottom: 0;
            width: 176px;
            height: 192px;
            background: url(${imageAfterXl}) no-repeat center center/100%;
        }
    }

    @media (max-width: ${breakpoints.LG}) {
        height: 180px;
        padding: 36px 36px 32px;

        &::before {
            left: 22px;
            width: 213px;
            height: 182px;
            background: url(${imageBeforeLg}) no-repeat center center/100%;
        }

        &::after {
            right: 24px;
            width: 206px;
            height: 182px;
            background: url(${imageAfterLg}) no-repeat center center/100%;
        }
    }

    @media (max-width: ${breakpoints.MD}) {
        height: 140px;
        padding: 26px 18px;
        border-radius: 24px;

        &::before {
            top: 0;
            right: 81px;
            left: unset;
            bottom: unset;
            width: 130px;
            height: 130px;
            background: url(${imageBeforeMd}) no-repeat center center/100%;
        }

        &::after {
            right: 0;
            bottom: 0;
            width: 118px;
            height: 140px;
            background: url(${imageAfterMd}) no-repeat center center/100%;
        }
    }
`

const Title = styled.h2`
    margin-bottom: 192px;
    font: var(--font-7);
    color: var(--interface-dark);
    text-align: center;

    @media (max-width: ${breakpoints.XL}) {
        max-width: 220px;
        margin: 0 auto 24px auto;
    }

    @media (max-width: ${breakpoints.MD}) {
        max-width: 148px;
        margin: 0 auto 16px 0;
        text-align: left;
    }
`

const Text = styled.p`
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 16px 24px;
    font: var(--font-8);
    color: var(--brand-3);
    background-color: var(--brand-1);
    border-radius: 20px;
    text-align: center;

    @media (max-width: ${breakpoints.XL}) {
        margin: 0 auto;
        max-width: 230px;
        border-radius: 20px;
    }

    @media (max-width: ${breakpoints.LG}) {
        max-width: 210px;
        padding: 12px 24px;
    }

    @media (max-width: ${breakpoints.MD}) {
        margin: 0 auto 0 0;
        max-width: 148px;
        padding: 5px 24px 7px;
    }
`

export {BannerCardProfile1, Title, Text};
