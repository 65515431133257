import styled from "styled-components";
import { breakpoints } from "../../../config";

type CardFeedingType = {
  $cardIcon: string;
};

const CardFeeding = styled.article<CardFeedingType>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 266px;
  max-height: 266px;
  padding: 0 19px 5px 16px;
  background-image: url(${(props) => props.$cardIcon});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--brand-4);
  border-radius: 32px;
  overflow: hidden;
  cursor: pointer;
  will-change: transform;
  transition: ease-in-out 250ms;

  @media (max-width: ${breakpoints.XXL}) {
    min-height: 246px;
  }

  @media (max-width: ${breakpoints.XL}) {
    min-height: 210px;
    padding: 24px;
  }

  @media (max-width: ${breakpoints.LG}) {
    justify-content: flex-start;
    background-position: bottom;
    padding: 20px;
  }

  @media (max-width: ${breakpoints.MD}) {
    min-height: 190px;
    background-size: auto;
    padding: 16px;
    border-radius: 26px;
  }

  @media (max-width: ${breakpoints.SM}) {
    background-position: right top;
    display: grid;
    grid-template-rows: 60% 40%;
    min-height: 100%;
  }

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const Text = styled.p`
  max-width: 210px;
  font: var(--font-12);
  color: var(--hyperlink);
  margin-top: 166px;
  text-align: center;
  z-index: 2;

  @media (max-width: ${breakpoints.XXL}) {
    margin-top: 156px;
  }

  @media (max-width: ${breakpoints.XL}) {
    max-width: 100%;
    margin-top: 126px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-top: 0;
    text-align: start;
  }

  @media (max-width: ${breakpoints.MD}) {
    grid-row: 2/3;
  }
`;

export { CardFeeding, Text };
