import styled from "styled-components";
import { breakpoints } from "../../../config";
import heroLine1_1440 from "../../../config/assets/images/hero-line-1-1440.svg";
import heroLine1_1280 from "../../../config/assets/images/hero-line-1-1280.svg";
import heroLine1_1024 from "../../../config/assets/images/hero-line-1-1024.svg";
import heroLine1_768 from "../../../config/assets/images/hero-line-1-768.svg";
import heroLine1_375 from "../../../config/assets/images/hero-line-1-375.svg";
import heroLine2_1440 from "../../../config/assets/images/hero-line-2-1440.svg";
import heroShape1440 from "../../../config/assets/images/hero-shape-1440.svg";
import heroShape768 from "../../../config/assets/images/hero-shape-768.svg";
import heroShape375 from "../../../config/assets/images/hero-shape-375.svg";

const Banner = styled.div`
  position: relative;
  padding: 56px;
  border-radius: 80px;
  border: 1px solid var(--pink);
  background-color: var(--brand-3);
  overflow: hidden;

  @media (max-width: ${breakpoints.XXL}) {
    padding-left: 50px;
  }

  @media (max-width: ${breakpoints.XL}) {
    padding: 45px;
  }

  @media (max-width: ${breakpoints.LG}) {
    padding: 33px 44px 86px;
    border-radius: 48px;
  }

  @media (max-width: ${breakpoints.MD}) {
    border-radius: 40px;
    min-height: 410px;
  }

  @media (max-width: ${breakpoints.SM}) {
    padding: 20px 23px 20px 30px;
    min-height: 466px;
  }

  @media (max-width: ${breakpoints.XS}) {
    padding-left: 20px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 355px;
    background-image: url(${heroLine1_1440});
    background-repeat: no-repeat;
    background-position: top;

    @media (max-width: ${breakpoints.XXL}) {
      top: -186px;
      background-image: url(${heroLine1_1280});
    }

    @media (max-width: ${breakpoints.XL}) {
      top: -213px;
      right: -161px;
      width: 950px;
      background-image: url(${heroLine1_1024});
    }

    @media (max-width: ${breakpoints.LG}) {
      top: 0;
      right: unset;
      left: 0;
      width: 560px;
      background-image: url(${heroLine1_768});
      background-position: top right;
    }

    @media (max-width: ${breakpoints.MD}) {
      top: -57px;
      right: -36px;
      left: unset;
      width: 730px;
      height: 200px;
      background-image: url(${heroLine1_375});
    }
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 166px;
    background-image: url(${heroLine2_1440});
    background-repeat: no-repeat;

    @media (max-width: ${breakpoints.LG}) {
      display: none;
    }
`;

const HeaderGroup = styled.hgroup`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  z-index: 5;

  @media (max-width: ${breakpoints.XXL}) {
    gap: 24px;
  }

  @media (max-width: ${breakpoints.MD}) {
    gap: 12px;
    max-width: 350px;
  }
`;

const HeroTitle = styled.h1`
  position: relative;
  font: var(--font-1);
  max-width: 405px;
  padding-left: 6px;
  margin-left: -6px;
  color: var(--brand-1);
  background-color: var(--brand-3);
  text-transform: uppercase;

  @media (max-width: ${breakpoints.XXL}) {
    max-width: 425px;
    padding-left: 20px;
    margin-left: -20px;
  }

  @media (max-width: ${breakpoints.XL}) {
    max-width: 355px;
    padding-left: 9px;
    margin-left: -9px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 215px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: 260px;
    padding-left: 6px;
    margin-left: -6px;
  }
`;

const ShapeWrapper = styled.div`
  position: absolute;
  top: 180px;
  right: -5px;
  background-image: url(${heroShape1440});
  background-repeat: no-repeat;
  background-position: 100%;
  width: 273px;
  height: 167px;
  z-index: 0;

  @media (max-width: ${breakpoints.XXL}) {
    top: 200px;
  }

  @media (max-width: ${breakpoints.XL}) {
    top: 175px;
  }

  @media (max-width: ${breakpoints.LG}) {
    top: unset;
    bottom: 0;
    right: 270px;
    background-image: url(${heroShape768});
    width: 245px;
    height: 140px;
    z-index: 5;
  }

  @media (max-width: ${breakpoints.MD}) {
    background-image: url(${heroShape375});
    width: 196px;
    height: 136px;
    right: 36vw;
  }

  @media (max-width: ${breakpoints.SM}) {
    right: -25px;
    bottom: 150px;
  }
`;

const ExtraText = styled.p`
  font: var(--font-2);
  font-weight: bold;
  position: absolute;
  top: 48px;
  left: 40px;
  max-width: 225px;
  color: var(--brand-1);
  text-align: center;
  transform: rotate(-8deg);

  @media (max-width: ${breakpoints.XL}) {
    top: 42px;
    left: 35px;
    max-width: 230px;
  }

  @media (max-width: ${breakpoints.LG}) {
    font-weight: unset;
    top: 43px;
    left: 16px;
    max-width: 208px;
  }

  @media (max-width: ${breakpoints.MD}) {
    top: 30px;
    left: 26px;
    max-width: 125px;
  }
`;

const Picture = styled.picture``;

const Source375 = styled.source``;

const Source375Webp = styled.source``;

const Source1024 = styled.source``;

const Source1024Webp = styled.source``;

const Image = styled.img`
  position: absolute;
  left: 14px;
  bottom: 0;
  z-index: 1;

  @media (max-width: ${breakpoints.XXL}) {
    left: 0;
  }

  @media (max-width: ${breakpoints.LG}) {
    right: -48px;
    bottom: -49px;
    left: unset;
    z-index: 6;
  }

  @media (max-width: ${breakpoints.MD}) {
    right: -3vw;
    bottom: 0;
  }

  @media (max-width: ${breakpoints.SM}) {
    left: 8vw;
    right: unset;
  }
`;

export {
  Banner,
  HeaderGroup,
  HeroTitle,
  ShapeWrapper,
  ExtraText,
  Picture,
  Source375,
  Source375Webp,
  Source1024,
  Source1024Webp,
  Image,
};
