import React, { FC, useState } from "react";
import * as s from "./Form-user-styles";
import { FormikProps } from "formik";
import { InitialValuesFormikRegistrationType } from "../../../pages";
import {
  deleteMaskPhone,
  useAppDispatch,
  useAppSelector,
} from "../../../shared/lib";
import { setModals } from "../../modals/model";
import { ModalsProperty } from "../../../shared/config";
import { selectAuth } from "../../../entities/auth/model";

type FormUserType = {
  className?: string;
  formik: FormikProps<InitialValuesFormikRegistrationType>;
};

export const FormUser: FC<FormUserType> = ({ className, formik }) => {
  const dispatch = useAppDispatch();
  const [isPhoneInput, setIsPhoneInput] = useState(false);
  const { isDisabledButton } = useAppSelector(selectAuth);

  const onShowModal = async () => {
    dispatch(
      setModals({
        action: ModalsProperty.MODAL_SEND_PHONE_ANON,
        content: {
          phone: deleteMaskPhone({ ...formik.getFieldProps("phone") }.value),
        },
      })
    );
  };

  const onShowInputPhone = () => {
    setIsPhoneInput(!isPhoneInput);
  };

  return (
    <s.FormUser className={className} onSubmit={formik.handleSubmit}>
      <s.SubTitle>Введите данные</s.SubTitle>

      <s.List>
        <s.Item>
          <s.InputText
            type={"text"}
            label={"Имя*"}
            {...formik.getFieldProps("first_name")}
            {...formik.getFieldMeta("first_name")}
            {...formik.getFieldHelpers("first_name")}
          />
        </s.Item>
        <s.Item>
          <s.InputText
            type={"text"}
            label={"Фамилия*"}
            {...formik.getFieldProps("last_name")}
            {...formik.getFieldMeta("last_name")}
            {...formik.getFieldHelpers("last_name")}
          />
        </s.Item>
        <s.Item>
          <s.InputText
            type={"text"}
            label={"E-mail*"}
            {...formik.getFieldProps("email")}
            {...formik.getFieldMeta("email")}
            {...formik.getFieldHelpers("email")}
          />
        </s.Item>

        {isPhoneInput && (
          <s.Item>
            <s.Wrap>
              <s.WrapInput>
                <s.InputPhone
                  label={"Телефон"}
                  {...formik.getFieldProps("phone")}
                  {...formik.getFieldMeta("phone")}
                  {...formik.getFieldHelpers("phone")}
                />

                <s.WrapIconInput
                  text={""}
                  number={"5"}
                  onClick={onShowInputPhone}
                  isPosition={
                    String({ ...formik.getFieldProps("phone") }.value).length >
                    0
                  }
                  disabled={true}
                />
              </s.WrapInput>

              <s.ButtonInput
                type={"button"}
                disabled={
                  !(
                    String({ ...formik.getFieldProps("phone") }.value).length >
                    17
                  ) || isDisabledButton
                }
                onClick={onShowModal}
              >
                Подтвердить
              </s.ButtonInput>
            </s.Wrap>
          </s.Item>
        )}
      </s.List>

      {!isPhoneInput && (
        <s.Button
          text={"+ Добавить номер телефона"}
          number={"5"}
          onClick={onShowInputPhone}
        />
      )}
    </s.FormUser>
  );
};
