import styled, { css } from "styled-components";
import { PopupBody } from "../../../../shared";
import { breakpoints } from "../../../../shared/config";
import { BodyAnimation } from "../../../../shared/ui/molecules/popup-body/Popup-body-styles";
import { ReactNode } from "react";

type ModalPromoCodeType = {
  $isTags?: boolean;
  $title?: string | ReactNode;
};

const ModalPromoCode = styled.div``;

const Modal = styled(PopupBody)`
  ${BodyAnimation} {
    max-width: 840px;
    padding: 50px 90px 60px 50px;
  }

  @media (max-width: ${breakpoints.XL}) {
    ${BodyAnimation} {
      max-width: 790px;
      padding: 50px;
    }
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      max-width: 100%;
      padding: 60px 56px 70px;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    ${BodyAnimation} {
      max-width: 100%;
      padding: 65px 30px 40px;
    }
  }
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font: var(--font-10);
  color: var(--text);
`;

const Text = styled.p<ModalPromoCodeType>`
  margin-bottom: ${(props) => (props.$isTags ? "20px" : "36px")};
  font: var(--font-4);
  color: var(--brand-1);

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: ${(props) => (props.$isTags ? "16px" : "36px")};
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: ${(props) => (props.$isTags ? "12px" : "36px")};
  }
`;

const Tags = styled.div`
  margin-bottom: 32px;
`;

const TagsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  @media (max-width: ${breakpoints.MD}) {
    gap: 4px;
  }
`;

const TagsItem = styled.li<ModalPromoCodeType>`
  font: var(--font-33);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    typeof props.$title === "string" &&
    props.$title.includes("рационы для котят")
      ? "var(--dark-Pink)"
      : "var(--red-text)"};
  background-color: ${(props) =>
    typeof props.$title === "string" &&
    props.$title.includes("рационы для котят")
      ? "var(--light-light-Pink)"
      : "var(--light-red-bg)"};
  padding: 8px 12px;
  border-radius: 56px;
`;

const List = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 32px;
`;

const Item = styled.li`
  &:nth-of-type(3) {
    grid-row: 2/3;
  }
`;

const SubList = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 4px;
`;

type SubItemType = {
  $isArray?: boolean;
};

const SubItem = styled.li<SubItemType>`
  position: relative;
  padding-left: 34px;
  font: var(--font-11);
  color: var(--text);

  ${(props) =>
    !props.$isArray &&
    css`
        &::before {
          content: "";
          position: absolute;
          top: 12px;
          left: 14px;
          transform: translateY(-50%);
          width: 6px;
          height: 6px;
          background-color: var(--iconography);
          border-radius: 50%;
        }

        @media (max-width: ${breakpoints.LG}) {
          &::before {
            top: 10px;
            left: 16px;
          }
      `}
`;

const SubTitle = styled.h3`
  margin-bottom: 12px;
  font: var(--font-9);
  color: var(--interface-dark);
`;

const SubText = styled.p`
  font: var(--font-11);
  color: var(--text);
`;

export {
  ModalPromoCode,
  Modal,
  Title,
  Text,
  Tags,
  TagsList,
  TagsItem,
  List,
  Item,
  SubTitle,
  SubText,
  SubList,
  SubItem,
};
