import styled from "styled-components";
import { Button } from "../../atom";
import { breakpoints } from "../../../config";

const BonusMechanics = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 54px;
  padding: 60px 60px 46px;
  border-radius: 80px;
  background-color: var(--background-form);
  border: 1px solid var(--pink);

  @media (max-width: ${breakpoints.XXL}) {
    gap: 38px;
    padding-bottom: 78px;
  }

  @media (max-width: ${breakpoints.XL}) {
    gap: 39px;
    padding: 46px 22px 40px 40px;
  }

  @media (max-width: ${breakpoints.LG}) {
    padding: 42px 32px 34px;
    gap: 25px;
    border-radius: 48px;
  }

  @media (max-width: ${breakpoints.MD}) {
    gap: 32px;
    background-color: var(--brand-3);
    border-radius: 40px;
    padding: 30px 20px 30px 30px;
  }
`;

const ButtonRoyals = styled(Button)`
  max-width: unset;

  @media (max-width: ${breakpoints.XL}) {
    width: auto;
    min-height: 62px;
    margin-right: 18px;
  }

  @media (max-width: ${breakpoints.LG}) {
    width: 100%;
    margin-right: 0;
  }

  @media (max-width: ${breakpoints.MD}) {
    width: auto;
    margin-right: 10px;
    min-height: 64px;
    border-radius: 20px;
  }

  &:hover {
    transform: scale(1.05);
    background-color: var(--brand-1);
  }

  &:active {
    transform: scale(0.97);
  }
`;

export { BonusMechanics, ButtonRoyals };
