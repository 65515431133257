import styled from "styled-components";
import {breakpoints} from "../../../config";

const CardWallet = styled.div`
    position: relative;
    gap: 12px;
    max-width: 100%;
    height: 100%;
    padding: 32px 20px;
    border-radius: 32px;
    background-color: var(--pink);
    overflow: hidden;

    @media (max-width: ${breakpoints.XL}) {
    }

    @media (max-width: ${breakpoints.LG}) {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    @media (max-width: ${breakpoints.MD}) {
        padding: 20px;
        border-radius: 24px;
    }
`;

const WrapIcon = styled.div`
`;

const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    z-index: 1;
`;

const Title = styled.h2`
    font: var(--font-6);
    color: var(--interface-dark);
    text-align: left;
    letter-spacing: -0.56px;
`;

const Text = styled.p`
    font: var(--font-19);
    color: var(--brand-2);
`;

export {CardWallet, WrapIcon, Text, Content, Title};
