import React, { FC, ReactNode } from "react";
import * as s from "./Card-royals-styles";

export type CardRoyalsType = {
  className?: string;
  title: string;
  royals: number;
  image: ReactNode;
  onClickCard?: () => void;
};

export const CardRoyals: FC<CardRoyalsType> = ({
  className,
  title,
  royals,
  image,
  onClickCard,
}) => {
  return (
    <s.CardRoyals className={className}>
      <s.Body onClick={onClickCard}>
        {image}

        <s.WrapCaption>
          <s.Caption>{royals}</s.Caption>

          <s.Icon />
        </s.WrapCaption>

        <s.Title>{title}</s.Title>
      </s.Body>
    </s.CardRoyals>
  );
};
