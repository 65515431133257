import React, { FC } from "react";
import * as s from "./Сard-feeding-styles";

export type CardFeedingType = {
  className?: string;
  text: string;
  icon: string;
  onClickCard?: () => void;
};

export const CardFeeding: FC<CardFeedingType> = ({
  className,
  text,
  icon,
  onClickCard,
}) => {
  return (
    <s.CardFeeding className={className} $cardIcon={icon} onClick={onClickCard}>
      <s.Text>{text}</s.Text>
    </s.CardFeeding>
  );
};
