import {type ActionReducerMapBuilder, createSlice, PayloadAction,} from "@reduxjs/toolkit";

import {NoInfer} from "react-redux";
import {
  getDiscountReceiptIdThunk,
  getDiscountReceiptsThunk,
  getHistoryRoyalsThunk,
  getPromoCodesThunk,
  getPromoCodesTypesThunk,
} from "../thunk/discount-thunk";
import {
  DiscountStateType,
  HistoryRoyalsType,
  initialStateDiscount as initialState,
  PromoCode,
  PromoCodeType,
  ReceiptType,
} from "../type/discount-type";
import {StatusProperty} from "../../../../shared/config";
import {formatDate} from "../../../../shared/lib";

const discountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<any>>) => {
    builder.addCase(
      getPromoCodesThunk.fulfilled.type,
      (state: DiscountStateType, action: PayloadAction<Array<PromoCode>>) => {
        state.promoCodes = action.payload?.sort((a, b) => {
          const dateA: Date = new Date(a.expired_date);
          const dateB: Date = new Date(b.expired_date);

          return dateB.getTime() - dateA.getTime();
        });
      }
    );

    builder.addCase(
      getPromoCodesTypesThunk.fulfilled.type,
      (
        state: DiscountStateType,
        action: PayloadAction<Array<PromoCodeType>>
      ) => {
        const filterPromoCodesTypes = (assignments: Array<string>) => {
          return action.payload?.filter((promoCode) =>
            assignments.includes(promoCode.assignment)
          );
        };

        state.promoCodesTypes = action.payload;
        state.promoCodesTypesYoung = filterPromoCodesTypes(["young"])?.sort(
          (a, b) => a.price - b.price
        );
        state.promoCodesTypesAdult = filterPromoCodesTypes([
          "adult",
          "grown_up",
        ])?.sort((a, b) => a.price - b.price);
        state.promoCodesTypesPartner = filterPromoCodesTypes(["partner"])?.sort(
          (a, b) => (!!a.index && !!b.index ? a.index - b.index : 0)
        );
      }
    );

    builder.addCase(
      getHistoryRoyalsThunk.fulfilled.type,
      (
        state: DiscountStateType,
        action: PayloadAction<Array<HistoryRoyalsType>>
      ) => {
        state.historyRoyals = action.payload;
      }
    );

    builder.addCase(
      getDiscountReceiptsThunk.fulfilled.type,
      (state: DiscountStateType, action: PayloadAction<Array<ReceiptType>>) => {
        const currentDate = new Date();
        const {day, month, year} = formatDate(
          `${currentDate.getFullYear()}-${
            currentDate.getMonth() + 1
          }-${currentDate.getDate()}`
        );

        const mixCurrentDate = `${year}-${month}-${day}`;

        const receiptsStatusInProcessAndSuccess = action.payload.filter(
          (receipt) =>
            receipt.receipt_status === StatusProperty.IN_PROCESS ||
            receipt.receipt_status === StatusProperty.SUCCESS
        );

        const receiptsCurrentDate = receiptsStatusInProcessAndSuccess.filter(
          (receipt) => receipt.dispatch_date === mixCurrentDate
        );

        state.isReceiptsCurrentDate = !(receiptsCurrentDate.length >= 3);
        state.receipts = action.payload?.sort((a, b) => {
          const dateA: Date = new Date(a.dispatch_date);
          const dateB: Date = new Date(b.dispatch_date);

          return dateB.getTime() - dateA.getTime();
        });
      }
    );

    builder.addCase(
      getDiscountReceiptIdThunk.fulfilled.type,
      (
        state: DiscountStateType,
        action: PayloadAction<{ api_uuid: string | null }>
      ) => {
        state.api_uuid = action.payload.api_uuid;
      }
    );
  },
  selectors: {
    selectDiscount: (state) => state,
    selectDiscountPromoCodes: (state) => state.promoCodes,
    selectDiscountPromoCodesTypes: (state) => state.promoCodesTypes,
    selectDiscountPromoCodesTypesYoung: (state) => state.promoCodesTypesYoung,
    selectDiscountPromoCodesTypesAdult: (state) => state.promoCodesTypesAdult,
    selectDiscountPromoCodesTypesPartner: (state) =>
      state.promoCodesTypesPartner,
    selectRoyalsHistory: (state) => state.historyRoyals,
    selectDiscountReceipts: (state) => state.receipts,
    selectDiscountReceiptId: (state) => state.api_uuid,
    selectIsReceiptsCurrentDate: (state) => state.isReceiptsCurrentDate,
  },
});

export const {reducer} = discountSlice;
export const discountReducer = reducer;
export const {
  selectDiscount,
  selectDiscountPromoCodes,
  selectDiscountPromoCodesTypes,
  selectDiscountPromoCodesTypesYoung,
  selectDiscountPromoCodesTypesAdult,
  selectDiscountPromoCodesTypesPartner,
  selectRoyalsHistory,
  selectDiscountReceipts,
  selectDiscountReceiptId,
} = discountSlice.selectors;
