import styled from "styled-components";
import {breakpoints} from "../../../config";

const ButtonInvite = styled.button`
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding: 20px 24px;
    font: var(--font-8);
    color: var(--text);
    border-radius: 24px;
    background-color: var(--brand-3);
    will-change: transform;
    transition: transform 300ms ease, color 300ms ease;


    @media (max-width: ${breakpoints.XL}) {
        padding: 16px;
        border-radius: 20px;
    }

    @media (max-width: ${breakpoints.LG}) {
        justify-content: center;
        padding: 18px 24px;
    }

    @media (max-width: ${breakpoints.MD}) {
        padding: 14px 24px;
    }

    &:active {
        transform: scale(0.95);
    }

    &:hover {
        transform: scale(1.05);
    }

    &:disabled {
        pointer-events: none;
        background-color: var(--brand-4);
        color: var(--interface);
    }

    &:focus-visible {
        outline: 2px solid var(--brand-2);
    }
`;

export {ButtonInvite};
