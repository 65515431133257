import styled from "styled-components";
import {IconInfo, PopupBody} from "../../../../shared";
import {BodyAnimation} from "../../../../shared/ui/molecules/popup-body/Popup-body-styles";
import {breakpoints} from "../../../../shared/config";

const ModalCheckEmail = styled.div``;

const Modal = styled(PopupBody)`
    ${BodyAnimation} {
        padding: 40px 80px 40px 40px;
        max-width: 620px;
    }

    padding: 0 20px;

    @media (max-width: ${breakpoints.LG}) {
        ${BodyAnimation} {
            margin: auto;
            padding: 40px 80px 40px 40px;
            border-radius: 32px;

            &::before {
                content: "";
                display: none;
            }
        }
    }

    @media (max-width: ${breakpoints.MD}) {
        ${BodyAnimation} {
            padding: 30px 45px 30px 30px;
        }
    }
`;

const Title = styled.h2`
    display: flex;
    align-items: center;
    gap: 12px;
    font: var(--font-3);
    color: var(--brand-1);
`;

const Icon = styled(IconInfo)`
    flex: 0 0 32px;
`

export {ModalCheckEmail, Modal, Title, Icon};
