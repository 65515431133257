import {FC, ReactNode} from "react";
import * as s from "./Banner-card-profile-1-styles";

type BannerCardProfile1Type = {
  className?: string
  title: ReactNode;
  text: string;
  href: string;
};

export const BannerCardProfile1: FC<BannerCardProfile1Type> = ({className, title, text, href}) => {
  return (
    <s.BannerCardProfile1 className={className} href={href} target={"_blank"}>
      <s.Title>{title}</s.Title>

      <s.Text>{text}</s.Text>
    </s.BannerCardProfile1>
  );
};