import {ModalConfirmation} from "../../../features";
import styled from "styled-components";
import {Title} from "../../../features/_modals/modal-confirmation/ui/Modal-confirmation-styles";
import {BodyAnimation} from "../../../shared/ui/molecules/modal-body/Modal-body-styles";
import {breakpoints} from "../../../shared/config";
import {
  ButtonInvite,
  ButtonTextIcon,
  IconOk,
  IconRoyals,
  IconRub,
  IconUserAdd,
  IconVk,
  PopupBody,
} from "../../../shared";
import {BodyAnimation as PopupBodyAnimation} from "../../../shared/ui/molecules/popup-body/Popup-body-styles";

const ModalConfirmationPhone = styled(ModalConfirmation)`
    ${Title} {
        max-width: 575px;
        margin-bottom: 32px;
        color: var(--brand-1);
    }

    ${BodyAnimation} {
        max-width: 702px;
    }

    @media (max-width: ${breakpoints.LG}) {
        ${Title} {
            max-width: unset;
            color: var(--brand-1);
        }

        ${BodyAnimation} {
            max-width: 100%;
        }
    }
`;

const SpanIconRoyal = styled.span`
    display: inline-flex;
    align-items: center;
    gap: 8px;
`

const IconRoyal = styled(ButtonTextIcon)`
    display: inline-flex;
    transform: translateY(3px);

    @media (max-width: ${breakpoints.MD}) {
        transform: translateY(1px);
    }
`

const IconRoy = styled(IconRoyals)`
    width: 40px;
    height: 40px;
    vertical-align: middle;

    @media (max-width: ${breakpoints.LG}) {
        width: 32px;
        height: 32px;
    }
`;

const ButtonInviteModal = styled(ButtonInvite)`
    max-width: 100%;
    background-color: var(--brand-1);
    color: var(--brand-3);
`;

const IconButtonVk = styled(IconVk)`
    width: 32px;
    height: 32px;
    color: var(--brand-1);
`;

const IconButtonOk = styled(IconOk)`
    width: 32px;
    height: 32px;
    color: var(--brand-3);
`;

const IconUser = styled(IconUserAdd)`
    width: 32px;
    height: 32px;
    color: var(--brand-3);
`;

const RubleIcon = styled(IconRub)`
    width: 26px;
    height: 26px;
    color: currentColor;

    @media (max-width: ${breakpoints.XL}) {
        width: 23px;
        height: 23px;
    }

    @media (max-width: ${breakpoints.MD}) {
        width: 17px;
        height: 17px;
    }
`;

const TextModalConfirmationPhone = styled.p`
    margin-bottom: 10px;
    font: var(--font-9);
    color: var(--interface-dark);
`

const ButtonVK = styled(ButtonInvite)`
    color: var(--brand-1);
    justify-content: center;
    border: 1px solid var(--brand-1);
`

const ButtonOd = styled(ButtonInvite)`
    background-color: var(--brand-1);
    color: var(--brand-3);
`

const ModalErrorImage = styled(PopupBody)`
    ${PopupBodyAnimation} {
        max-width: 580px;
        padding: 40px 50px 60px;
        border-radius: 32px;
    }

    @media (max-width: ${breakpoints.XL}) {
        ${PopupBodyAnimation} {
            max-width: 540px;
            padding: 40px;
        }
    }

    @media (max-width: ${breakpoints.LG}) {
        padding: 0 20px;
        ${PopupBodyAnimation} {
            max-width: 530px;
            margin: auto;

            &::before {
                content: unset;
            }
        }
    }

    @media (max-width: ${breakpoints.MD}) {
        ${PopupBodyAnimation} {
            max-width: 350px;
            padding: 24px;
            border-radius: 24px;
        }
    }
`;

const ModalErrorImageTitle = styled.h2`
    margin-bottom: 16px;
    font: var(--font-3);
    color: var(--brand-1);
`;

const ModalErrorImageText = styled.p`
    font: var(--font-11);
    color: var(--brand-2);
`;

export {
  ModalConfirmationPhone,
  SpanIconRoyal,
  IconRoyal,
  IconRoy,
  ButtonInviteModal,
  IconButtonVk,
  IconButtonOk,
  IconUser,
  RubleIcon,
  TextModalConfirmationPhone,
  ButtonVK,
  ButtonOd,
  ModalErrorImage,
  ModalErrorImageTitle,
  ModalErrorImageText,
};
