import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { breakpoints } from "../../../config";
import { IconMenuUpload } from "../../atom";

const MenuPersonalAreaMobile = styled.nav`
  padding: 21px 20px;
  background-color: var(--brand-3);
  border-top: 1px solid var(--interface);

  @media (max-width: ${breakpoints.MD}) {
    padding: 5px 0;
  }
`;

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Item = styled.li``;

const IconButton = styled(IconMenuUpload)`
  width: 64px;
  height: 65px;

  @media (max-width: ${breakpoints.MD}) {
    width: 45px;
    height: 45px;
  }
`;

const Button = styled.button``;

const Link = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  min-width: 140px;
  min-height: 78px;
  font: var(--font-23);
  font-weight: 700;
  color: var(--brand-2);
  letter-spacing: -0.36px;
  transition: color 300ms ease;

  &:hover {
    color: var(--interface-dark);
  }

  &.active {
    font: var(--font-22);
    color: var(--interface-dark);
  }

  @media (max-width: ${breakpoints.MD}) {
    gap: 0;
    min-width: 60px;
    min-height: 54px;

    & svg {
      width: 28px;
      height: 28px;
    }
  }
`;

export { MenuPersonalAreaMobile, List, Item, IconButton, Button, Link };
