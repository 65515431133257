import styled from "styled-components";
import {breakpoints} from "../../../config";

const BannerCardProfile = styled.a`
    display: block;
    width: 100%;
    height: 260px;
    border-radius: 32px;
    padding: 36px;
    transition: transform 300ms ease;
    will-change: transform;

    &:hover {
        transform: scale(1.05);
    }

    @media (max-width: ${breakpoints.XXL}) {
        padding: 38px 30px;
    }

    @media (max-width: ${breakpoints.XL}) {
        height: 192px;
        padding: 35px 30px;
    }

    @media (max-width: ${breakpoints.LG}) {
        height: 182px;
        padding: 26px 24px;
    }

    @media (max-width: ${breakpoints.MD}) {
        display: flex;
        flex-direction: column;
        height: 208px;
        padding: 16px 12px 8px;
        border-radius: 24px;
    }
`

const Title = styled.p`
    max-width: 184px;
    margin-bottom: 26px;
    font: var(--font-7);
    color: var(--interface-dark);

    @media (max-width: ${breakpoints.XXL}) {
        max-width: 166px;
        margin-bottom: 24px;
    }

    @media (max-width: ${breakpoints.XL}) {
        max-width: unset;
    }

    @media (max-width: ${breakpoints.LG}) {
        max-width: 170px;
    }

    @media (max-width: ${breakpoints.MD}) {
        flex: 1 1 auto;
        margin: 0 auto 0 auto;
        text-align: center;
    }
`

const Button = styled.div`
    max-width: 230px;
    width: 100%;
    padding: 18px 75px;
    font: var(--font-8);
    color: var(--brand-3);
    background-color: var(--brand-1);
    border-radius: 20px;

    @media (max-width: ${breakpoints.XXL}) {
    }

    @media (max-width: ${breakpoints.XL}) {
        padding: 15px 80px;
    }

    @media (max-width: ${breakpoints.LG}) {
        max-width: 165px;
        padding: 11px 49px;
        border-radius: 16px;
    }

    @media (max-width: ${breakpoints.MD}) {
        max-width: 100%;
        text-align: center;
        padding: 6px 43px;
        border-radius: 12px;
    }
`

export {BannerCardProfile, Title, Button}