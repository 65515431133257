import React, {FC, useEffect, useState} from "react";
import {esAuth} from "../../../entities";
import {editUserThunk} from "../../../entities/auth/model";
import {
  getDiscountReceiptIdThunk,
  getDiscountReceiptsThunk,
  ReceiptType,
  selectDiscountReceipts,
} from "../../../entities/discount/model";
import {Animation, IconCalendar, IconDoc, IconFolderCloud, IconInfo, IconQr, Receipt,} from "../../../shared";
import {breakpointsTs, ModalsProperty, StatusProperty,} from "../../../shared/config";
import {useAppDispatch, useAppSelector, useIsMobile, useScrollToTop, useSendImage,} from "../../../shared/lib";
import {SidebarPersonalArea} from "../../../widgets";
import {setModals} from "../../../widgets/modals/model";
import * as s from "./Personal-area-cheks-styles";
import {useParams} from "react-router-dom";
import {
  BannerPersonalAreaChecks
} from "../../../shared/ui/atom/banner-personal-area-checks/Banner-personal-area-checks";

type PersonalAreaCheksType = {};

type TooltipVisibilityType = {
  [key: number]: boolean;
};

export const PersonalAreaCheks: FC<PersonalAreaCheksType> = ({}) => {
  useScrollToTop();
  const dispatch = useAppDispatch();
  const params = useParams<{ isModal?: string }>();
  const receipts = useAppSelector(selectDiscountReceipts);
  const user = useAppSelector(esAuth.model.selectAuthUser);
  const [isMobile] = useIsMobile(breakpointsTs.LG);
  const [listMaxLength, setListMaxLength] = useState(!isMobile ? 3 : 9);
  const [tooltipVisibility, setTooltipVisibility] =
    useState<TooltipVisibilityType>({});
  const [errorTooltipVisibility, setErrorTooltipVisibility] =
    useState<TooltipVisibilityType>({});

  const formatterDate = (dateString: string): string => {
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  };

  const onChangeListMaxLength = () => {
    setListMaxLength((value) => value + 10);
  };

  const getStatusText = (status: StatusProperty): string => {
    switch (status) {
      case StatusProperty.SUCCESS:
        return "Чек принят";
      case StatusProperty.IN_PROCESS:
        return "Чек в обработке";
      case StatusProperty.ERROR:
        return "Ошибка";
      default:
        return "Ошибка";
    }
  };

  const onShowPopupReceipt = (): void => {
    dispatch(setModals({action: ModalsProperty.MODAL_RECEIPT}));
  };

  const onShowTooltip = (index: number) => (): void => {
    setTooltipVisibility((prev) => ({...prev, [index]: true}));
  };

  const onHideTooltip = (index: number) => (): void => {
    setTooltipVisibility((prev) => ({...prev, [index]: false}));
  };

  const onShowErrorTooltip = (index: number) => (): void => {
    setErrorTooltipVisibility((prev) => ({...prev, [index]: true}));
  };

  const onHideErrorTooltip = (index: number) => (): void => {
    setErrorTooltipVisibility((prev) => ({...prev, [index]: false}));
  };

  const [cb] = useSendImage((image) => {
    dispatch(
      editUserThunk({
        ...user,
        image: image,
      })
    );
  });
  const onEditAvatar = cb();

  useEffect(() => {
    if (receipts.length === 0) {
      dispatch(getDiscountReceiptsThunk());
    }

    if (params?.isModal) {
      onShowPopupReceipt();
    }
  }, []);

  useEffect(() => {
    dispatch(getDiscountReceiptIdThunk());
  }, []);

  return (
    <s.PersonalAreaContainer>
      <s.PersonalAreaCheks>
        <SidebarPersonalArea
          isMobile={isMobile}
          isAvatar={isMobile}
          onChangeInput={onEditAvatar}
          onClickMenuUpload={onShowPopupReceipt}
        />
        <div>
          <Animation isShow={isMobile}>
            <s.BannerInfo
              list={[
                {
                  title: "Требования к чеку",
                  list: [
                    {
                      text: "На фото только один чек",
                      icon: <IconDoc width={36} height={36}/>,
                    },

                    {
                      text: "Формат JPEG/PNG/PDF",
                      subText: "До 20Мб",
                      icon: <IconFolderCloud width={36} height={36}/>,
                    },
                  ],
                },

                {
                  title: "В чеке должно быть",
                  list: [
                    {text: "QR-код", icon: <IconQr width={36} height={36}/>},

                    {
                      text: "Дата и время",
                      subText: "(чек принимается к загрузке в\u00A0течение 10 дней с момента покупки)",
                      icon: <IconCalendar width={36} height={36}/>,
                    },
                  ],
                },
              ]}
              onClickButtonUpload={onShowPopupReceipt}
            />
          </Animation>

          <Animation isShow={true} delay={0.2}>
            <s.Title>Чеки</s.Title>
          </Animation>

          {receipts.length === 0 ? (
            <BannerPersonalAreaChecks
              title={"У вас еще нет загруженных чеков"}
              text={
                <>
                  Нажмите кнопку <s.BoldText>«Загрузить чек»,</s.BoldText> чтобы
                  получить роялсы
                </>
              }
            />
          ) : (
            <s.ReceiptList>
              {receipts
                .slice(0, listMaxLength)
                .map((check: ReceiptType, index: number) => (
                  <s.ReceiptItem key={index}>
                    <Receipt
                      index={index * 0.03}
                      dispatchDate={formatterDate(check.dispatch_date || "")}
                      isInfoBonusTooltip={!isMobile && !!check.royals}
                      isTooltipVisibility={tooltipVisibility[index]}
                      infoBonusTooltipCounterRoyals={String(check.royals) || ""}
                      infoBonusTooltipDate={formatterDate(
                        check.approved_date || ""
                      )}
                      onInfoBonusTooltipHandleMouseEnter={onShowTooltip(index)}
                      onInfoBonusTooltipHandleMouseLeave={onHideTooltip(index)}
                      infoBonusTooltipIcon={
                        <s.IconInfoBonus width={20} height={20}/>
                      }
                      isCounterRoyals={!!check.royals && isMobile}
                      counterRoyalsValue={String(check.royals) || ""}
                      approvedDate={formatterDate(check.approved_date || "")}
                      receiptStatus={check.receipt_status}
                      statusCheckText={
                        getStatusText(check.receipt_status) || ""
                      }
                      isErrorInfoTooltip={
                        check.receipt_status === StatusProperty.ERROR
                      }
                      errorInfoTooltipIcon={<IconInfo width={24} height={24}/>}
                      isErrorTooltipVisibility={errorTooltipVisibility[index]}
                      errorInfoTooltipStatus={check.status}
                      onErrorInfoTooltipHandleMouseErrorEnter={onShowErrorTooltip(
                        index
                      )}
                      onErrorInfoTooltipHandleMouseErrorLeave={onHideErrorTooltip(
                        index
                      )}
                    />
                  </s.ReceiptItem>
                ))}
              {listMaxLength < receipts.length && (
                <s.ReceiptListMoreButton onClick={onChangeListMaxLength}>
                  Показать ещё {Math.min(10, receipts.length - listMaxLength)}
                </s.ReceiptListMoreButton>
              )}
            </s.ReceiptList>
          )}
        </div>
      </s.PersonalAreaCheks>
    </s.PersonalAreaContainer>
  );
};
