import React, {FC, ReactNode} from "react";
import * as s from "./Modal-about-invitation-buttons-styles";

type ModalAboutInvitationButtonsType = {
  className?: string;
  onShowModal: () => void;
  title: ReactNode;
  list: Array<{ text: string }>;
  buttons: Array<ReactNode>;
};

export const ModalAboutInvitationButtons: FC<
  ModalAboutInvitationButtonsType
> = ({className, onShowModal, title, list, buttons}) => {
  return (
    <s.ModalAboutInvitationButtons className={className}>
      <s.Modal onClickButtonBg={onShowModal} onClickButtonIcon={onShowModal}>
        <s.Title>{title}</s.Title>
        <s.List>
          {list.map((item, index) => (
            <s.Item key={index}>
              <s.Text>
                <s.Number>{index + 1}.</s.Number>
                {item.text}
              </s.Text>
            </s.Item>
          ))}
        </s.List>
        <s.Buttons>
          {buttons.map((button) => (
            button && (
              <s.ItemButton>{button}</s.ItemButton>
            )
          ))}
        </s.Buttons>
      </s.Modal>
    </s.ModalAboutInvitationButtons>
  );
};
