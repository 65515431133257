import {ModalsProperty} from "../../../../shared/config";

export const cardsDiscountsMock = [
  {
    title: "Скидки на рационы для котят\u00A0и\u00A0щенков",
    modal: ModalsProperty.MODAL_PROMO_CODE,
    content: {
      title: "Скидки на рационы для котят\u00A0и\u00A0щенков",
      tags: ["Скидка 100 ₽ за 15 роялсов", "Скидка 300 ₽ за 45 роялсов", "Скидка 200 ₽ за 30 роялсов", "Скидка 400 ₽ за 60 роялсов", "Скидка 500 ₽ за 75 роялсов"],
      description: {
        "Как получить": [
          "Приобрести промокод можно за накопленные роялсы.",
          "Количество приобретаемых промокодов не ограничено.",
        ],
        "Где потратить":
          "Промокод можно использовать в интернет-магазине Zoozavr.ru и в розничных магазинах сети Зоозавр.",
        "Срок действия": [
          "Промокод можно использовать в течение 1-го месяца с момента его приобретения на Сайте.",
          "Использовать промокод можно только один раз.",
        ],
      },
    },
  },

  {
    title: "Скидки на рационы для\u00A0взрослых кошек\u00A0и\u00A0собак",
    modal: ModalsProperty.MODAL_PROMO_CODE,
    content: {
      title: "Скидки на рационы для\u00A0взрослых кошек\u00A0и\u00A0собак",
      isTags: true,
      description: {
        "Как получить": [
          "Промокод становится доступным, когда",
          [
            "вашему питомцу рекомендован переход на рацион для взрослых животных;",
            "ваш питомец зарегистрирован как взрослый.",
          ],
          "Приобрести промокод можно за накопленные роялсы.",
          "Как только вы приобретаете один из промокодов для взрослых кошек и собак, все остальные промокоды в данной категории перестают быть активными.",
        ],
        "Где потратить":
          "Промокод можно использовать в интернет-магазине Zoozavr.ru и в розничных магазинах сети Зоозавр.",
        "Срок действия": [
          "Промокод можно использовать в течение 1-го месяца с момента его приобретения на Сайте.",
          "Использовать промокод можно только один раз.",
        ],
      },
    },
  },

  {
    title: "Промокод на первую покупку рациона для\u00A0взрослого питомца",
    modal: ModalsProperty.MODAL_PROMO_CODE,
    content: {
      title: "Промокод на первую покупку рациона для\u00A0взрослого питомца",
      tags: ["Скидка 100 ₽ за 15 роялсов", "Скидка 300 ₽ за 45 роялсов", "Скидка 200 ₽ за 30 роялсов", "Скидка 400 ₽ за 60 роялсов", "Скидка 500 ₽ за 75 роялсов"],
      description: {
        "Как получить": [
          "Подарочный промокод активируется автоматически, когда вашему питомцу рекомендован переход на рацион для взрослых животных.",
          "Если ваш питомец уже взрослый, то данный промокод будет недоступен.",
        ],
        "Где потратить":
          "Промокод можно использовать только в розничных магазинах Зоозавр. Cкидка 99% действует при сумме покупки до 600 рублей включительно.",
        "Срок действия": [
          "Промокод можно использовать в течение 1-го месяца с момента его автоматической активации на Сайте.",
          "Использовать промокод можно только один раз.",
        ],
      },
    },
  },

  {
    title: "Подарки\u000Aот\u00A0партнера",
    modal: ModalsProperty.MODAL_PROMO_CODE,
    content: {
      title: "Подарки\u000Aот\u00A0партнера",
      tags: ["Груминг", "Наполнители", "Амуниция", "Средства гигиены"],
      description: {
        "Как получить": [
          "Промокод со скидкой откроется для получения, как только вы зарегистрируете свой первый чек на сайте. Промокод можно получить только один раз.",
        ],
        "Где потратить":
          "Промокод можно использовать согласно описания категории каждого подарка.",
        "Срок действия": [
          "Промокод можно использовать в течение 1-го месяца с момента его приобретения на Сайте.",
          "Использовать промокод можно только один раз.",
        ],
      },
    },
  },
];
