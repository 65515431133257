import styled from "styled-components";
import { PopupBody } from "../../../../shared";
import { breakpoints } from "../../../../shared/config";
import { BodyAnimation } from "../../../../shared/ui/molecules/popup-body/Popup-body-styles";

const ModalAdditionalRoyals = styled.div``;

const Modal = styled(PopupBody)`
  ${BodyAnimation} {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 36px;
    padding: 50px 50px 60px;
    max-width: 740px;
    border-radius: 40px;
  }

  @media (max-width: ${breakpoints.XL}) {
    ${BodyAnimation} {
      gap: 32px;
    }
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      padding: 56px 56px 70px;
      max-width: 100%;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    ${BodyAnimation} {
      padding: 65px 20px 35px;
    }
  }
`;

const Title = styled.h3`
  font: var(--font-4);
  color: var(--brand-1);
  max-width: 92%;
  letter-spacing: -1.2px;

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
    letter-spacing: -0.96px;
  }
`;

const List = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 90%;
  margin-left: 25px;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 100%;
    margin-left: 20px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 93%;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
  }
`;

const Item = styled.li`
  font: var(--font-11);
  color: var(--brand-2);
  list-style: unset;
  padding-left: 5px;

  @media (max-width: ${breakpoints.XL}) {
    padding-left: 2px;
  }
`;

const Text = styled.div``;

export { ModalAdditionalRoyals, Modal, Title, List, Item, Text };
