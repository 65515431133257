import styled from "styled-components";
import {ButtonUpload} from "../../atom";
import {breakpoints} from "../../../config";

const BannerProfileInfo = styled.section`
    display: flex;
    align-items: center;
    gap: 70px;
    padding: 36px 30px;
    background-color: var(--brand-4);
    border-radius: 42px;

    @media (max-width: ${breakpoints.XXL}) {
        padding: 30px;
        gap: 35px;
    }

    @media (max-width: ${breakpoints.XL}) {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }
`;

const Title = styled.h3`
    width: max-content;
    margin-bottom: 24px;
    font: var(--font-9);
    color: var(--interface-dark);

    @media (max-width: ${breakpoints.XL}) {
        margin-bottom: 20px;
    }
`;

const Wrap = styled.div`
    display: flex;
    gap: 70px;

    @media (max-width: ${breakpoints.XXL}) {
        gap: 50px;
    }
`;

const WrapList = styled.div``;

const List = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    @media (max-width: ${breakpoints.XXL}) {
        gap: 12px;
    }
`;

const Item = styled.li`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const Text = styled.p`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    font: var(--font-19);
    color: var(--hyperlink);
`;

const Span = styled.span`
    display: inline-block;
    max-width: 240px;
    font: var(--font-21);
    color: var(--text);

    @media (max-width: ${breakpoints.XXL}) {
        max-width: 210px;
    }
`;

const Button = styled(ButtonUpload)`
    max-width: 230px;
`;

export {
  BannerProfileInfo,
  Title,
  Wrap,
  WrapList,
  List,
  Item,
  Text,
  Span,
  Button,
};
