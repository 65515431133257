import styled from "styled-components";
import { breakpoints } from "../../../config";

const StepRoyals = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: ${breakpoints.XL}) {
    gap: 20px;
  }

  @media (max-width: ${breakpoints.LG}) {
    gap: 14px;
  }

  @media (max-width: ${breakpoints.MD}) {
    align-items: flex-start;
    gap: 12px;
  }
`;

const IndexShape = styled.div`
  position: relative;
`;

const Icon = styled.span`
  display: flex;
  width: max-content;
  color: var(--pink);
  background-color: var(--background-form);

  @media (max-width: ${breakpoints.MD}) {
    padding-right: 0;
    background-color: transparent;
  }

  & svg {
    width: 90px;
    height: 90px;

    @media (max-width: ${breakpoints.XL}) {
      width: 82px;
      height: 82px;
    }

    @media (max-width: ${breakpoints.MD}) {
      width: 56px;
      height: 56px;
    }
  }
`;

const Item = styled.div`
  color: var(--text);
  max-width: 80%;

  @media (max-width: ${breakpoints.LG}) {
    max-width: 50%;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: 100%;
  }
`;

const Index = styled.span`
  position: absolute;
  font: var(--font-3);
  top: 51%;
  left: 49%;
  transform: translate(-50%, -49%);
  color: var(--brand-1);
  z-index: 3;
`;

const Content = styled.p`
  line-height: 1;

  @media (max-width: ${breakpoints.LG}) {
    max-width: 280px;
    line-height: unset;
  }
`;

const FirstWord = styled.span`
  font: var(--font-3);
  color: var(--interface-dark);

  @media (max-width: ${breakpoints.MD}) {
    display: inline-block;
    width: 100%;
  }
`;

const Text = styled.p`
  font: var(--font-11);
  color: var(--text);
  margin-top: 2px;

  @media (max-width: ${breakpoints.LG}) {
    max-width: 83%;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
  }
`;

export { StepRoyals, IndexShape, Icon, Item, Index, Content, FirstWord, Text };
