import React, { FC } from "react";

export const IconsStepsRoyals: FC<
  React.SVGProps<SVGSVGElement> & { status: 1 | 2 | 3 | 4 | 5 }
> = ({ status, ...props }) => (
  <>
    {/* Icon circle 1 */}
    {status === 1 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox={"0 0 90 90"}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.9115 6.75125C28.1056 6.65759 8.61193 11.7886 2.8316 28.6304C-3.17004 46.117 1.16848 64.9394 16.3125 75.5441C30.5788 85.5342 55.5731 85.5526 69.8265 75.5441C84.8333 65.0067 93.6399 46.3965 87.6187 29.0764C81.8942 12.6095 63.3449 6.84296 45.9115 6.75125Z"
          fill="currentColor"
        />
      </svg>
    )}

    {/* Icon circle 2 */}
    {status === 2 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox={"0 0 90 90"}
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.7455 82.6574C62.5514 82.7511 82.0451 77.6201 87.8254 60.7783C93.827 43.2917 88.8315 27.4798 73.6875 16.875C59.4212 6.88493 35.0839 3.85606 20.8305 13.8645C5.82371 24.402 -2.98288 43.0122 3.03825 60.3323C8.76279 76.7992 27.3121 82.5657 44.7455 82.6574Z"
          fill="currentColor"
        />
      </svg>
    )}

    {/* Icon circle 3 */}
    {status === 3 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox={"0 0 80 80"}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M38.774.001C54.6-.082 71.929 4.48 77.067 19.45c5.335 15.544-4.087 31.57-17.548 40.995-12.681 8.88-29.333 9.603-42.003.706C4.176 51.784-3.651 35.241 1.701 19.846 6.789 5.208 23.277.083 38.774 0Z"
          clipRule="evenodd"
        />
      </svg>
    )}

    {/* Icon circle 4 */}
    {status === 4 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox={"0 0 80 80"}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M39.81.001C23.983-.082 6.655 4.48 1.517 19.45c-5.335 15.544 4.087 31.57 17.548 40.995 12.681 8.88 29.333 9.603 42.003.706 13.34-9.366 21.167-25.909 15.815-41.304C71.795 5.208 55.307.083 39.81 0Z"
          clipRule="evenodd"
        />
      </svg>
    )}

    {/* Icon circle 5 */}
    {status === 5 && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox={"0 0 80 80"}
        {...props}
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M38.774 67.473c15.827.084 33.155-4.477 38.293-19.448C82.402 32.481 72.98 16.456 59.519 7.03c-12.681-8.88-29.333-9.602-42.003-.706C4.176 15.691-3.651 32.233 1.701 47.629c5.088 14.637 21.576 19.763 37.073 19.844Z"
          clipRule="evenodd"
        />
      </svg>
    )}
  </>
);
