import {useFormik} from "formik";
import React, {FC, useCallback} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ButtonProperty, pages} from "../../../shared/config";
import {ValidationFormRecoverPasswordEmail} from "../config/validation/validation-recover-password";
import * as s from "./Recover-password-email-styles";
import {useAppDispatch, useScrollToTop} from "../../../shared/lib";
import {sendRecoveryNewPasswordThunk} from "../../../entities/recovery/model";

type RecoverPasswordEmailType = {};

export type InitialValuesFormikRecoverEmailType = {
  email: string;
};

export const RecoverPasswordEmail: FC<RecoverPasswordEmailType> = ({}) => {
  useScrollToTop();

  const params = useParams<{ email: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: params?.email || "",
    },

    validationSchema: ValidationFormRecoverPasswordEmail,

    onSubmit: async (
      body: InitialValuesFormikRecoverEmailType
    ): Promise<void> => {
      const response = await dispatch(
        sendRecoveryNewPasswordThunk({email: body.email})
      );
      if (response.meta.requestStatus === "fulfilled") {
        navigate(pages.recoverPasswordRestored.link);
      } else {
        if (!!response?.payload) {
          formik.setFieldError("email", String(response.payload).length > 1000 ? "Произошла ошибка. Пожалуйста, попробуйте ещё раз или свяжитесь с нашей службой поддержки." : response.payload as string);
        }
      }
    },
  });

  const routeToRegistration = useCallback(() => {
    navigate(pages.registration.link);
  }, [navigate]);

  const isValidEmail =
    (formik.errors.email && formik.touched.email) || !formik.values.email;

  return (
    <s.RecoverPasswordEmail>
      <s.Title>Восстановление пароля</s.Title>
      <s.Subtitle>Введите e-mail</s.Subtitle>
      <s.InfoText>
        Отправим на него новый пароль. Письмо может попасть в папку Спам
      </s.InfoText>

      <s.FormRecoverPasswordEmail onSubmit={formik.handleSubmit}>
        <s.InputText
          type={"text"}
          label={"E-mail"}
          {...formik.getFieldProps("email")}
          {...formik.getFieldMeta("email")}
          {...formik.getFieldHelpers("email")}
        />

        <s.ButtonRecoverCode
          property={ButtonProperty.SECONDARY}
          text={"Получить пароль"}
          type={"submit"}
          disabled={isValidEmail}
          onClickButton={() => {
          }}
        />
      </s.FormRecoverPasswordEmail>

      <s.ToRegistration onClick={routeToRegistration}>
        Нет аккаунта? <span>Зарегистрироваться</span>
      </s.ToRegistration>
    </s.RecoverPasswordEmail>
  );
};
