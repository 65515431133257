import React, { FC } from "react";

export const IconCalendarSquare: FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 7.47917C19 6.99592 18.6082 6.60417 18.125 6.60417H5.875C5.39175 6.60417 5 6.99592 5 7.47917V18.75C5 19.2333 5.39175 19.625 5.875 19.625H18.125C18.6082 19.625 19 19.2333 19 18.75V7.47917ZM5.75 5.5625C4.7835 5.5625 4 6.346 4 7.3125V18.9167C4 19.8832 4.7835 20.6667 5.75 20.6667H18.25C19.2165 20.6667 20 19.8832 20 18.9167V7.3125C20 6.346 19.2165 5.5625 18.25 5.5625H5.75Z"
      fill="#767676"
    />
    <path d="M5 9.20837H19V10.25H5V9.20837Z" fill="#767676" />
    <path
      d="M9 8.16663C8.72386 8.16663 8.5 7.93344 8.5 7.64579V4.52079C8.5 4.23314 8.72386 3.99996 9 3.99996C9.27614 3.99996 9.5 4.23314 9.5 4.52079V7.64579C9.5 7.93344 9.27614 8.16663 9 8.16663Z"
      fill="#767676"
    />
    <path
      d="M15 8.16663C14.7239 8.16663 14.5 7.93344 14.5 7.64579V4.52079C14.5 4.23314 14.7239 3.99996 15 3.99996C15.2761 3.99996 15.5 4.23314 15.5 4.52079V7.64579C15.5 7.93344 15.2761 8.16663 15 8.16663Z"
      fill="#767676"
    />
    <path
      d="M9.5 13.1146C9.5 13.5461 9.16421 13.8959 8.75 13.8959C8.33579 13.8959 8 13.5461 8 13.1146C8 12.6832 8.33579 12.3334 8.75 12.3334C9.16421 12.3334 9.5 12.6832 9.5 13.1146Z"
      fill="#767676"
    />
    <path
      d="M12.75 13.1146C12.75 13.5461 12.4142 13.8959 12 13.8959C11.5858 13.8959 11.25 13.5461 11.25 13.1146C11.25 12.6832 11.5858 12.3334 12 12.3334C12.4142 12.3334 12.75 12.6832 12.75 13.1146Z"
      fill="#767676"
    />
    <path
      d="M16 13.1146C16 13.5461 15.6642 13.8959 15.25 13.8959C14.8358 13.8959 14.5 13.5461 14.5 13.1146C14.5 12.6832 14.8358 12.3334 15.25 12.3334C15.6642 12.3334 16 12.6832 16 13.1146Z"
      fill="#767676"
    />
    <path
      d="M9.5 15.7188C9.5 16.1503 9.16421 16.5 8.75 16.5C8.33579 16.5 8 16.1503 8 15.7188C8 15.2873 8.33579 14.9375 8.75 14.9375C9.16421 14.9375 9.5 15.2873 9.5 15.7188Z"
      fill="#767676"
    />
    <path
      d="M12.75 15.7188C12.75 16.1503 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.1503 11.25 15.7188C11.25 15.2873 11.5858 14.9375 12 14.9375C12.4142 14.9375 12.75 15.2873 12.75 15.7188Z"
      fill="#767676"
    />
    <path
      d="M16 15.7188C16 16.1503 15.6642 16.5 15.25 16.5C14.8358 16.5 14.5 16.1503 14.5 15.7188C14.5 15.2873 14.8358 14.9375 15.25 14.9375C15.6642 14.9375 16 15.2873 16 15.7188Z"
      fill="#767676"
    />
  </svg>
);
