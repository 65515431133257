import styled from "styled-components";
import { breakpoints } from "../../../config";
import { WrapCaption } from "../../atom/card-royals/Card-royals-styles";

const CardsRoyals = styled.div``;

const ListCards = styled.ul`
  display: flex;
  gap: 24px;

  @media (max-width: ${breakpoints.XXL}) {
    gap: 12px;
  }

  @media (max-width: ${breakpoints.XL}) {
    gap: 16px;
  }

  @media (max-width: ${breakpoints.LG}) {
    gap: 8px;
  }

  @media (max-width: ${breakpoints.MD}) {
    flex-direction: column;
  }
`;

const ItemCards = styled.li`
  flex: 1;

  &:nth-of-type(1) {
    ${WrapCaption} {
      top: 50px;
      right: -32px;
      transform: rotate(10deg);

      @media (max-width: ${breakpoints.XL}) {
        top: unset;
        right: 45px;
        bottom: 5px;
      }

      @media (max-width: ${breakpoints.LG}) {
        right: 20px;
        bottom: -4px;
      }

      @media (max-width: ${breakpoints.MD}) {
        top: 30px;
        right: -14px;
        bottom: unset;
      }
    }
  }

  &:nth-of-type(2) {
    ${WrapCaption} {
      top: 40px;
      right: -17px;
      transform: rotate(-6deg);

      @media (max-width: ${breakpoints.XXL}) {
        right: -16px;
      }

      @media (max-width: ${breakpoints.XL}) {
        top: unset;
        right: 60px;
        bottom: 5px;
      }

      @media (max-width: ${breakpoints.LG}) {
        right: 35px;
        bottom: -5px;
        transform: rotate(-11deg);
      }

      @media (max-width: ${breakpoints.MD}) {
        top: 27px;
        right: 5px;
        bottom: unset;
      }
    }
  }

  &:nth-of-type(3) {
    ${WrapCaption} {
      top: 40px;
      right: -59px;
      transform: rotate(10deg);

      @media (max-width: ${breakpoints.XXL}) {
        top: 32px;
        right: -37px;
      }

      @media (max-width: ${breakpoints.XL}) {
        top: unset;
        right: 42px;
        bottom: -10px;
      }

      @media (max-width: ${breakpoints.LG}) {
        right: -23px;
        bottom: -7px;
      }

      @media (max-width: ${breakpoints.MD}) {
        top: 30px;
        right: -24px;
        bottom: unset;
      }
    }
  }
`;

export { CardsRoyals, ListCards, ItemCards };
