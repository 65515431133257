import styled from "styled-components";
import { breakpoints } from "../../../config";

const DuoFeeding = styled.section`
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media (max-width: ${breakpoints.XL}) {
    gap: 40px;
  }

  @media (max-width: ${breakpoints.LG}) {
    gap: 24px;
  }
`;

const HeadText = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${breakpoints.MD}) {
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }

  &:after {
    content: "";
    position: absolute;
    top: -104px;
    right: -32px;
    width: 490px;
    height: 395px;
    z-index: -1;

    @media (max-width: ${breakpoints.XXL}) {
      top: -105px;
      right: -30px;
    }

    @media (max-width: ${breakpoints.XL}) {
      background-size: contain;
      right: -97px;
      height: 385px;
    }

    @media (max-width: ${breakpoints.LG}) {
      top: -90px;
      right: -160px;
    }

    @media (max-width: ${breakpoints.MD}) {
      display: none;
    }
`;

const Title = styled.h2`
  font: var(--font-3);
  letter-spacing: -1.68px;
  color: var(--brand-1);
  max-width: 509px;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 415px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 350px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: 70%;
  }
`;

const Text = styled.p`
  font: var(--font-10);
  color: var(--brand-2);
  max-width: 313px;

  @media (max-width: ${breakpoints.XXL}) {
    max-width: 230px;
    margin-right: 19px;
  }

  @media (max-width: ${breakpoints.XL}) {
    max-width: 280px;
    margin-right: 0;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 227px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: 100%;
    color: var(--iconography);
    margin-right: 0;
  }
`;

export { DuoFeeding, HeadText, Title, Text };
