import styled from "styled-components";
import {IconRoyals} from "../_icons";

const ButtonTextIcon = styled.button`
    display: flex;
    align-items: center;
    gap: 10px;
    font: var(--font-14);
    color: var(--brand-1);

    &:disabled {
        cursor: unset;
    }
`

const WrapIcon = styled.div`
    display: flex;
    align-items: center;
    gap: 1px;
    padding: 4px 10px;
    border-radius: 49px;
    background-color: var(--brand-1);
`

const Number = styled.span`
    font: var(--font-30);
    color: var(--brand-3);
`

const Icon = styled(IconRoyals)`
    display: flex;
    width: 24px;
    height: 24px;
`

export {ButtonTextIcon, WrapIcon, Number, Icon}