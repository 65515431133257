import styled from "styled-components";
import {IconBannerCatSpecialGift, IconsStepsRoyals} from "../_icons";
import {breakpoints} from "../../../config";
import petStoryImage from "./../../../config/assets/images/icons/pet-story.svg";
import bannerImageCat from "./../../../config/assets/images/icons/banner-image-cat.svg";
import bannerImageCatXl from "./../../../config/assets/images/icons/banner-image-cat-xl.svg";
import bannerImageCatLg from "./../../../config/assets/images/icons/banner-image-cat-lg.svg";
import bannerImageCatMd from "./../../../config/assets/images/icons/banner-image-cat-md.svg";

const BannerProfileCat = styled.article`
    position: relative;
    padding: 36px 25px 112px 36px;
    background-color: var(--light-light-Pink);
    border-radius: 32px;
    height: 100%;
    overflow: hidden;

    &::before {
        content: ";";
        position: absolute;
        left: 45px;
        bottom: 29px;
        width: 73px;
        height: 34px;
        background: url(${petStoryImage}) no-repeat center center/100%;
    }

    &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 120px;
        height: 120px;
        background: url(${bannerImageCat}) no-repeat center center/100%;
    }

    @media (max-width: ${breakpoints.XXL}) {
        padding: 36px 16px 110px 32px;

        &::before {
            left: 140px;
            bottom: 4px;
        }

        &::after {
            width: 107px;
            height: 115px;
        }
    }

    @media (max-width: ${breakpoints.XL}) {
        padding: 36px 240px 17px 32px;

        &::before {
            left: unset;
            bottom: unset;
            top: 73px;
            right: 88px;
        }

        &::after {
            width: 239px;
            height: 221px;
            background: url(${bannerImageCatXl}) no-repeat center center/100%;
        }
    }

    @media (max-width: ${breakpoints.LG}) {
        padding: 36px 300px 53px 32px;

        &::before {
            top: 36px;
            right: 38px;
        }

        &::after {
            left: 290px;
            right: unset;
            width: 160px;
            height: 121px;
            background: url(${bannerImageCatLg}) no-repeat center center/100%;
        }
    }

    @media (max-width: ${breakpoints.MD}) {
        padding: 24px 24px 102px;

        &::before {
            top: unset;
            right: unset;
            left: 27px;
            bottom: 26px;
        }

        &::after {
            right: 0;
            left: unset;
            width: 138px;
            height: 110px;
            background: url(${bannerImageCatMd}) no-repeat center center/100%;
        }
    }
`;

const Title = styled.h2`
    margin-bottom: 10px;
    font: var(--font-6);
    color: var(--interface-dark);

    @media (max-width: ${breakpoints.XXL}) {
        margin-bottom: 16px;
    }

    @media (max-width: ${breakpoints.XL}) {
        margin-bottom: 12px;
    }

    @media (max-width: ${breakpoints.MD}) {
        margin-bottom: 8px;
    }
`;

const Text = styled.p`
    margin-bottom: 36px;
    font: var(--font-11);
    color: var(--text);

    @media (max-width: ${breakpoints.XXL}) {
        margin-bottom: 24px;
    }

    @media (max-width: ${breakpoints.MD}) {
        margin-bottom: 20px;
    }
`;

const List = styled.ol`
    position: relative;
    z-index: 1;
    display: grid;
    grid-auto-rows: var(--minmax);
    gap: 20px;
    margin-bottom: 20px;

    @media (max-width: ${breakpoints.XL}) {
        gap: 12px;
        margin-bottom: 12px;
    }

    @media (max-width: ${breakpoints.LG}) {
        margin-bottom: unset;
    }

    @media (max-width: ${breakpoints.MD}) {
        margin-bottom: 12px;
    }
`;

const Item = styled.li`
    display: flex;
    align-items: center;
    gap: 16px;

    &:nth-of-type(2) {
    }

    @media (max-width: ${breakpoints.LG}) {
        &:nth-of-type(2) {
            max-width: 230px;
        }
    }

    @media (max-width: ${breakpoints.MD}) {
        gap: 12px;

        &:nth-of-type(2) {
            max-width: unset;
        }
    }
`;

const WrapIcon = styled.div`
    position: relative;
    flex: 0 0 56px;
    color: var(--pink);

    @media (max-width: ${breakpoints.MD}) {
        flex: 0 0 40px;
    }
`;

const Index = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font: var(--font-3-1);
    color: var(--brand-1);
`;

const IconStep = styled(IconsStepsRoyals)`
    color: var(--pink);
`;

const WrapText = styled.div``;

const SubTitle = styled.h3`
    font: var(--font-15);
    color: var(--interface-dark);
`;

const SubText = styled.p`
    font: var(--font-21);
    color: var(--text);
`;

const SubTextSpan = styled.p`
    font: var(--font-21);
    color: var(--text);
`;

const Span = styled.span`
    display: block;
`

const WrapGift = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: ${breakpoints.LG}) {
        position: absolute;
        top: 99px;
        right: 26px;
        z-index: 0;
        flex-direction: column;
        max-width: 200px;
        text-align: center;
    }

    @media (max-width: ${breakpoints.MD}) {
        position: unset;
        flex-direction: row;
        gap: 12px;
        text-align: left;
        max-width: 250px;
    }
`;

const IconGift = styled(IconBannerCatSpecialGift)`
    flex: 0 0 56px;

    @media (max-width: ${breakpoints.LG}) {
        flex: 0 0 120px;
    }

    @media (max-width: ${breakpoints.MD}) {
        flex: 0 0 40px;
    }
`;

export {
  BannerProfileCat,
  Title,
  Text,
  List,
  Item,
  WrapIcon,
  IconStep,
  Index,
  WrapText,
  SubTitle,
  SubText,
  Span,
  WrapGift,
  IconGift,
};
