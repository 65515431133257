import React, { FC } from "react";
import * as s from "./Card-target-action-styles";
import { ButtonUploadType } from "../../atom";

type CardTargetActionType = {
  className?: string;
  title: string;
  text: string;
  footnote: string;
  onClickButtonUpload: () => void;
} & ButtonUploadType;

export const CardTargetAction: FC<CardTargetActionType> = ({
  className,
  title,
  text,
  footnote,
  textUploadButton,
  typeUploadButton,
  propertyUploadButton,
  iconUploadButton,
  disabledUploadButton,
  onClickButtonUpload,
}) => {
  return (
    <s.CardTargetAction className={className}>
      <s.LeftPart>
        <s.Content>
          <s.Title>{title}</s.Title>
          <s.Footnote>{footnote}</s.Footnote>
        </s.Content>

        <s.Button
          textUploadButton={textUploadButton}
          typeUploadButton={typeUploadButton}
          propertyUploadButton={propertyUploadButton}
          iconUploadButton={iconUploadButton}
          onClickButtonUpload={onClickButtonUpload}
          disabledUploadButton={disabledUploadButton}
        />
      </s.LeftPart>

      <s.RightPart>
        <s.MiniCard />
        <s.Text>{text}</s.Text>
      </s.RightPart>
    </s.CardTargetAction>
  );
};
