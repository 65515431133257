import styled, { css } from "styled-components";
import { IconCalendarSquare } from "../../../shared";

type SelectInputCalendarFormikType = {
  $isActive: boolean;
};

type IconSelectCalendarType = {
  $isError: boolean;
};

const SelectInputCalendarFormik = styled.div<SelectInputCalendarFormikType>`
  ${(props) =>
    props.$isActive &&
    css`
      position: relative;
      z-index: 10000000;
    `}
`;

const IconSelectCalendar = styled(IconCalendarSquare)<IconSelectCalendarType>`
  width: 24px;
  height: 24px;
  color: var(--iconography);

  ${(props) =>
    props.$isError &&
    css`
      color: var(--error);
    `}
`;

export { SelectInputCalendarFormik, IconSelectCalendar };
