import styled from "styled-components";
import { IconsCardsRoyals } from "../../atom";
import { breakpoints } from "../../../config";

const IconHeartPink = styled(IconsCardsRoyals)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 380px;
  height: 100%;
  color: var(--pink-for-svg);
`;

const IconWingPink = styled(IconsCardsRoyals)`
  position: absolute;
  top: 0;
  left: 0;
  width: 424px;
  height: 100%;
  color: var(--pink-for-svg);

  @media (max-width: ${breakpoints.XXL}) {
    width: 100%;
  }
`;

const IconStarPink = styled(IconsCardsRoyals)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 424px;
  height: 100%;
  color: var(--pink-for-svg);
`;

const IconShapePink = styled(IconsCardsRoyals)`
  position: absolute;
  top: 0;
  right: -54px;
  width: 253px;
  height: 280px;
  color: var(--pink-for-svg);

  @media (max-width: ${breakpoints.MD}) {
    right: -38px;
    width: 231px;
    height: 220px;
  }
`;

const AdditionalRoyals = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (max-width: ${breakpoints.LG}) {
    gap: 24px;
  }

  @media (max-width: ${breakpoints.MD}) {
    gap: 32px;
  }
`;

const Title = styled.h2`
  font: var(--font-3);
  letter-spacing: -1.68px;
  color: var(--brand-1);

  @media (max-width: ${breakpoints.MD}) {
    max-width: 70%;
    letter-spacing: -1.04px;
  }
`;

export {
  AdditionalRoyals,
  Title,
  IconHeartPink,
  IconWingPink,
  IconStarPink,
  IconShapePink,
};
