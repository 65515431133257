import React, { type FC, useEffect } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import {
  authPage,
  faqAnswersPage,
  mainPage,
  personalAreaPage,
  recoverPasswordPage,
  registrationPage,
  signInPage,
} from "../../../pages";
import { Modals, ws } from "../../../widgets";
import { useAppDispatch, useAppSelector } from "../../../shared/lib";
import { recoverPasswordEmailPage } from "../../../pages/recover-password-email";
import { recoverPasswordRestoredPage } from "../../../pages/recover-password-restored";
import { personalAreaProfilePage } from "../../../pages/personal-area-profile";
import { personalAreaChecksPage } from "../../../pages/personal-area-cheks";
import { personalAreaPromoCodePage } from "../../../pages/personal-area-promo-code";
import { rootPage } from "../../../pages/root";
import { esAuth } from "../../../entities";
import { NotificationsBarAction } from "../../../features";
import { pages } from "../../../shared/config";

type ConfigRoutersType = {};

export const ConfigRouters: FC<ConfigRoutersType> = () => {
  const dispatch = useAppDispatch();
  const { action, content } = useAppSelector(ws.model.selectModals);
  const { isAuthorization } = useAppSelector(esAuth.model.selectAuth);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <Outlet />
          <Modals action={action} content={content} />
          <NotificationsBarAction />
        </>
      ),
      children: [
        {
          path: "",
          element: <Navigate to={pages.main.link} />,
        },

        rootPage.config.router([
          authPage.config.router(isAuthorization, [
            registrationPage.config.router,
            signInPage.config.router,
            recoverPasswordPage.config.router([
              recoverPasswordEmailPage.config.router,
              recoverPasswordRestoredPage.config.router,
            ]),
          ]),

          mainPage.config.router,
        ]),

        personalAreaPage.config.router(isAuthorization, [
          personalAreaProfilePage.config.router,
          personalAreaChecksPage.config.router,
          personalAreaPromoCodePage.config.router,
        ]),

        faqAnswersPage.config.router,
      ],
    },
  ]);

  useEffect(() => {
    dispatch(esAuth.model.getUserThunk());
  }, []);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};
