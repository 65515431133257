import { FC } from "react";
import * as s from "./Banner-personal-area-promo-code-styles";

type BannerPersonalAreaPromoCodeType = {
  className?: string;
  title: string;
  text: string;
  span: string;
};
export const BannerPersonalAreaPromoCode: FC<
  BannerPersonalAreaPromoCodeType
> = ({ className, title, text, span }) => {
  return (
    <s.BannerPersonalAreaPromoCode className={className}>
      <s.Wrap>
        <s.Title>{title}</s.Title>

        <s.Text>
          {text} {span ? <s.Span>{span}</s.Span> : ""}
        </s.Text>
      </s.Wrap>
    </s.BannerPersonalAreaPromoCode>
  );
};
