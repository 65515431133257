import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {recoveryApi} from "../../api/recovery-api";
import {InitialValuesFormikRecoverEmailType} from "../../../../pages/recover-password-email";

export const sendRecoveryNewPasswordThunk = createAsyncThunk(
  "recovery/send-recovery-email",
  async (
    body: InitialValuesFormikRecoverEmailType,
    {rejectWithValue, dispatch}
  ) => {
    try {
      await recoveryApi.recovery_new_password(body);
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error?.response?.data);
    }
  }
);
