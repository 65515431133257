import styled from "styled-components";
import { breakpoints } from "../../../config";
import {
  Icon,
  Item,
  StepRoyals,
  Text,
} from "../../atom/step-royals/Step-royals-styles";

const StepsRoyals = styled.div``;

const ListSteps = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (max-width: ${breakpoints.LG}) {
    gap: 28px;
  }

  @media (max-width: ${breakpoints.MD}) {
    flex-direction: column;
    gap: 24px;
  }
`;

const ItemSteps = styled.li`
  @media (max-width: ${breakpoints.LG}) and (min-width: ${breakpoints.MD}) {
    &:last-child {
      ${StepRoyals} {
        position: absolute;
        top: 10px;
        right: 0;
        flex-direction: column;
        align-items: center;
        max-width: 225px;
      }

      ${Item} {
        text-align: center;
        max-width: 99%;
      }

      ${Icon} {
        svg {
          width: 140px;
          height: 140px;
        }
      }

      ${Text} {
        max-width: unset;
      }
    }
  }
`;

export { StepsRoyals, ListSteps, ItemSteps };
