import React, { FC } from "react";
import * as s from "./Discounts-cards-styles";
import { ModalsEnumType } from "../../../config";
import { CardsDiscounts } from "../../molecules/cards-discounts/Cards-discounts";
import { PromoCodeDescriptionType } from "../../../../entities/discount/model";

type AdditionalRoyalsType = {
  className?: string;
  id?: string;
  titleAdditionalRoyals: string;
  discountsCards: any;
  onClickCardDiscounts: (
    modalInfo: ModalsEnumType,
    content: PromoCodeDescriptionType
  ) => () => void;
};

export const DiscountsCards: FC<AdditionalRoyalsType> = ({
  className,
  id,
  titleAdditionalRoyals,
  discountsCards,
  onClickCardDiscounts,
}) => {
  return (
    <s.DiscountsCards className={className} id={id}>
      <s.Title>{titleAdditionalRoyals}</s.Title>

      <CardsDiscounts
        cards={discountsCards}
        onClickCardDiscounts={onClickCardDiscounts}
      />
    </s.DiscountsCards>
  );
};
