import React, { FC } from "react";

export const IconRoyalsBig: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 164 164"
    fill="none"
    {...props}
  >
    <path
      fill="#FF85B6"
      d="M18.322 98.801c-15.311-40.83 31.897-68.05 76.555-68.05 78.682 0 59.544 69.391 8.506 93.567-32.323 15.311-77.087-4.254-85.061-25.517Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M81.173 73.933c3.972 0 7.193-2.826 7.193-6.33 0-3.499-3.221-6.338-7.193-6.338-3.978 0-7.196 2.84-7.196 6.337 0 3.505 3.218 6.331 7.196 6.331ZM66.251 52.941c3.907-3.064 5.873-4.263 12.096-5.05 5.263-.666 9.87 1.443 10.542 1.658 8.275 2.646 13.869 11.725 12.758 20.714-.656 5.344-3.982 10.17-8.117 13.655 3.994 8.424 11.463 13.915 10.276 18.087-.819 2.883-1.658 3.63-4.322 4.735-1.714.706-4.178.522-5.594-.637-6.938-5.619-10.595-13.742-16.438-19.555-1.438-1.424-2.084-.79-2.084-.016.013 6.816.076 11.875-.301 15.208-.22 1.996-2.19 4.294-3.543 4.932-3.732 1.783-8.786-.372-9.438-4.364-.127-.715-1.166-3.8-1.225-17.871-.02-6.965 0-8.49 0-19.55 0-4.516 2.241-9.482 5.39-11.946Z"
      clipRule="evenodd"
    />
  </svg>
);
