import React, { FC } from "react";
import * as s from "./Card-discount-styles";

export type CardDiscountType = {
  className?: string;
  title: string;
  onClickCard?: () => void;
};

export const CardDiscount: FC<CardDiscountType> = ({
  className,
  title,
  onClickCard,
}) => {
  return (
    <s.CardDiscount className={className}>
      <s.Body onClick={onClickCard}>
        <s.Title>{title}</s.Title>
      </s.Body>
    </s.CardDiscount>
  );
};
