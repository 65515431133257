import styled from "styled-components";
import {breakpoints} from "../../../shared/config";
import {Button, DataWarning} from "../../../shared";

const FormUserPassword = styled.form``;

const SubTitle = styled.h3`
    grid-column: 1/2;
    grid-row: 1/2;
    margin-bottom: 24px;
    font: var(--font-9);
    color: var(--interface-dark);

    @media (max-width: ${breakpoints.MD}) {
        margin-bottom: 12px;
    }
`;

const ListCheckboxs = styled.ul`
    display: grid;
    grid-auto-rows: var(--minmax);
    gap: 8px;
    max-width: 570px;
    margin-bottom: 18px;
`;

const ItemCheckbox = styled.li``;

const Text = styled.p`
    font: var(--font-19);
    color: var(--brand-2);
`;

const Label = styled.div`
    font: var(--font-19);
    color: var(--text);
    align-self: center;
`;

const Link = styled.a`
    color: var(--brand-1);
`;

const WrapButton = styled.div`
    margin-top: 48px;

    @media (max-width: ${breakpoints.XL}) {
        margin-top: 54px;
    }

    @media (max-width: ${breakpoints.LG}) {
        margin-top: 64px;
    }

    @media (max-width: ${breakpoints.MD}) {
        margin-top: 60px;
    }
`;

const Warning = styled(DataWarning)`
    margin-bottom: 4px;
`;

const ButtonRegistration = styled(Button)`
    max-width: 380px;
    margin-bottom: 12px;
`;

const TextWrapButton = styled(Text)`
    @media (max-width: ${breakpoints.LG}) {
        max-width: 312px;
    }
`;

const LinkWrapButton = styled.a`
    font: var(--font-17);
`;

export {
  FormUserPassword,
  SubTitle,
  ListCheckboxs,
  ItemCheckbox,
  Text,
  Label,
  Link,
  WrapButton,
  Warning,
  ButtonRegistration,
  TextWrapButton,
  LinkWrapButton,
};
