import styled, { css } from "styled-components";
import { breakpoints } from "../../../config";
import { ButtonUploadProperty } from "../../../config/enum/button-upload-property";

type ButtonUploadType = {
  property?: ButtonUploadProperty.DEFAULT | ButtonUploadProperty.SECONDARY;
};

const ButtonUpload = styled.button<ButtonUploadType>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  min-height: 64px;
  padding: 16px 24px;
  font: var(--font-8);
  border-radius: 20px;
  letter-spacing: -0.48px;
  will-change: transform;
  transition: transform 300ms ease, background-color 300ms ease,
    color 300ms ease;

  @media (max-width: ${breakpoints.XL}) {
    min-height: 56px;
    padding: 16px 22px;
    border-radius: 16px;
  }

  & svg {
    width: 24px;
    height: 24px;

    @media (max-width: ${breakpoints.XL}) {
      width: 20px;
      height: 20px;
    }
  }

  ${(props) =>
    props.property === ButtonUploadProperty.DEFAULT &&
    css`
      color: var(--brand-3);
      background-color: var(--brand-1);

      &:focus-visible {
        outline: 2px solid var(--pink);
      }
    `};

  ${(props) =>
    props.property === ButtonUploadProperty.SECONDARY &&
    css`
      color: var(--brand-1);
      background-color: var(--brand-3);
    `};

  &:hover {
    transform: scale(1.05);
  }

  &:disabled {
    pointer-events: none;
    background-color: var(--brand-3);
    color: var(--inactive);
  }

  &:active {
    transform: scale(0.95);
  }
`;

export { ButtonUpload };
