import React, {FC} from "react";

export const IconPromosCard: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 164 164"
    fill="none"
    {...props}
  >
    <path
      fill="#FF85B6"
      stroke="#FF85B6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={8.328}
      d="M123.647 84.773c0-7.662 6.219-13.88 13.88-13.88v-5.552c0-22.208-5.552-27.76-27.76-27.76h-55.52c-22.21 0-27.761 5.552-27.761 27.76v2.776c7.662 0 13.88 6.218 13.88 13.88 0 7.662-6.218 13.88-13.88 13.88v2.776c0 22.209 5.552 27.761 27.76 27.761h55.521c22.208 0 27.76-5.552 27.76-27.76-7.661 0-13.88-6.219-13.88-13.88Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={8.328}
      d="M65.344 97.271 98.656 63.96"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={13.667}
      d="M98.619 97.268h.05M65.306 66.735h.05"
    />
  </svg>
);
