import React, { FC } from "react";
import * as s from "./Cards-discounts-styles";
import { ModalsEnumType } from "../../../config";
import { CardDiscount } from "../../atom/card-discount/Card-discount";
import { PromoCodeDescriptionType } from "../../../../entities/discount/model";

export type CardArrayDiscountsType = {
  title: string;
  modal: ModalsEnumType;
  content: PromoCodeDescriptionType;
};

type CardsDiscountsType = {
  className?: string;
  cards: Array<CardArrayDiscountsType>;
  onClickCardDiscounts: (
    modalInfo: ModalsEnumType,
    content: PromoCodeDescriptionType
  ) => () => void;
};

export const CardsDiscounts: FC<CardsDiscountsType> = ({
  className,
  cards,
  onClickCardDiscounts,
}) => {
  return (
    <s.CardsDiscounts className={className}>
      <s.ListCards>
        {cards.map((card, index) => (
          <s.ItemCards key={index}>
            <CardDiscount
              title={card.title}
              onClickCard={onClickCardDiscounts(card.modal, card.content)}
            />
          </s.ItemCards>
        ))}
      </s.ListCards>
    </s.CardsDiscounts>
  );
};
