import React, { FC } from "react";
import * as s from "./Additional-royals-styles";
import { CardsRoyals } from "../../molecules";
import { ModalsEnumType, ModalsProperty } from "../../../config";

type AdditionalRoyalsType = {
  className?: string;
  id?: string;
  titleAdditionalRoyals: string;
  onClickCardRoyals: (modalInfo: ModalsEnumType) => () => void;
};

export const AdditionalRoyals: FC<AdditionalRoyalsType> = ({
  className,
  id,
  titleAdditionalRoyals,
  onClickCardRoyals,
}) => {
  const cardsRoyalsMock = [
    {
      title: "Регистрируйтесь в\u00A0программе лояльности",
      royals: 30,
      image: <s.IconHeartPink status={1} />,
      modal: ModalsProperty.MODAL_ADDITIONAL_ROYALS_HEART,
    },

    {
      title: "Приглашайте друзей с\u00A0питомцами",
      royals: 10,
      image: <s.IconWingPink status={2} />,
      modal: ModalsProperty.MODAL_ADDITIONAL_ROYALS_WINGS,
    },

    {
      title: "Рассказывайте о\u00A0программе в\u00A0социальных сетях",
      royals: 5,
      image: <s.IconStarPink status={3} />,
      modal: ModalsProperty.MODAL_ADDITIONAL_ROYALS_STAR,
    },
  ];

  return (
    <s.AdditionalRoyals className={className} id={id}>
      <s.Title>{titleAdditionalRoyals}</s.Title>

      <CardsRoyals
        cards={cardsRoyalsMock}
        onClickCardRoyals={onClickCardRoyals}
      />
    </s.AdditionalRoyals>
  );
};
