import styled from "styled-components";
import { Animation, ButtonIcon } from "../../atom";
import { breakpoints, stylesScrollbarMixin } from "../../../config";

const PopupBody = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  display: flex;
  overflow: auto;
  ${stylesScrollbarMixin("4px", "4px")};
`;

const ButtonBg = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--interface-dark);
  opacity: 0.3;
  z-index: 0;
`;

const BodyAnimation = styled(Animation)`
  position: relative;
  margin: auto;
  width: 100%;
  padding: 40px 70px 40px 24px;
  background-color: var(--brand-3);
  border-radius: 40px;
  max-height: 100%;
  overflow: auto;

  @media (max-width: ${breakpoints.XL}) {
    padding: 50px 66px 60px 50px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin: auto 0 0 0;
    padding: 56px 76px 70px 56px;
    border-radius: 40px 40px 0 0;

    &::before {
      content: "";
      position: absolute;
      top: 16px;
      left: 50%;
      transform: translateX(-50%);
      width: 140px;
      border-radius: 160px;
      height: clamp(4px, 1vw, 5px);
      background-color: var(--interface);
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 40px 17px 30px 20px;

    &::before {
      width: 64px;
    }
  }
`;

const ButtonIconBody = styled(ButtonIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  top: 24px;
  right: 24px;

  &:hover {
    color: var(--interface-dark);
  }
`;

export { PopupBody, ButtonBg, BodyAnimation, ButtonIconBody };
