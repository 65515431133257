import styled, { css } from "styled-components";
import { ButtonTagProperty } from "../../../config";

type ButtonTagType = {
  property:
    | ButtonTagProperty.DEFAULT
    | ButtonTagProperty.SELECTED
    | ButtonTagProperty.ERROR;
  $isActive?: boolean;
};

const ButtonTag = styled.button<ButtonTagType>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 8px 16px;
  font: var(--font-18);
  border-radius: 100px;
  border: 1px solid transparent;
  letter-spacing: -0.64px;
  will-change: transform;
  transition: transform 300ms linear, background-color 300ms ease,
    color 300ms ease, border 300ms ease;

  ${(props) =>
    props.property === ButtonTagProperty.DEFAULT &&
    css`
      color: var(--text);
      background-color: var(--brand-4);
    `};

  ${(props) =>
    props.property === ButtonTagProperty.SELECTED &&
    css`
      color: var(--hyperlink);
      background-color: var(--background-form);
      border: 1px solid var(--text);
    `};

  ${(props) =>
    props.property === ButtonTagProperty.ERROR &&
    css`
      color: var(--error);
      background-color: var(--background-form);
      border: 1px solid var(--error);
    `};

  ${(props) =>
    props.$isActive &&
    css`
      background-color: var(--background-form);
      color: var(--hyperlink);
      border: 1px solid var(--text);
    `};

  &:hover {
    background-color: var(--interface);
    color: var(--hyperlink);
  }

  &:active {
    transform: scale(0.95);
  }

  &:focus-visible {
    outline: 2px solid var(--brand-1);
  }

  &:disabled {
    background-color: var(--inactive);
    pointer-events: none;
  }
`;

export { ButtonTag };
