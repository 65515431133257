import React, { FC, ReactNode } from "react";
import * as s from "./Cards-royals-styles";
import { CardRoyals } from "../../atom";
import { ModalsEnumType } from "../../../config";

export type CardArrayRoyalsType = {
  title: string;
  royals: number;
  image: ReactNode;
  modal: ModalsEnumType;
};

type CardsRoyalsType = {
  className?: string;
  cards: Array<CardArrayRoyalsType>;
  onClickCardRoyals: (modalInfo: ModalsEnumType) => () => void;
};

export const CardsRoyals: FC<CardsRoyalsType> = ({
  className,
  cards,
  onClickCardRoyals,
}) => {
  return (
    <s.CardsRoyals className={className}>
      <s.ListCards>
        {cards.map((card, index) => (
          <s.ItemCards key={index}>
            <CardRoyals
              title={card.title}
              royals={card.royals}
              image={card.image}
              onClickCard={onClickCardRoyals(card.modal)}
            />
          </s.ItemCards>
        ))}
      </s.ListCards>
    </s.CardsRoyals>
  );
};
