import styled, {css} from "styled-components";
import {breakpoints} from "../../../shared/config";
import {Input} from "../../../shared/ui/atom/input/Input-styles";
import {FieldPhoneFormik, FieldTextFormik} from "../../../features";
import {ButtonTextIcon} from "../../../shared";

const FormUser = styled.form`
    max-width: 788px;
`;

const SubTitle = styled.h3`
    margin-bottom: 24px;
    font: var(--font-9);
    color: var(--interface-dark);
`;

const List = styled.ul`
    display: grid;
    grid-auto-rows: var(--minmax);
    gap: 12px;

    @media (max-width: ${breakpoints.MD}) {
        gap: 8px;
    }
`;

const Item = styled.li``;

const InputCss = css`
    max-width: var(--input-width);

    ${Input} {
        background-color: var(--brand-4);

        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 1000px var(--brand-4) inset;
        }
    }
`;

const InputText = styled(FieldTextFormik)`
    ${InputCss}
`;

const Wrap = styled.div`
    display: inline-grid;
    grid-template-columns: var(--input-width) 1fr;
    align-items: flex-start;
    gap: 16px;

    @media (max-width: ${breakpoints.MD}) {
        grid-template-columns: var(--input-width);
    }
`

const WrapInput = styled.div`
    position: relative;
`;

const InputPhone = styled(FieldPhoneFormik)`
    ${InputCss}
`;

type WrapIconInputType = {
  isPosition?: boolean;
}

const WrapIconInput = styled(ButtonTextIcon)<WrapIconInputType>`
    position: absolute;
    top: 18px;
    right: 36px;
    margin-top: unset;
    transition: right 300ms ease;

    @media (max-width: ${breakpoints.XL}) {
        top: 13px;
    }

    ${props => props.isPosition && css`
        right: 48px;
    `}
`

const ButtonInput = styled.button`
    margin-top: 22px;
    font: var(--font-14);
    color: var(--brand-1);
    transition: transform 300ms ease;
    will-change: transform;

    &:disabled {
        pointer-events: none;
        color: var(--inactive);
    }

    @media (max-width: ${breakpoints.XL}) {
        margin-top: 17px;
    }

    @media (max-width: ${breakpoints.MD}) {
        margin-top: unset;
        justify-self: flex-start;
    }

    &:active {
        transform: scale(0.96);
    }
`

const Button = styled(ButtonTextIcon)`
    margin-top: 24px;

    @media (max-width: ${breakpoints.MD}) {
        margin-top: 20px;
    }
`

export {
  FormUser,
  SubTitle,
  List,
  Item,
  InputText,
  Wrap,
  WrapInput,
  InputPhone,
  WrapIconInput,
  Button,
  ButtonInput,
};
