import {ChangeEvent} from "react";
import {useAppDispatch} from "./use-app-dispatch";
import {setModals} from "../../../widgets/modals/model";
import {ModalsProperty} from "../../config";

const useSendImage = (cb: (image: string, id?: number) => void) => {
  const dispatch = useAppDispatch();
  const onEditAvatar = (id?: number) => async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files && files.length > 0) {
      const image = files[0];
      const isImage = image.type === "image/png" || image.type === "image/jpeg" || image.type === "image/jpg" || image.type === "image/webp";
      if (isImage) {
        const reader = new FileReader();

        reader.onload = (event: ProgressEvent<FileReader>) => {
          const imageDataUrl = (event.target as FileReader).result as string;
          cb(imageDataUrl, id)
        };
        reader.readAsDataURL(image);
      } else {
        dispatch(setModals({action: ModalsProperty.MODAL_ERROR_IMAGE}))
      }
    }
  }
  return [onEditAvatar] as const
}

export {useSendImage}