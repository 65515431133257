import {createAsyncThunk} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {authApi} from "../../api/auth-api";
import {RegistrationRequestBodyType, UserType} from "../type/auth-type";
import {setError} from "../slices/auth-slice";
import {setModals} from "../../../../widgets/modals/model";
import {ModalsProperty} from "../../../../shared/config";
import {esAuth} from "../../index";

export const getUserThunk = createAsyncThunk(
  "auth/me",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      const response = await authApi.me();
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
      localStorage.removeItem("token");
      return rejectWithValue(error.response?.data.error);
    }
  }
);

export const editUserThunk = createAsyncThunk(
  "auth/me",
  async (body: UserType, {dispatch, rejectWithValue}) => {
    try {
      const response = await authApi.editMe(body);
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
      return rejectWithValue(error.response?.data.error);
    }
  }
);

export const authRegistrationThunk = createAsyncThunk(
  "auth/registration",
  async (
    data: { body: RegistrationRequestBodyType; invitationToken?: string },
    {rejectWithValue, dispatch}
  ) => {
    try {
      const response = await authApi.registration(
        data.body,
        data.invitationToken
      )

      await dispatch(esAuth.model.setError(null));
      const responseJSON = JSON.stringify(response.data.tokens);
      localStorage.setItem("token", responseJSON);
      await dispatch(
        setModals({
          action: ModalsProperty.MODAL_SUCCESSFULLY_REGISTERED,
        })
      );
      return response.data;
    } catch (e) {
      const error = e as AxiosError<any>;
      if ("Unable to create account." === error.response?.data[0]) {
        dispatch(setError("Невозможно создать учетную запись."));
      } else {
        dispatch(setError(error.response?.data[0]));
      }
    }
  }
);
