import styled from "styled-components";
import { breakpoints } from "../../../config";
import { Banner } from "../../atom";
import { BonusMechanics } from "../../organisms";

const IconPetStory = styled.img`
  @media (max-width: ${breakpoints.XL}) {
    width: 82px;
    height: 82px;
  }

  @media (max-width: ${breakpoints.LG}) {
    width: 132px;
    height: auto;
  }

  @media (max-width: ${breakpoints.MD}) {
    width: 56px;
    height: 56px;
  }
`;

const Hero = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-height: 600px;

  @media (max-width: ${breakpoints.XL}) {
    min-height: 520px;
  }

  @media (max-width: ${breakpoints.LG}) {
    display: flex;
    flex-direction: column;
    min-height: unset;
  }
`;

const BannerStyles = styled(Banner)``;

const BonusMechanicsStyles = styled(BonusMechanics)`
  @media (max-width: ${breakpoints.MD}) {
    position: static;
    transform: none;
  }
`;

export { IconPetStory, Hero, BannerStyles, BonusMechanicsStyles };
