import styled from "styled-components";
import { breakpoints } from "../../../config";
import bannerCheckPromoCode45 from "./../../../config/assets/images/icons/banner-check-promo-code-45.svg";
import bannerCheckPromoCode45Xl from "./../../../config/assets/images/icons/banner-check-promo-code-45-xl.svg";
import bannerCheckPromoCode45Md from "./../../../config/assets/images/icons/banner-check-promo-code-45-md.svg";
import bannerCheckPromoCode75 from "./../../../config/assets/images/icons/banner-check-promo-code-75.svg";
import bannerCheckPromoCode75Xxl from "./../../../config/assets/images/icons/banner-check-promo-code-75-xxl.svg";
import bannerCheckPromoCode75Xl from "./../../../config/assets/images/icons/banner-check-promo-code-75-xl.svg";
import bannerCheckPromoCode75Lg from "./../../../config/assets/images/icons/banner-check-promo-code-75-lg.svg";
import bannerCheckPromoCode75Md from "./../../../config/assets/images/icons/banner-check-promo-code-75-md.svg";

const BannerPersonalAreaPromoCode = styled.section`
  position: relative;
  height: 220px;
  padding: 50px 40px;
  border-radius: 36px;
  background: linear-gradient(97deg, #ffe8eb 1.9%, #ffd5d9 56.58%);
  overflow: hidden;
  margin-bottom: 5px;

  &::before {
    content: "";
    position: absolute;
    right: 240px;
    bottom: 0;
    z-index: 0;
    width: 290px;
    height: 172px;

    background: url(${bannerCheckPromoCode45}) no-repeat center center/100%;
  }

  &::after {
    content: "";
    top: 0;
    position: absolute;
    top: 0;
    right: 13px;
    z-index: 0;
    width: 275px;
    height: 168px;

    background: url(${bannerCheckPromoCode75}) no-repeat center center/100%;
  }

  @media (max-width: ${breakpoints.XXL}) {
    &::before {
      right: 176px;
      height: 175px;
    }

    &::after {
      right: 0;
      width: 225px;
      height: 166px;
      background: url(${bannerCheckPromoCode75Xxl}) no-repeat center center/100%;
    }
  }

  @media (max-width: ${breakpoints.XL}) {
    height: 265px;
    padding: 78px 32px;

    &::before {
      right: 66px;
      width: 212px;
      height: 133px;
      background: url(${bannerCheckPromoCode45Xl}) no-repeat center center/100%;
    }

    &::after {
      width: 189px;
      height: 139px;
      background: url(${bannerCheckPromoCode75Xl}) no-repeat center center/100%;
    }
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-right: 40px;

    &::before {
      right: 110px;
      width: 255px;
      height: 162px;
    }

    &::after {
      width: 214px;
      height: 135px;
      background: url(${bannerCheckPromoCode75Lg}) no-repeat center center/100%;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    height: 235px;
    padding: 16px 34px 148px;
    margin-right: 12px;
    border-radius: 28px;

    &::before {
      left: 0;
      right: unset;
      width: 173px;
      height: 105px;
      background: url(${bannerCheckPromoCode45Md}) no-repeat center center/100%;
    }

    &::after {
      top: unset;
      bottom: 0;
      width: 170px;
      height: 137px;
      background: url(${bannerCheckPromoCode75Md}) no-repeat center center/100%;
    }
  }
`;

const Wrap = styled.div`
  position: relative;
  z-index: 1;
  max-width: 300px;

  @media (max-width: ${breakpoints.MD}) {
    margin: 0 auto;
  }
`;

const Title = styled.h2`
  margin-bottom: 16px;
  font: var(--font-27);
  color: var(--interface-dark);

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 12px;
    text-align: center;
  }
`;

const Text = styled.p`
  font: var(--font-24);
  color: var(--text);

  @media (max-width: ${breakpoints.MD}) {
    text-align: center;
  }
`;

const Span = styled.span`
  font: var(--font-32);
  color: var(--interface-dark);
`;

export { BannerPersonalAreaPromoCode, Wrap, Title, Text, Span };
