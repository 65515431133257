import { FC, ReactNode } from "react";
import * as s from "./Banner-personal-area-checks-styles";

type BannerPersonalAreaPromoCodeType = {
  className?: string;
  title: string;
  text: ReactNode;
};
export const BannerPersonalAreaChecks: FC<BannerPersonalAreaPromoCodeType> = ({
  className,
  title,
  text,
}) => {
  return (
    <s.BannerPersonalAreaPromoCode className={className}>
      <s.Wrap>
        <s.Title>{title}</s.Title>

        <s.Text>{text}</s.Text>
      </s.Wrap>
    </s.BannerPersonalAreaPromoCode>
  );
};
