import React, {FC, ReactNode} from "react";
import * as s from "./Modal-promo-code-styles";
import {AssignmentType, PromoCodeDescriptionType,} from "../../../../entities/discount/model";

type ModalPromoCodeType = {
  className?: string;
  onShowModal: () => void;
  title: string | ReactNode;
  tags?: Array<string>;
  assignment?: AssignmentType;
  description: PromoCodeDescriptionType;
};

export const ModalPromoCode: FC<ModalPromoCodeType> = ({
                                                         className,
                                                         onShowModal,
                                                         title,
                                                         tags,
                                                         assignment,
                                                         description,
                                                       }) => {
  const titles = Object.keys(description);

  const text =
    assignment === "young"
      ? "Скидки на рационы для котят и щенков"
      : assignment === "adult" || assignment === "grown_up"
        ? "Скидки на рационы для взрослых кошек и собак"
        : "Подарки от партнера";

  return (
    <s.ModalPromoCode className={className}>
      <s.Modal onClickButtonBg={onShowModal} onClickButtonIcon={onShowModal}>
        {assignment && <s.Title>{text}</s.Title>}
        <s.Text as={assignment ? "p" : "h2"} $isTags={!!tags}>
          {title}
        </s.Text>


        {!!tags && (
          <s.Tags>
            <s.TagsList>
              {tags.map(tag =>
                <s.TagsItem key={tag} $title={title}>{tag}</s.TagsItem>
              )}
            </s.TagsList>
          </s.Tags>
        )}

        <s.List>
          {titles.map((title, index) => (
            <s.Item key={index}>
              <s.SubTitle>{title}</s.SubTitle>

              {Array.isArray(description[title]) &&
              description[title].length > 1 ? (
                <s.SubList>
                  {(description[title] as string[]).map(
                    (text: string | Array<string>, index) => (
                      <s.SubItem $isArray={Array.isArray(text)} key={index}>
                        {Array.isArray(text) ? (
                          <s.SubList>
                            {text.map((el) => (
                              <s.SubItem key={el}>{el}</s.SubItem>
                            ))}
                          </s.SubList>
                        ) : (
                          <s.SubText>{text}</s.SubText>
                        )}
                      </s.SubItem>
                    )
                  )}
                </s.SubList>
              ) : (
                <s.SubText>{description[title]}</s.SubText>
              )}
            </s.Item>
          ))}
        </s.List>
      </s.Modal>
    </s.ModalPromoCode>
  );
};
