import styled, { css } from "styled-components";
import { Avatar } from "../../atom";
import { breakpoints } from "../../../config";

type CardPetType = {
  $dateEnd: string | null;
};

const CardPet = styled.article`
  padding: 32px 32px 24px;
  border-radius: 36px;
  background-color: var(--brand-3);

  @media (max-width: ${breakpoints.XXL}) {
    padding: 32px 24px 20px;
  }

  @media (max-width: ${breakpoints.XL}) {
    padding: 24px 20px 16px;
    border-radius: 28px;
  }

  @media (max-width: ${breakpoints.LG}) {
    padding: 24px 13px 20px 17px;
    border-radius: 32px;
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 24px 28px 24px 17px;
  }
`;

const Wrap = styled.div<CardPetType>`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  overflow: hidden;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 20px;
  }

  @media (max-width: ${breakpoints.XL}) {
    gap: 12px;
    margin-bottom: 24px;
  }

  @media (max-width: ${breakpoints.LG}) {
    gap: 16px;
    margin-bottom: 16px;
  }
`;

const WrapAvatar = styled.div`
  width: 116px;
  height: 116px;

  @media (max-width: ${breakpoints.XL}) {
    width: 90px;
    height: 90px;
  }

  @media (max-width: ${breakpoints.LG}) {
    width: 120px;
    height: 120px;
  }
`;

const AvatarPet = styled(Avatar)`
  margin: auto;

  @media (max-width: ${breakpoints.XL}) {
    width: 78px;
    height: 78px;
  }

  @media (max-width: ${breakpoints.LG}) {
    width: 103px;
    height: 103px;
  }
`;

const Content = styled.div``;

const Date = styled.p`
  margin-bottom: 5px;
  font: var(--font-13);
  color: var(--text);
`;

const BioPet = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @media (-webkit-min-device-pixel-ratio: 2),
    (min--moz-device-pixel-ratio: 2),
    (-o-min-device-pixel-ratio: 2/1),
    (min-resolution: 2dppx),
    (min-resolution: 192dpi) {
    align-items: flex-end;
  }

  & svg {
    width: 36px;
    height: 36px;
    flex: 0 0 36px;
    color: var(--interface-dark);
  }
`;

const Name = styled.h2`
  display: flex;
  align-items: center;
  gap: 4px;
  font: var(--font-6);
  color: var(--interface-dark);

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
  word-break: break-all;
`;

const TextCss = css`
  font: var(--font-18);
  padding: 16px;
  color: var(--dark-Pink);
  background-color: var(--light-light-Pink);
  border-radius: 20px;
  text-align: center;
  letter-spacing: -0.64px;
`;

const Breed = styled.p`
  font: var(--font-14);
  color: var(--text);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: anywhere;
`;

const Text = styled.p`
  ${TextCss};
  padding: 16px;
  color: var(--dark-Pink);
  border-radius: 20px;

  @media (max-width: ${breakpoints.MD}) {
    max-width: unset;
  }
`;

const Caption = styled.p`
  ${TextCss};
`;

const Span = styled.span`
  font: var(--font-17);
  color: var(--dark-Pink);
`;

export {
  CardPet,
  Wrap,
  WrapAvatar,
  AvatarPet,
  Content,
  Date,
  BioPet,
  Name,
  Breed,
  Caption,
  Text,
  Span,
};
