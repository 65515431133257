import React, { FC, ReactNode } from "react";
import * as s from "./Button-upload-styles";
import { ButtonUploadProperty } from "../../../config/enum/button-upload-property";

export type ButtonUploadType = {
  className?: string;
  textUploadButton: string;
  typeUploadButton: "button" | "reset" | "submit";
  propertyUploadButton?:
    | ButtonUploadProperty.DEFAULT
    | ButtonUploadProperty.SECONDARY;
  disabledUploadButton?: boolean;
  iconUploadButton: ReactNode;
  onClickButtonUpload?: () => void;
};

export const ButtonUpload: FC<ButtonUploadType> = ({
  className,
  textUploadButton,
  typeUploadButton,
  propertyUploadButton = ButtonUploadProperty.DEFAULT,
  disabledUploadButton,
  iconUploadButton,
  onClickButtonUpload,
}) => {
  return (
    <s.ButtonUpload
      className={className}
      type={typeUploadButton}
      property={propertyUploadButton}
      disabled={disabledUploadButton}
      onClick={disabledUploadButton ? () => {} : onClickButtonUpload}
    >
      {iconUploadButton} {textUploadButton}
    </s.ButtonUpload>
  );
};
