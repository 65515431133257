import styled from "styled-components";
import {PopupBody} from "../../../../shared";
import {breakpoints} from "../../../../shared/config";
import {BodyAnimation} from "../../../../shared/ui/molecules/popup-body/Popup-body-styles";

const ModalAboutInvitation = styled.div``;

const Modal = styled(PopupBody)`
    ${BodyAnimation} {
        padding: 50px 90px 60px 50px;
        max-width: 740px;
    }

    @media (max-width: ${breakpoints.XXL}) {
        ${BodyAnimation} {
            max-width: 702px;
        }
    }

    @media (max-width: ${breakpoints.LG}) {
        ${BodyAnimation} {
            max-width: 100%;
        }
    }

    @media (max-width: ${breakpoints.MD}) {
        ${BodyAnimation} {
            padding: 65px 20px 30px;
        }
    }
`;

const Title = styled.h2`
    margin-bottom: 36px;
    font: var(--font-4);
    color: var(--brand-1);
    letter-spacing: -1.44px;

    @media (max-width: ${breakpoints.XL}) {
        margin-bottom: 32px;
        max-width: 65%;
        letter-spacing: -1.2px;
    }

    @media (max-width: ${breakpoints.MD}) {
        text-align: center;
    }
`;

const List = styled.ol`
    display: grid;
    grid-auto-rows: var(--minmax);
    gap: 12px;
    max-width: 800px;
`;

const Item = styled.li`
    display: flex;
    gap: 8px;
`;

const Number = styled.span`
`;


const Text = styled.p`
    display: flex;
    gap: 4px;
    font: var(--font-11);
    color: var(--text);
`;

export {
  ModalAboutInvitation,
  Modal,
  Title,
  List,
  Item,
  Number,
  Text,
};
