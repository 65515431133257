import styled, { css } from "styled-components";
import { Button, CounterRoyals, IconRub, PopupBody } from "../../../../shared";
import { breakpoints } from "../../../../shared/config";
import { BodyAnimation } from "../../../../shared/ui/molecules/popup-body/Popup-body-styles";

const ModalPromoCodeType = styled.div``;

const Modal = styled(PopupBody)`
  ${BodyAnimation} {
    max-width: 840px;
    padding: 50px 90px 60px 50px;
  }

  @media (max-width: ${breakpoints.LG}) {
    ${BodyAnimation} {
      max-width: 100%;
      padding: 60px 55px 70px;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    ${BodyAnimation} {
      max-width: 100%;
      padding: 65px 20px 30px;
    }
  }
`;

const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;

  @media (max-width: ${breakpoints.MD}) {
    flex-wrap: wrap;
    gap: 4px;
    margin-bottom: 20px;
  }
`;

const Royals = styled(CounterRoyals)`
  font: var(--font-6);
`;

const Title = styled.h2`
  font: var(--font-10);
  color: var(--text);
`;

const Text = styled.p`
  margin-bottom: 40px;
  font: var(--font-4);
  color: var(--brand-1);
`;

const RubleIcon = styled(IconRub)`
  width: 26px;
  height: auto;

  @media (max-width: ${breakpoints.XL}) {
    width: 22px;
  }

  @media (max-width: ${breakpoints.MD}) {
    width: 18px;
  }
`;

const List = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 32px;
`;

const Item = styled.li`
  &:nth-of-type(3) {
    grid-row: 2/3;
  }
`;

const SubList = styled.ul`
  display: grid;
  grid-auto-rows: var(--minmax);
  gap: 4px;
`;

type SubItemType = {
  $isArray?: boolean;
};

const SubItem = styled.li<SubItemType>`
  position: relative;
  padding-left: 34px;
  font: var(--font-11);
  color: var(--text);

  ${(props) =>
    !props.$isArray &&
    css`
        &::before {
          content: "";
          position: absolute;
          top: 12px;
          left: 14px;
          transform: translateY(-50%);
          width: 6px;
          height: 6px;
          background-color: var(--iconography);
          border-radius: 50%;
        }

        @media (max-width: ${breakpoints.LG}) {
          &::before {
            top: 10px;
            left: 16px;
          }
      `}
`;

const SubTitle = styled.h3`
  margin-bottom: 12px;
  font: var(--font-9);
  color: var(--interface-dark);
`;

const SubText = styled.p`
  font: var(--font-11);
  color: var(--text);
`;

const ButtonModal = styled(Button)`
  margin-top: 40px;
  max-width: 450px;

  @media (max-width: ${breakpoints.LG}) {
    max-width: 600px;
  }
`;

export {
  ModalPromoCodeType,
  Modal,
  Royals,
  Title,
  Wrap,
  Text,
  RubleIcon,
  List,
  Item,
  SubList,
  SubItem,
  SubTitle,
  SubText,
  ButtonModal,
};
