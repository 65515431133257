import styled from "styled-components";
import { breakpoints } from "../../../config";

const DiscountsCards = styled.section`
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (max-width: ${breakpoints.XXL}) {
    gap: 40px;
  }

  @media (max-width: ${breakpoints.LG}) {
    gap: 24px;
  }
`;

const Title = styled.h2`
  font: var(--font-3);
  letter-spacing: -0.36px;
  color: var(--brand-1);

  @media (max-width: ${breakpoints.MD}) {
    max-width: 90%;
    letter-spacing: -1.04px;
  }
`;

export { DiscountsCards, Title };
