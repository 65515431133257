import React, { FC, ReactNode } from "react";
import * as s from "./Hero-styles";
import { IconsStepsRoyals } from "../../atom";
import petStoryImage from "./../../../config/assets/images/icons/pet-story.svg";

type HeroType = {
  className?: string;
  titleBanner: string;
  extraTextBanner: string;
  imgBanner375Webp: string;
  imgBanner375_2xWebp: string;
  imgBanner375: string;
  imgBanner375_2x: string;
  imgBanner1024Webp: string;
  imgBanner1024_2xWebp: string;
  imgBanner1024: string;
  imgBanner1024_2x: string;
  imgBanner1440: string;
  imgBanner1440_2x: string;
  imgBanner1440Webp: string;
  imgBanner1440_2xWebp: string;
  altTextImgBanner: string;
  buttonText: string;
  buttonTypeBonus: "button" | "reset" | "submit";
  buttonIconUpload: ReactNode;
  onClickButtonHero: () => void;
};
export const Hero: FC<HeroType> = ({
  className,
  titleBanner,
  extraTextBanner,
  imgBanner375Webp,
  imgBanner375_2xWebp,
  imgBanner375,
  imgBanner375_2x,
  imgBanner1024Webp,
  imgBanner1024_2xWebp,
  imgBanner1024,
  imgBanner1024_2x,
  imgBanner1440,
  imgBanner1440_2x,
  imgBanner1440Webp,
  imgBanner1440_2xWebp,
  altTextImgBanner,
  buttonText,
  buttonTypeBonus,
  buttonIconUpload,
  onClickButtonHero,
}) => {
  const stepsRoyalsListMock = [
    {
      image: <IconsStepsRoyals status={1} />,
      index: 1,
      firstWord: "Покупайте рационы Royal\u00A0Canin",
      text: "для котят и щенков в Зоозавре",
    },

    {
      image: <IconsStepsRoyals status={2} />,
      index: 2,
      firstWord: "Регистрируйте чеки",
      text: "и копите роялсы, обменивайте их на\u00A0скидки\u00A0и\u00A0подарки",
    },

    {
      image: (
        <s.IconPetStory
          src={petStoryImage}
          width={90}
          height={90}
          alt={"Pet story"}
        />
      ),
      firstWord: (
        <>
          Участвуйте
          <br />в розыгрыше призов
        </>
      ),
      text: "1 из 50 страховок PetStory",
    },
  ];

  return (
    <s.Hero className={className}>
      <s.BannerStyles
        title={titleBanner}
        extraText={extraTextBanner}
        imgPath375Webp={imgBanner375Webp}
        imgPath375_2xWebp={imgBanner375_2xWebp}
        imgPath375={imgBanner375}
        imgPath375_2x={imgBanner375_2x}
        imgPath1024Webp={imgBanner1024Webp}
        imgPath1024_2xWebp={imgBanner1024_2xWebp}
        imgPath1024={imgBanner1024}
        imgPath1024_2x={imgBanner1024_2x}
        imgPath1440={imgBanner1440}
        imgPath1440_2x={imgBanner1440_2x}
        imgPath1440Webp={imgBanner1440Webp}
        imgPath1440_2xWebp={imgBanner1440_2xWebp}
        altText={altTextImgBanner}
      />

      <s.BonusMechanicsStyles
        steps={stepsRoyalsListMock}
        buttonText={buttonText}
        buttonType={buttonTypeBonus}
        buttonIcon={buttonIconUpload}
        onClickButton={onClickButtonHero}
      />
    </s.Hero>
  );
};
