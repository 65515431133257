import React, {FC, useEffect} from "react";
import * as s from "./Main-styles";
import {Animation, IconDocSquare, IconExit, IconUpload,} from "../../../shared";
import {useLocation, useNavigate} from "react-router-dom";
import heroPets1440 from "../../../shared/config/assets/images/hero-pets-1440.png";
import heroPets1440_2x from "../../../shared/config/assets/images/hero-pets-1440@2x.png";
import heroPets1440Webp from "../../../shared/config/assets/images/hero-pets-1440.webp";
import heroPets1440_2xWebp from "../../../shared/config/assets/images/hero-pets-1440@2x.webp";
import heroPets1024 from "../../../shared/config/assets/images/hero-pets-1024.png";
import heroPets1024_2x from "../../../shared/config/assets/images/hero-pets-1024@2x.png";
import heroPets1024Webp from "../../../shared/config/assets/images/hero-pets-1024.webp";
import heroPets1024_2xWebp from "../../../shared/config/assets/images/hero-pets-1024@2x.webp";
import heroPets375 from "../../../shared/config/assets/images/hero-pets-375.png";
import heroPets375_2x from "../../../shared/config/assets/images/hero-pets-375@2x.png";
import heroPets375Webp from "../../../shared/config/assets/images/hero-pets-375.webp";
import heroPets375_2xWebp from "../../../shared/config/assets/images/hero-pets-375@2x.webp";
import {esAuth, esDiscount} from "../../../entities";
import {useAppDispatch, useAppSelector, useIsMobile, useScrollToHash, useScrollToTop,} from "../../../shared/lib";
import {setModals} from "../../../widgets/modals/model";
import {PromoCodeDescriptionType} from "../../../entities/discount/model";
import {breakpointsTs, Container, ModalsEnumType, ModalsProperty, pages,} from "../../../shared/config";
import {ButtonUploadProperty} from "../../../shared/config/enum/button-upload-property";
import {DiscountsCards} from "../../../shared/ui/organisms/discounts-cards/Discounts-cards";
import {cardsDiscountsMock} from "../config/mock/main-mock";

type MainType = {};

export const Main: FC<MainType> = ({}) => {
  useScrollToTop();
  const {hash} = useLocation();
  useScrollToHash(hash);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isTablet] = useIsMobile(breakpointsTs.LG);
  const {isAuthorization} = useAppSelector(esAuth.model.selectAuth);
  const {promoCodesTypes} = useAppSelector(esDiscount.model.selectDiscount);
  const {user} = useAppSelector(esAuth.model.selectAuth);

  const onRedirect = () => {
    if (isAuthorization) {
      dispatch(setModals({action: ModalsProperty.MODAL_EXIT}));
    } else {
      navigate(pages.signIn.link);
    }
  };

  const onRedirectButtonHero = () => {
    if (isAuthorization) {
      navigate(pages.personalAreaProfile.link);
    } else {
      const element = document.getElementById("form-registration-section");
      element?.scrollIntoView({behavior: "smooth"});
    }
  };

  const onRedirectButtonBonusesSection = () => {
    if (isAuthorization) {
      navigate(pages.personalAreaChecks.link + "/" + "Modal");
    } else {
      navigate(pages.signIn.link);
    }
  };

  const onOpenModalEnum = (actionModal: ModalsEnumType) => () => {
    dispatch(
      setModals({
        action: actionModal,
      })
    );
  };

  const onOpenModalEnumContent =
    (actionModal: ModalsEnumType, content: PromoCodeDescriptionType) => () => {
      dispatch(
        setModals({
          action: actionModal,
          content,
        })
      );
    };

  const onOpenModalQuestion = (title: string) => () => {
    dispatch(
      setModals({
        action: ModalsProperty.MODAL_FREQUENTLY_ASKED_QUESTION_QUESTION,
        content: title,
      })
    );
  };

  const onRedirectProfile = () => {
    navigate(pages.personalAreaProfile.link);
  };

  useEffect(() => {
    if (promoCodesTypes?.length === 0) {
      dispatch(esDiscount.model.getPromoCodesTypesNotAuthorizedThunk());
    }
  }, []);

  const animation = {
    initial: {opacity: 0, y: -50},
    animate: {opacity: 1, y: 0},
    exit: {opacity: 0},
  };

  return (
    <s.Main>
      <s.HeaderMain
        isMobile={!isTablet}
        isScroll={true}
        listLeft={[
          <s.LinkDefault href="#additional-royals-section">
            Дополнительные роялсы
          </s.LinkDefault>,

          <s.LinkDefault href="#discounts-section">
            Скидки и подарки
          </s.LinkDefault>,

          <s.LinkDefault href="#duo-feeding-section">
            Смешанное кормление
          </s.LinkDefault>,

          <s.LinkDefault href="#faq-section">FAQ</s.LinkDefault>,
        ]}
        listRight={[
          <s.LinkDefault
            href={
              "https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/rules/Правила_акции__Здоровый_питомец.pdf"
            }
            target={"_blank"}
          >
            <IconDocSquare width={24} height={24}/>
            {isTablet && "Полные правила акции"}
          </s.LinkDefault>,

          <s.Button type={"button"} onClick={onRedirect}>
            <IconExit width={24} height={24}/>{" "}
            {isTablet && (isAuthorization ? "Выйти" : "Войти")}
          </s.Button>,

          isAuthorization && (
            <s.ButtonAvatar
              type={"button"}
              $isImage={user?.image}
              onClick={onRedirectProfile}
            >
              {user?.image ? (
                <s.ImageAvatar src={user?.image} alt={"Аватар"}></s.ImageAvatar>
              ) : (
                user?.first_name.slice(0, 1) || <s.IconAvatar/>
              )}
            </s.ButtonAvatar>
          ),
        ]}
      />

      <s.Content>
        <Container>
          <Animation isShow={true} delay={0.3} variants={animation}>
            <s.HeroSection
              titleBanner={"Здоровье с\u00A0первых дней"}
              extraTextBanner={
                "Подберите рацион питомцу с\u00A0учетом его индивидуальных потребностей"
              }
              imgBanner375Webp={heroPets375Webp}
              imgBanner375_2xWebp={heroPets375_2xWebp}
              imgBanner375={heroPets375}
              imgBanner375_2x={heroPets375_2x}
              imgBanner1024Webp={heroPets1024Webp}
              imgBanner1024_2xWebp={heroPets1024_2xWebp}
              imgBanner1024={heroPets1024}
              imgBanner1024_2x={heroPets1024_2x}
              imgBanner1440={heroPets1440}
              imgBanner1440_2x={heroPets1440_2x}
              imgBanner1440Webp={heroPets1440Webp}
              imgBanner1440_2xWebp={heroPets1440_2xWebp}
              altTextImgBanner={"\u00A0"}
              buttonText={"Получить роялсы"}
              buttonTypeBonus={"button"}
              buttonIconUpload={<IconUpload/>}
              onClickButtonHero={onRedirectButtonHero}
            />
          </Animation>
        </Container>

        <Container>
          <s.CardBonusRoyals
            title={"За каждые 50 рублей в\u00A0чеке начисляем 1 роялс*"}
            footnote={"*Роялс – наш внутренний бонус"}
            text={
              "Дополнительные роялсы за покупки и загрузку чеков каждый месяц"
            }
            textUploadButton={"Загрузить чек"}
            typeUploadButton={"button"}
            propertyUploadButton={ButtonUploadProperty.SECONDARY}
            iconUploadButton={<IconUpload/>}
            onClickButtonUpload={onRedirectButtonBonusesSection}
          />
        </Container>

        <Container>
          <s.AdditionalRoyalsSection
            id="additional-royals-section"
            titleAdditionalRoyals={"Как получить дополнительные роялсы?"}
            onClickCardRoyals={onOpenModalEnum}
          />
        </Container>

        <Container>
          <s.DiscountsSection id="discounts-section">
            <DiscountsCards
              titleAdditionalRoyals={"Скидки и промокоды"}
              discountsCards={cardsDiscountsMock}
              onClickCardDiscounts={onOpenModalEnumContent}
            />
          </s.DiscountsSection>
        </Container>

        <s.ContainerFormRegistration id={"form-registration-section"}>
          {!isAuthorization && <s.FormRegistration/>}

          <s.PartnersCards/>
        </s.ContainerFormRegistration>

        <Container>
          <s.DuoFeedingSection
            id="duo-feeding-section"
            titleDuoFeeding={"Важность кормления сухим и\u00A0влажным рационом"}
            textDuoFeeding={
              "Сочетание в рационе сухих и влажных рационов может помочь улучшить здоровье питомца"
            }
            onClickCardFeedings={onOpenModalEnum}
          />
        </Container>

        <Container>
          <s.FaqSection id="faq-section" onClickButton={onOpenModalQuestion}/>
        </Container>
      </s.Content>
    </s.Main>
  );
};
