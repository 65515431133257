import styled from "styled-components";
import { breakpoints } from "../../../config";
import { Text } from "../../atom/card-feeding/Сard-feeding-styles";

const CardsFeedings = styled.div``;

const ListCards = styled.ul`
  display: flex;
  gap: 24px;

  @media (max-width: ${breakpoints.XXL}) {
    gap: 12px;
  }

  @media (max-width: ${breakpoints.XL}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: ${breakpoints.LG}) {
    gap: 8px;
  }

  @media (max-width: ${breakpoints.SM}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, 200px) 136px;
  }
`;

const ItemCards = styled.li`
  flex: 1;

  @media (max-width: ${breakpoints.MD}) {
    &:last-child {
      ${Text} {
        max-width: 80%;
      }
    }
  }

  @media (max-width: ${breakpoints.SM}) {
    &:last-child {
      grid-column: span 2;
    }
  }
`;

export { CardsFeedings, ListCards, ItemCards };
