import React, {FC} from "react";
import * as s from "./Personal-area-styles";
import {Outlet, useNavigate} from "react-router-dom";
import {Header} from "../../../widgets";
import {Footer, IconDocSquare, IconExit} from "../../../shared";
import {useAppDispatch, useAppSelector, useIsMobile, useScrollToTop,} from "../../../shared/lib";
import {esAuth} from "../../../entities";
import {setModals} from "../../../widgets/modals/model";
import {breakpointsTs, ModalsProperty, pages} from "../../../shared/config";

type PersonalAreaType = {};

export const PersonalArea: FC<PersonalAreaType> = ({}) => {
  useScrollToTop();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {isAuthorization} = useAppSelector(esAuth.model.selectAuth);
  const [isMobile] = useIsMobile(breakpointsTs.LG);

  const onRedirect = () => {
    if (isAuthorization) {
      dispatch(setModals({action: ModalsProperty.MODAL_EXIT}));
    } else {
      navigate(pages.signIn.link);
    }
  };

  const onOpenModalQuestion = () => {
    dispatch(setModals({action: ModalsProperty.MODAL_QUESTION}));
  };

  return (
    <s.PersonalArea>
      <Header
        isMobile={false}
        listLeft={[<s.LinkRouter to={pages.main.link}>Главная</s.LinkRouter>]}
        listRight={[
          <s.LinkDefault
            href={
              "https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/rules/Правила_акции__Здоровый_питомец.pdf"
            }
            target={"_blank"}
          >
            <IconDocSquare width={24} height={24}/>
            {isMobile && "Полные правила акции"}
          </s.LinkDefault>,

          <s.Button type={"button"} onClick={onRedirect}>
            <IconExit width={24} height={24}/>{" "}
            {isMobile && (isAuthorization ? "Выйти" : "Войти")}
          </s.Button>,
        ]}
      />

      <s.Content>
        <Outlet/>
      </s.Content>

      {isMobile && (
        <s.PersonalAreaContainer>
          <Footer
            links={[
              {
                title: "Корпоративная политика",
                items: [
                  {
                    text: "Правила использования сайта",
                    href: "https://www.mars.com/global/policies/legal/ld-russian",
                  },

                  {
                    text: "Конфиденциальность",
                    href: "https://www.mars.com/global/policies/privacy/pp-russian",
                  },

                  {
                    text: "Доступность",
                    href: "https://www.mars.com/accessibility-russian",
                  },

                  {
                    text: "Cookies",
                    href: "https://www.mars.com/cookies-russia",
                  },
                ],
              },

              {
                title: "Об акции",
                items: [
                  {
                    text: "Пользовательское соглашение",
                    href: "https://www.royalcanin.com/ru/about-us/terms-and-conditions",
                  },

                  {
                    text: "Правила акции",
                    href: "https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/rules/Правила_акции__Здоровый_питомец.pdf",
                  },
                ],
              },
            ]}
            onClickButton={onOpenModalQuestion}
          />
        </s.PersonalAreaContainer>
      )}
    </s.PersonalArea>
  );
};
