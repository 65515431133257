import styled from "styled-components";
import { breakpoints } from "../../../config";
import bannerPromoCodeAnimalImage from "./../../../config/assets/images/icons/banner-promo-code-animal.svg";
import bannerPromoCodeAnimalImageXl from "./../../../config/assets/images/icons/banner-promo-code-animal-xl.svg";
import bannerPromoCodeAnimalImageLg from "./../../../config/assets/images/icons/banner-promo-code-animal-lg.svg";
import bannerPromoCodeAnimalImageMd from "./../../../config/assets/images/icons/banner-promo-code-animal-md.svg";

const BannerPromoCodeAnimal = styled.section`
  position: relative;
  height: 215px;
  padding: 64px 40px;
  border-radius: 36px;
  background: linear-gradient(97deg, #ffe8eb 1.9%, #ffd5d9 56.58%);
  overflow: hidden;
  margin-bottom: 5px;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 175px;
    width: 342px;
    height: 220px;
    background: url(${bannerPromoCodeAnimalImage}) no-repeat center center/100%;
  }

  @media (max-width: ${breakpoints.XXL}) {
    &::before {
      bottom: 0;
      right: 81px;
    }
  }

  @media (max-width: ${breakpoints.XL}) {
    height: 210px;
    padding: 66px 32px;

    &::before {
      right: 81px;
      width: 306px;
      height: 210px;
      background: url(${bannerPromoCodeAnimalImageXl}) no-repeat center
        center/100%;
    }
  }

  @media (max-width: ${breakpoints.LG}) {
    padding: 66px 32px;

    &::before {
      right: 3px;
      width: 334px;
      background: url(${bannerPromoCodeAnimalImageLg}) no-repeat center
        center/100%;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    height: 180px;
    padding: 16px 34px 126px;
    border-radius: 28px;

    &::before {
      right: 50%;
      width: 211px;
      height: 117px;
      transform: translateX(50%);
      background: url(${bannerPromoCodeAnimalImageMd}) no-repeat center
        center/100%;
    }
  }
`;

const Title = styled.div`
  position: relative;
  z-index: 1;
  max-width: 352px;
  font: var(--font-27);
  color: var(--interface-dark);

  @media (max-width: ${breakpoints.XL}) {
    max-width: 239px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: 283px;
    margin: 0 auto;
    text-align: center;
  }
`;

export { BannerPromoCodeAnimal, Title };
