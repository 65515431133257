import styled, { css } from "styled-components";
import { breakpoints, Container } from "../../../shared/config";
import {
  CardTargetAction,
  Faq,
  Hero,
  IconProfile,
  PartnersSection,
} from "../../../shared";
import { Image } from "../../../shared/ui/atom/banner/Banner-styles";
import { BannerStyles } from "../../../shared/ui/templates/hero/Hero-styles";
import { AdditionalRoyals } from "../../../shared/ui/organisms/additional-royals/Additional-royals";
import { DuoFeeding } from "../../../shared/ui/organisms/duo-feeding/Duo-feeding";
import { FormRegistrationMini, Header } from "../../../widgets";

type ButtonAvatarType = {
  $isImage: boolean;
};

const Main = styled.section``;

const HeaderMain = styled(Header)`
  padding: 24px 0;

  @media (max-width: ${breakpoints.XXL}) {
    padding: 32px 0;
  }

  @media (max-width: ${breakpoints.LG}) {
    padding: 24px 0;
  }

  @media (max-width: ${breakpoints.MD}) {
    padding: 12px 0;
  }
`;

const LinkCss = css`
  font: var(--font-16);
  color: var(--text);
  will-change: transform;
  transition: color 300ms ease, transform 300ms ease;

  &:hover {
    color: var(--hyperlink);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const LinkDefault = styled.a`
  display: flex;
  align-items: center;
  gap: 4px;
  ${LinkCss};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  ${LinkCss};
`;

const ButtonAvatar = styled.button<ButtonAvatarType>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--brand-1);
  border-radius: 50%;
  font: var(--font-28);
  color: var(--brand-3);
  will-change: transform;
  text-transform: uppercase;
  transition: color 300ms ease, transform 300ms ease;
  overflow: hidden;

  @media (max-width: ${breakpoints.XL}) {
    width: 32px;
    height: 32px;
  }

  @media (max-width: ${breakpoints.MD}) {
    width: 40px;
    height: 40px;
  }

  &:active {
    transform: scale(0.98);
  }

  ${(props) =>
    props.$isImage &&
    css`
      background-color: transparent;
    `};

  @media (-webkit-min-device-pixel-ratio: 2),
    (min--moz-device-pixel-ratio: 2),
    (-o-min-device-pixel-ratio: 2/1),
    (min-resolution: 2dppx),
    (min-resolution: 192dpi) {
    align-items: flex-end;
  }
`;

const IconAvatar = styled(IconProfile)`
  width: 100%;
  height: 100%;
`;

const ImageAvatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const Content = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

const HeroSection = styled(Hero)`
  margin-bottom: 90px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 110px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 70px;
  }

  ${BannerStyles} {
    ${Image} {
      width: 516px;

      @media (max-width: ${breakpoints.XXL}) {
        width: 500px;
      }

      @media (max-width: ${breakpoints.XL}) {
        width: 375px;
      }

      @media (max-width: ${breakpoints.LG}) {
        width: 345px;
      }

      @media (max-width: ${breakpoints.MD}) {
        width: 52vw;
      }

      @media (max-width: ${breakpoints.SM}) {
        width: 315px;
      }
    }
  }
`;

const CardBonusRoyals = styled(CardTargetAction)`
  margin-bottom: 130px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 110px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 80px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 70px;
  }
`;

const DiscountsSection = styled.section`
  margin-bottom: 120px;
  scroll-margin-top: 120px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 110px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 80px;
  }
`;

const AdditionalRoyalsSection = styled(AdditionalRoyals)`
  margin-bottom: 120px;
  scroll-margin-top: 120px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 112px;
  }

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 102px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 80px;
  }
`;

const DuoFeedingSection = styled(DuoFeeding)`
  margin-bottom: 130px;
  scroll-margin-top: 130px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 150px;
  }

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 110px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 80px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 70px;
  }
`;

const ContainerFormRegistration = styled(Container)`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 130px;
  scroll-margin-top: 130px;

  @media (max-width: ${breakpoints.XXL}) {
    gap: 16px;
    margin-bottom: 120px;
  }

  @media (max-width: ${breakpoints.XL}) {
    flex-direction: column;
    gap: 110px;
    margin-bottom: 110px;
  }

  @media (max-width: ${breakpoints.LG}) {
    gap: 80px;
    margin-bottom: 100px;
  }

  @media (max-width: ${breakpoints.MD}) {
    gap: 70px;
    margin-bottom: 70px;
  }
`;

const FormRegistration = styled(FormRegistrationMini)`
  flex: 68%;

  @media (max-width: ${breakpoints.XXL}) {
    flex: 61%;
  }
`;

const PartnersCards = styled(PartnersSection)`
  flex: 33%;
`;

const FaqSection = styled(Faq)`
  margin-bottom: 120px;
  scroll-margin-top: 120px;

  @media (max-width: ${breakpoints.XXL}) {
    margin-bottom: 110px;
  }

  @media (max-width: ${breakpoints.XL}) {
    margin-bottom: 100px;
  }

  @media (max-width: ${breakpoints.LG}) {
    margin-bottom: 104px;
  }

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 96px;
  }
`;

export {
  Main,
  HeaderMain,
  LinkDefault,
  Button,
  ButtonAvatar,
  IconAvatar,
  ImageAvatar,
  Content,
  HeroSection,
  CardBonusRoyals,
  BannerStyles,
  DiscountsSection,
  AdditionalRoyalsSection,
  DuoFeedingSection,
  ContainerFormRegistration,
  FormRegistration,
  PartnersCards,
  FaqSection,
};
