import React, {ChangeEvent, FC, MouseEvent} from "react";
import * as s from "./Modal-question-styles";
import {ButtonProperty} from "../../../../shared/config";
import {useFormik} from "formik";
import {ValidationFormModalQuestion} from "../config";
import {Animation} from "../../../../shared";
import {QuestionType, sendQuestionThunk} from "../../../../shared/model";
import {deleteMaskPhone, useAppDispatch, useAppSelector,} from "../../../../shared/lib";
import {esAuth} from "../../../../entities";

type ModalQuestionType = {
  className?: string;
  onShowModal: () => void;
  onSubmit: () => void;
};

export const ModalQuestion: FC<ModalQuestionType> = ({
                                                       className,
                                                       onShowModal,
                                                       onSubmit,
                                                     }) => {
  const user = useAppSelector(esAuth.model.selectAuthUser);
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      name: user?.first_name || "",
      phone: user?.phone
        ? `+${user.phone.slice(0, 1)} (${user.phone.slice(
          1,
          4
        )}) ${user.phone.slice(4, 7)}-${user.phone.slice(
          7,
          9
        )}-${user.phone.slice(9, 11)}`
        : "",
      email: user?.email || "",
      category: "",
      text: "",
      personal_data_handling: false,
    },

    validationSchema: ValidationFormModalQuestion,

    onSubmit: async (body: QuestionType) => {
      await dispatch(
        sendQuestionThunk({
          ...body,
          phone: deleteMaskPhone(body.phone),
        })
      );
      onSubmit();
    },
  });

  const onChangeValueTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    formik.setFieldValue("text", e.currentTarget.value);
  };

  const onBlurValueTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    formik.setFieldTouched("text");
  };

  const onChangeValueInput =
    (formikValueName: string, formikValue: boolean) =>
      (e: MouseEvent<HTMLButtonElement>) => {
        const element = e.target as HTMLElement;
        if (element.nodeName !== "A") {
          formik.setFieldValue(formikValueName, !formikValue);
        }
      };

  return (
    <s.ModalQuestion className={className}>
      <s.Modal
        onClickButtonBg={onShowModal}
        onClickButtonIcon={onShowModal}
        isStatus={true}
      >
        <s.Title>Задайте ваш вопрос</s.Title>

        <s.Form onSubmit={formik.handleSubmit}>
          <s.List>
            <s.Item>
              <s.InputText
                type={"text"}
                label={"Имя*"}
                $isError={
                  !!formik.getFieldMeta("name").error &&
                  !!formik.getFieldMeta("name").touched
                }
                {...formik.getFieldProps("name")}
                {...formik.getFieldMeta("name")}
                {...formik.getFieldHelpers("name")}
              />
            </s.Item>

            <s.Item>
              <s.InputPhone
                label={"Телефон*"}
                $isError={
                  !!formik.getFieldMeta("phone").error &&
                  !!formik.getFieldMeta("phone").touched
                }
                {...formik.getFieldProps("phone")}
                {...formik.getFieldMeta("phone")}
                {...formik.getFieldHelpers("phone")}
              />
            </s.Item>

            <s.Item>
              <s.InputText
                type={"text"}
                label={"E-mail*"}
                $isError={
                  !!formik.getFieldMeta("email").error &&
                  !!formik.getFieldMeta("email").touched
                }
                {...formik.getFieldProps("email")}
                {...formik.getFieldMeta("email")}
                {...formik.getFieldHelpers("email")}
              />
            </s.Item>
            <s.Item>
              <s.SelectQuestions
                disabled={false}
                list={
                  [
                    {id: "1123", value: "Изменение данных"},
                    {id: "2sdfasdf", value: "Удаление аккаунта"},
                    {
                      id: "3arsg23",
                      value: "Начисление роялсов",
                    },
                    {
                      id: "4asdfasdf123",
                      value: "Статус чека",
                    },
                    {
                      id: "5afasdfasd",
                      value: "Другой вопрос",
                    },
                  ] || []
                }
                placeholderInput={"Категория вопроса*"}
                $isError={
                  !!formik.getFieldMeta("category").error &&
                  !!formik.getFieldMeta("category").touched
                }
                {...formik.getFieldProps("category")}
                {...formik.getFieldMeta("category")}
                {...formik.getFieldHelpers("category")}
              />
            </s.Item>
            <s.Item>
              <s.TextareaQuestion
                isError={!!formik.errors.text && formik.touched.text}
                placeholder={"Опишите детали вашего вопроса"}
                value={formik.values.text}
                onChangeTextarea={onChangeValueTextarea}
                onBlur={onBlurValueTextarea}
              />

              <Animation isShow={!!formik.errors.text && !!formik.touched.text}>
                <s.TextareaError>{formik.errors.text}</s.TextareaError>
              </Animation>

              <s.Counter>{formik.values.text.length}/300</s.Counter>
            </s.Item>

            <s.Item>
              <s.Checkbox
                checked={formik.values.personal_data_handling}
                error={
                  formik.touched["personal_data_handling"]
                    ? formik.errors.personal_data_handling
                    : ""
                }
                label={
                  <s.Label>
                    Я ознакомился с{" "}
                    <s.LabelLink
                      href={
                        "https://www.mars.com/global/policies/privacy/pp-russian"
                      }
                      target={"_blank"}
                    >
                      Положением о конфиденциальности,{" "}
                    </s.LabelLink>
                    <s.LabelLink
                      href={
                        "https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/rules/Правила_акции__Здоровый_питомец.pdf"
                      }
                      target={"_blank"}
                    >
                      Правилами Акции{" "}
                    </s.LabelLink>
                    и даю свое согласие на{" "}
                    <s.LabelLink
                      href={"https://www.mars.com/privacy-policy-russia"}
                      target={"_blank"}
                    >
                      обработку персональных данных
                    </s.LabelLink>
                    .*{" "}
                  </s.Label>
                }
                onClickInputChecked={onChangeValueInput(
                  "personal_data_handling",
                  formik.values.personal_data_handling
                )}
              />
            </s.Item>
          </s.List>

          <s.ButtonSubmit
            property={ButtonProperty.DEFAULT}
            text={"Отправить"}
            type={"submit"}
            onClickButton={() => {
            }}
          />
        </s.Form>
      </s.Modal>
    </s.ModalQuestion>
  );
};
