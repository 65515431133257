import React, {ChangeEvent, FC} from "react";
import * as s from "./Sidebar-personal-area-styles";
import image from "./../../../shared/config/assets/images/profile.svg";
import {Animation, IconPromoSmall, IconReceipt, IconUser, MenuPersonalAreaMobile,} from "../../../shared";
import {changeFormatPhone, useAppDispatch, useAppSelector,} from "../../../shared/lib";
import {setModals} from "../../modals/model";
import {ModalsProperty, pages} from "../../../shared/config";
import {esAuth} from "../../../entities";

type SidebarPersonalAreaType = {
  className?: string;
  isMobile: boolean;
  isAvatar?: boolean;
  onChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  onClickMenuUpload?: () => void;
};

export const SidebarPersonalArea: FC<SidebarPersonalAreaType> = ({
                                                                   className,
                                                                   isMobile,
                                                                   isAvatar = true,
                                                                   onChangeInput,
                                                                   onClickMenuUpload,
                                                                 }) => {
  const user = useAppSelector(esAuth.model.selectAuthUser);
  const dispatch = useAppDispatch();
  const onShowPopupSendPhone = (isValidationPhone: boolean) => () => {
    dispatch(
      setModals({
        action: ModalsProperty.MODAL_SEND_PHONE,
        content: {phone: user?.phone, isValidationPhone},
      })
    );
  };

  const animation = {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0},
  };

  return (
    <s.SidebarPersonalArea className={className}>
      <Animation isShow={isAvatar} delay={0.2} variants={animation}>
        <s.Wrap>
          <s.AvatarPersonal
            src={user?.image || image}
            alt={"Пользователь"}
            onChangeInput={onChangeInput}
          />

          <s.Name title={user?.first_name + " " + user?.last_name}>
            {`${
              user?.first_name.charAt(0).toUpperCase() +
              user?.first_name.slice(1)
            } ${
              user?.last_name.charAt(0).toUpperCase() + user?.last_name.slice(1)
            }` || "Имя Фамилия"}
          </s.Name>

          <s.SubWrap>
            <s.WrapPhone>
              {!user?.phone ? (
                <s.ButtonTextRoyals
                  type={"button"}
                  onClick={onShowPopupSendPhone(false)}
                >
                  Добавить номер телефона
                  <s.WrapIcon>
                    +5
                    <s.IconRoyal/>
                  </s.WrapIcon>
                </s.ButtonTextRoyals>
              ) : (
                !user.phone_approved ? (
                  <s.ButtonTextRoyals
                    type={"button"}
                    onClick={onShowPopupSendPhone(true)}
                  >
                    Подтвердить
                    <s.WrapIcon>
                      +5
                      <s.IconRoyal/>
                    </s.WrapIcon>
                  </s.ButtonTextRoyals>
                ) : (
                  <s.Text>{changeFormatPhone(user?.phone)}</s.Text>
                )
              )}
            </s.WrapPhone>

            <s.Text>{user?.email || "почта"}</s.Text>
          </s.SubWrap>
        </s.Wrap>
      </Animation>

      <s.Header>
        <Animation isShow={isMobile} delay={0.2} variants={animation}>
          <s.MenuDesktop
            links={[
              {
                text: "Профиль",
                to: pages.personalAreaProfile.link,
                icon: <IconUser width={24} height={24}/>,
              },

              {
                text: "Чеки",
                to: pages.personalAreaChecks.link,
                icon: <IconReceipt width={24} height={24}/>,
              },

              {
                text: "Скидки и промокоды",
                to: pages.personalAreaPromoCode.link,
                icon: <IconPromoSmall width={24} height={24}/>,
              },
            ]}
          />
        </Animation>

        <Animation isShow={!isMobile} delay={0.2} variants={animation}>
          <MenuPersonalAreaMobile onClickMenuUpload={onClickMenuUpload}/>
        </Animation>
      </s.Header>
    </s.SidebarPersonalArea>
  );
};
