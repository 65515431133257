import styled from "styled-components";
import { breakpoints } from "../../../config";
import bannerBill1440 from "../../../config/assets/images/banner-bill-1440.png";
import bannerBill1024 from "../../../config/assets/images/banner-bill-1024.png";
import bannerBill375 from "../../../config/assets/images/banner-bill-375.png";

const BannerPersonalAreaPromoCode = styled.section`
  position: relative;
  height: 220px;
  padding: 50px 40px;
  border-radius: 36px;
  background: linear-gradient(97deg, #ffe8eb 1.9%, #ffd5d9 56.58%);
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 140px;
    width: 355px;
    height: 220px;
    background: url(${bannerBill1440}) no-repeat right bottom/cover;
  }

  @media (max-width: ${breakpoints.XXL}) {
    &::before {
      bottom: 0;
      right: 81px;
    }
  }

  @media (max-width: ${breakpoints.XL}) {
    height: 265px;
    padding: 78px 32px;

    &::before {
      right: 20px;
      width: 306px;
      height: 265px;
      background: url(${bannerBill1024}) no-repeat right bottom/cover;
    }
  }

  @media (max-width: ${breakpoints.LG}) {
    padding: 66px 32px;

    &::before {
      right: 50px;
      width: 334px;
    }
  }

  @media (max-width: ${breakpoints.MD}) {
    height: 235px;
    padding: 16px 34px 148px;
    border-radius: 28px;

    &::before {
      right: 48%;
      width: 252px;
      height: 136px;
      transform: translateX(50%);
      background: url(${bannerBill375}) no-repeat right bottom/cover;
    }
  }
`;

const Wrap = styled.div`
  position: relative;
  z-index: 1;
  max-width: 300px;

  @media (max-width: ${breakpoints.MD}) {
    margin: 0 auto;
  }
`;

const Title = styled.h2`
  font: var(--font-27);
  color: var(--interface-dark);
  margin-bottom: 16px;

  @media (max-width: ${breakpoints.MD}) {
    margin-bottom: 12px;
    text-align: center;
  }
`;

const Text = styled.p`
  font: var(--font-24);
  color: var(--text);

  @media (max-width: ${breakpoints.XL}) {
    max-width: 85%;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
    text-align: center;
  }
`;

export { BannerPersonalAreaPromoCode, Wrap, Title, Text };
