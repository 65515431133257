import styled from "styled-components";
import {BannerPromoCodeAnimal, CardPromo, CardPromoCode, IconRub,} from "../../../shared";
import {breakpoints, Container} from "../../../shared/config";
import {Text} from "../../../shared/ui/molecules/card-promo-code/Card-promo-code-styles";

const PersonalAreaPromoCode = styled.section`
    display: grid;
    grid-template-columns: 390px 1fr;
    gap: 64px;

    @media (max-width: ${breakpoints.XXL}) {
        grid-template-columns: 340px 1fr;
        gap: 78px;
    }

    @media (max-width: ${breakpoints.XL}) {
        grid-template-columns: 300px 1fr;
        gap: 48px;
    }

    @media (max-width: ${breakpoints.LG}) {
        grid-template-columns: 1fr;
        grid-auto-rows: var(--minmax);
        gap: 4px;
    }

    @media (max-width: ${breakpoints.MD}) {
        grid-template-columns: 1fr;
        gap: 14px;
    }
`;

const PersonalAreaContainer = styled(Container)`
    @media (max-width: ${breakpoints.LG}) {
        padding-right: unset;
        padding-left: 40px;
    }

    @media (max-width: ${breakpoints.MD}) {
        padding-left: 12px;
    }
`;

const WrapContent = styled.div`
    max-width: 100%;
    width: 100%;
    min-width: 100%;

    &.swiper-button-next {
        display: none !important;
    }
`;

const PromoCodes = styled.section`
    margin-bottom: 100px;

    @media (max-width: ${breakpoints.XL}) {
        margin-bottom: 80px;
    }

    @media (max-width: ${breakpoints.MD}) {
        margin-bottom: 60px;
    }
`;

const PromoCodeTitle = styled.h2`
    margin-bottom: 40px;
    font: var(--font-9);
    color: var(--hyperlink);

    @media (max-width: ${breakpoints.XL}) {
        margin-bottom: 32px;
    }

    @media (max-width: ${breakpoints.LG}) {
        margin-bottom: 24px;
    }

    @media (max-width: ${breakpoints.MD}) {
        margin-bottom: 16px;
    }
`;

const CardPromoSlide = styled(CardPromo)`
    max-width: 100%;
`;

const DiscountsCards = styled.section``;

const DiscountsTitle = styled.h2`
    margin-bottom: 32px;
    font: var(--font-3);
    color: var(--brand-1);

    @media (max-width: ${breakpoints.MD}) {
        margin-bottom: 32px;
    }
`;

const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: ${breakpoints.MD}) {
        gap: 32px;
    }
`;

const Item = styled.li``;

const BannerPromoAnimal = styled(BannerPromoCodeAnimal)`
    @media (max-width: ${breakpoints.LG}) {
        margin-right: 40px;
    }

    @media (max-width: ${breakpoints.MD}) {
        margin-right: 12px;
    }
`;

const DiscountsSubTitle = styled.h3`
    margin-bottom: 24px;
    font: var(--font-8);
    color: var(--text);

    @media (max-width: ${breakpoints.MD}) {
        padding-right: 110px;
        margin-bottom: 16px;
    }
`;

const CardPromoCodeSlide = styled(CardPromoCode)`
    max-width: 100%;
    min-height: 220px;

    ${Text} {
        @media (max-width: ${breakpoints.LG}) {
            top: 140px;
        }
    }
`;

const RubleIcon = styled(IconRub)`
    width: 16px;
    height: 16px;
    color: currentColor;

    @media (max-width: ${breakpoints.XL}) {
        width: 14px;
        height: 14px;
    }

    @media (max-width: ${breakpoints.MD}) {
        width: 11px;
        height: 11px;
    }
`;

export {
  PersonalAreaPromoCode,
  PersonalAreaContainer,
  WrapContent,
  PromoCodes,
  PromoCodeTitle,
  CardPromoSlide,
  DiscountsCards,
  DiscountsTitle,
  List,
  Item,
  BannerPromoAnimal,
  DiscountsSubTitle,
  CardPromoCodeSlide,
  RubleIcon,
};
