import styled from "styled-components";
import { breakpoints } from "../../../config";
import {
  Body,
  CardDiscount,
  Title,
} from "../../atom/card-discount/Card-discount-styles";
import discountTicketCard1440 from "../../../config/assets/images/discounts-ticket-royals-1440.svg";
import discountTicketCard1024 from "../../../config/assets/images/discounts-ticket-royals-1024.svg";
import discountTicketCard768 from "../../../config/assets/images/discounts-ticket-royals-768.svg";
import discountTicketCard375 from "../../../config/assets/images/discounts-ticket-royals-375.svg";
import discountTicketDarkCard1440 from "../../../config/assets/images/discounts-ticket-dark-royals-1440.svg";
import discountTicketDarkCard1024 from "../../../config/assets/images/discounts-ticket-dark-royals-1024.svg";
import discountTicketDarkCard768 from "../../../config/assets/images/discounts-ticket-dark-royals-768.svg";
import discountTicketDarkCard375 from "../../../config/assets/images/discounts-ticket-dark-royals-375.svg";
import discount99percent1440 from "../../../config/assets/images/discounts-99-1440.svg";
import discount99percent768 from "../../../config/assets/images/discounts-99-768.svg";
import discountToys1440 from "../../../config/assets/images/discounts-toys-1440.svg";
import discountToys768 from "../../../config/assets/images/discounts-toys-768.svg";
import discountToys375 from "../../../config/assets/images/discounts-toys-375.svg";

const CardsDiscounts = styled.div``;

const ListCards = styled.ul`
  display: flex;
  gap: 24px;

  @media (max-width: ${breakpoints.XXL}) {
    gap: 10px;
  }

  @media (max-width: ${breakpoints.XL}) {
    gap: 12px;
  }

  @media (max-width: ${breakpoints.LG}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  @media (max-width: ${breakpoints.MD}) {
    display: flex;
    flex-direction: column;
  }
`;

const ItemCards = styled.li`
  flex: 1;

  &:nth-child(even) {
    margin-top: 60px;

    @media (max-width: ${breakpoints.LG}) {
      margin: 0;
    }
  }

  ${Body} {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
  }

  &:nth-of-type(1) {
    ${Body} {
      background-image: url(${discountTicketCard1440});
      background-size: 260px 200px;

      @media (max-width: ${breakpoints.XXL}) {
        background-position: 70% 0;
      }

      @media (max-width: ${breakpoints.XL}) {
        background-image: url(${discountTicketCard1024});
        background-size: contain;
      }

      @media (max-width: ${breakpoints.LG}) {
        background-image: url(${discountTicketCard768});
      }

      @media (max-width: ${breakpoints.MD}) {
        background-image: url(${discountTicketCard375});
        background-position: 105% center;
      }
    }

    ${Title} {
      @media (max-width: ${breakpoints.LG}) {
        max-width: 70%;
      }

      @media (max-width: ${breakpoints.MD}) {
        max-width: 50%;
      }
    }
  }

  &:nth-of-type(2) {
    @media (max-width: ${breakpoints.LG}) {
      margin-top: 40px;
    }

    @media (max-width: ${breakpoints.MD}) {
      margin-top: 0;
    }

    ${Body} {
      background-image: url(${discountTicketDarkCard1440});
      background-size: 235px 190px;
      background-position: 70% 0;

      @media (max-width: ${breakpoints.XXL}) {
        background-position: 85% 0;
      }

      @media (max-width: ${breakpoints.XL}) {
        background-image: url(${discountTicketDarkCard1024});
        background-size: contain;
        background-position: left top;
      }

      @media (max-width: ${breakpoints.LG}) {
        background-image: url(${discountTicketDarkCard768});
      }

      @media (max-width: ${breakpoints.MD}) {
        background-image: url(${discountTicketDarkCard375});
        background-position: right center;
      }
    }

    ${CardDiscount} {
      @media (max-width: ${breakpoints.LG}) {
        min-height: 280px;
      }

      @media (max-width: ${breakpoints.MD}) {
        min-height: unset;
      }
    }
  }

  &:nth-of-type(3) {
    @media (max-width: ${breakpoints.LG}) {
      margin-top: -100px;
    }

    @media (max-width: ${breakpoints.MD}) {
      margin-top: 0;
    }

    ${Body} {
      background-image: url(${discount99percent1440});
      background-size: 270px 160px;
      background-position: 80% 0;

      @media (max-width: ${breakpoints.XXL}) {
        background-position: 45px 0;
      }

      @media (max-width: ${breakpoints.XL}) {
        background-size: 92% auto;
        background-position: 15px 5px;
      }

      @media (max-width: ${breakpoints.LG}) {
        background-image: url(${discount99percent768});
        background-size: auto;
        background-position: right top;
      }

      @media (max-width: ${breakpoints.MD}) {
        background-size: 184px;
        background-position: right;
      }

      @media (max-width: ${breakpoints.SM}) {
        background-position: 115%;
      }
    }

    ${CardDiscount} {
      @media (max-width: ${breakpoints.LG}) {
        min-height: 280px;
      }

      @media (max-width: ${breakpoints.MD}) {
        min-height: unset;
      }
    }
  }

  &:nth-of-type(4) {
    ${Body} {
      background-image: url(${discountToys1440});
      background-position: top left;
      background-size: contain;
      white-space: break-spaces;

      @media (max-width: ${breakpoints.XL}) {
        background-size: 100% auto;
        padding-right: 30px;
      }

      @media (max-width: ${breakpoints.LG}) {
        background-image: url(${discountToys768});
      }

      @media (max-width: ${breakpoints.MD}) {
        background-image: url(${discountToys375});
        background-position: right center;
        background-size: contain;
      }
    }

    ${CardDiscount} {
      @media (max-width: ${breakpoints.LG}) {
        min-height: 260px;
      }

      @media (max-width: ${breakpoints.MD}) {
        min-height: unset;
      }
    }
  }
`;

export { CardsDiscounts, ListCards, ItemCards };
