import styled from "styled-components";
import {Button} from "../../../shared";
import {breakpoints} from "../../../shared/config";

const RecoverPasswordRestored = styled.section`
    max-width: 420px;
`;

const Title = styled.h2`
    font: var(--font-3);
    color: var(--brand-1);
    margin-bottom: 16px;

    @media (max-width: ${breakpoints.LG}) {
        margin-bottom: 10px;
    }

    @media (max-width: ${breakpoints.MD}) {
        margin-bottom: 12px;
    }
`;

const Subtitle = styled.p`
    font: var(--font-16);
    color: var(--brand-2);
    margin-bottom: 40px;

    @media (max-width: ${breakpoints.MD}) {
        margin-bottom: 32px;
    }
`;

const ButtonToSignIn = styled(Button)`
    max-width: 453px;

    @media (max-width: ${breakpoints.XL}) {
        max-width: 400px;
    }
`;

export {RecoverPasswordRestored, Title, Subtitle, ButtonToSignIn};
