export type BreedType = {
  id: number;
  title: string;
  adult_age_from: number;
  breed_class: string;
};

export type BreedRequestType = {
  user: number;
  name: string;
  date_of_birth: string;
  breed: string;
  gender: string;
};

export type PetType = {
  id: number;
  name: string;
  date_of_birth: string;
  date_of_adult: null | string;
  breed: BreedType;
  image: null | string;
  gender: "female" | "male";
  time_to_adult: {
    years: number;
    months: number;
  };
};

export type PetStateType = {
  pets: Array<PetType>;
  isPetsYoung: boolean | null;
  isPetsAdult: boolean | null;
};

export const initialStatePet: PetStateType = {
  pets: [],
  isPetsYoung: null,
  isPetsAdult: null,
};
