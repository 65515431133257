import styled from "styled-components";
import { ButtonUpload, IconMiniCardRoyals } from "../../atom";
import { breakpoints } from "../../../config";
import additionalRoyalsCover1440 from "../../../config/assets/images/additional-royals-cover-1440.png";
import additionalRoyalsCover1024 from "../../../config/assets/images/additional-royals-cover-1024.png";
import additionalRoyalsCover768 from "../../../config/assets/images/additional-royals-cover-768.png";
import additionalRoyalsCover375 from "../../../config/assets/images/additional-royals-cover-375.png";

const CardTargetAction = styled.div`
  position: relative;
  max-height: 280px;
  padding: 43px 56px;
  background-color: var(--pink-for-svg);
  background-image: url(${additionalRoyalsCover1440});
  background-size: cover;
  border-radius: 48px;
  width: 100%;
  overflow: hidden;

  @media (max-width: ${breakpoints.XXL}) {
    padding: 44px 50px;
  }

  @media (max-width: ${breakpoints.XL}) {
    padding: 54px 44px;
    background-image: url(${additionalRoyalsCover1024});
  }

  @media (max-width: ${breakpoints.LG}) {
    padding: 35px 32px;
    background-image: url(${additionalRoyalsCover768});
    border-radius: 36px;
  }

  @media (max-width: ${breakpoints.MD}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: unset;
    min-height: 570px;
    padding: 30px;
    background-image: url(${additionalRoyalsCover375});
    background-position: bottom;
  }

  @media (max-width: ${breakpoints.SM}) {
    min-height: 439px;
  }
`;

const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 434px;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 340px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 300px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: unset;
    align-items: center;
  }
`;

const RightPart = styled.div`
  @media (max-width: ${breakpoints.MD}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3vw;
  }

  @media (max-width: ${breakpoints.SM}) {
    gap: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: ${breakpoints.MD}) {
    align-items: center;
    gap: 10px;
  }
`;

const Title = styled.h2`
  font: var(--font-4);
  color: var(--brand-3);
  letter-spacing: -1.08px;

  @media (max-width: ${breakpoints.XL}) {
    letter-spacing: -1.36px;
  }

  @media (max-width: ${breakpoints.LG}) {
    letter-spacing: -1.04px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: 283px;
    text-align: center;
  }
`;

const Footnote = styled.p`
  font: var(--font-12);
  color: rgba(255, 255, 255, 0.6);
`;

const MiniCard = styled(IconMiniCardRoyals)`
  position: absolute;
  top: 35px;
  right: 517px;
  width: 200px;
  transform: rotate(-10deg);

  @media (max-width: ${breakpoints.XXL}) {
    right: 455px;
  }

  @media (max-width: ${breakpoints.XL}) {
    top: unset;
    right: 20px;
    bottom: 2px;
    width: 140px;
    transform: rotate(10deg);
  }

  @media (max-width: ${breakpoints.LG}) {
    top: -19px;
    right: 100px;
    bottom: unset;
    width: 160px;
    transform: rotate(-10deg);
  }

  @media (max-width: ${breakpoints.MD}) {
    position: relative;
    top: unset;
    right: unset;
    width: 25vw;
    transform: rotate(-20deg);
  }

  @media (max-width: ${breakpoints.SM}) {
    width: 120px;
  }
`;

const Text = styled.p`
  position: absolute;
  top: 87px;
  right: 80px;
  font: var(--font-4);
  max-width: 400px;
  color: var(--light-red-pattern);
  letter-spacing: -1.08px;

  @media (max-width: ${breakpoints.XXL}) {
    right: 41px;
  }

  @media (max-width: ${breakpoints.XL}) {
    top: 99px;
    right: 89px;
    max-width: 350px;
  }

  @media (max-width: ${breakpoints.LG}) {
    top: unset;
    right: 30px;
    bottom: 28px;
    max-width: 305px;
    text-align: center;
  }

  @media (max-width: ${breakpoints.MD}) {
    position: static;
    max-width: 74vw;
  }

  & svg {
    position: absolute;
    bottom: -5px;

    @media (max-width: ${breakpoints.LG}) {
      right: -35px;
    }

    @media (max-width: ${breakpoints.MD}) {
      right: unset;
    }
  }
`;

const Button = styled(ButtonUpload)`
  max-width: 230px;

  @media (max-width: ${breakpoints.XL}) {
    max-width: 195px;
    padding: 16px 10px;
  }

  @media (max-width: ${breakpoints.LG}) {
    max-width: 245px;
  }

  @media (max-width: ${breakpoints.MD}) {
    max-width: none;
  }
`;

export {
  CardTargetAction,
  LeftPart,
  RightPart,
  Content,
  Title,
  Footnote,
  MiniCard,
  Text,
  Button,
};
