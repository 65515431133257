import React, { FC } from "react";
import * as s from "./Cards-feedings-styles";
import { CardFeeding } from "../../atom";
import { ModalsEnumType } from "../../../config";

export type CardArrayType = {
  text: string;
  icon: string;
  modal: ModalsEnumType;
};

type CardsRoyalsType = {
  className?: string;
  cards: Array<CardArrayType>;
  onClickCardFeedings: (modalInfo: ModalsEnumType) => () => void;
};

export const CardsFeedings: FC<CardsRoyalsType> = ({
  className,
  cards,
  onClickCardFeedings,
}) => {
  return (
    <s.CardsFeedings className={className}>
      <s.ListCards>
        {cards.map((card, index) => (
          <s.ItemCards key={index}>
            <CardFeeding
              text={card.text}
              icon={card.icon}
              onClickCard={onClickCardFeedings(card.modal)}
            />
          </s.ItemCards>
        ))}
      </s.ListCards>
    </s.CardsFeedings>
  );
};
