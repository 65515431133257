import {AnimalPetType} from "../../../animal/model";
import {PetType} from "../../../pet/model";

export type RefreshRequest = {
  refresh: string;
};

export type RefreshResponse = {
  access: string;
};

export type RegistrationRequestBodyType = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  code: string;
  code_sms?: string;
  personal_data_handling: boolean;
  age_confirmation: boolean;
  promotional_mailing: boolean;
  loyalty_card: boolean;
  pets: Array<AnimalPetType<number>>;
};

export type UserType = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  image: any;
  phone_approved: boolean;
  is_active: boolean;
  vk_repost: boolean;
  ok_repost: boolean;
  available_royals: number;
  pets: Array<PetType>;
};

export const user: UserType = {
  id: 0,
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  image: null,
  phone_approved: false,
  is_active: false,
  vk_repost: false,
  ok_repost: false,
  available_royals: 0,
  pets: [],
};

export type AuthStateType = {
  error?: string | null;
  isAuthorization: boolean;
  isDisabledButton: boolean;
  user: UserType;
};

export const initialStateAuth: AuthStateType = {
  error: null,
  isAuthorization: false,
  isDisabledButton: false,
  user,
};

export type SignInRequestBodyType = {
  email: string;
  password: string;
};

export interface SignInRequestInterface {
  access: string;
  refresh: string;
}
