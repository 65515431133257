import * as React from "react";
import {FC} from "react";
import * as s from "./Modal-ref-email-friend-styles";
import {useFormik} from "formik";
import {validationFormModalRefEmailFriend} from "../config";
import {ButtonProperty, ModalsProperty} from "../../../../shared/config";
import {useAppDispatch} from "../../../../shared/lib";
import {InviteType, sendInviteThunk} from "../../../../shared/model";
import {setModals} from "../../../../widgets/modals/model";

type ModalRefEmailFriendType = {
  className?: string;
  buttonRedirectText: string;
  onShowModal: () => void;
};

export const ModalRefEmailFriend: FC<ModalRefEmailFriendType> = ({
                                                                   className,
                                                                   buttonRedirectText,
                                                                   onShowModal,
                                                                 }) => {
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: validationFormModalRefEmailFriend,

    onSubmit: async (body: InviteType) => {
      await dispatch(sendInviteThunk(body));
      await dispatch(
        setModals({
          action: ModalsProperty.MODAL_CONFIRMATION_REF_EMAIL_FRIEND,
        })
      );
    },
  });

  return (
    <s.ModalRefEmailFriend className={className}>
      <s.Modal
        buttonRedirectText={buttonRedirectText}
        onClickButtonBg={onShowModal}
        onClickButtonIcon={onShowModal}
      >

        <s.Title>Пригласить друга с&nbsp;питомцем
          <s.Wrap>
            и&nbsp;получить
            <s.Icon number={"+10"} disabled={true}/>
          </s.Wrap>
        </s.Title>

        <s.Form onSubmit={formik.handleSubmit}>
          <s.Text>Введите E-mail друга</s.Text>

          <s.SubText>На этот адрес мы отправим ссылку на регистрацию</s.SubText>

          <s.InputEmail
            label={"E-mail"}
            type={"text"}
            {...formik.getFieldProps("email")}
            {...formik.getFieldMeta("email")}
            {...formik.getFieldHelpers("email")}
          />

          <s.ButtonEmail
            property={ButtonProperty.SECONDARY}
            text={"Отправить приглашение"}
            type={"submit"}
            onClickButton={() => {
            }}
          />
        </s.Form>
      </s.Modal>
    </s.ModalRefEmailFriend>
  );
};
