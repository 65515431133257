import * as React from 'react';
import {FC} from 'react';
import * as s from "./Button-text-icon-styles";

type ButtonTextIconType = {
  className?: string;
  text?: string;
  number: string;
  disabled?: boolean;
  onClick?: () => void
};

export const ButtonTextIcon: FC<ButtonTextIconType> = (
  {
    className,
    text,
    number,
    disabled,
    onClick,
  }) => {
  return (
    <s.ButtonTextIcon className={className} type={"button"} onClick={onClick} disabled={disabled}>
      {text}

      <s.WrapIcon>
        <s.Number>{number}</s.Number>
        <s.Icon bg={true}/>
      </s.WrapIcon>
    </s.ButtonTextIcon>
  );
};