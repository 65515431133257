import React, {FC, ReactNode} from "react";
import {useNavigate} from "react-router-dom";
import {esAuth, esDiscount} from "../../../entities";
import {
  authRegistrationThunk,
  getUserThunk,
  RegistrationRequestBodyType,
  setAuthorization,
  setDisabledButton,
} from "../../../entities/auth/model";
import {
  ModalAboutInvitation,
  ModalAddAnimal,
  ModalAdditionalRoyals,
  ModalCheckEmail,
  ModalConfirmation,
  ModalConfirmationPhone,
  ModalConfirmationRefEmailFriend,
  ModalConfirmationSuccessful,
  ModalExit,
  ModalFeedingTypes,
  ModalFrequentlyAskedQuestion,
  ModalHistoryOperations,
  ModalPromoCode,
  ModalPromoCodeType,
  ModalQuestion,
  ModalQuestionSent,
  ModalReceipt,
  ModalRefEmailFriend,
  ModalSuccessfullyRegistered,
} from "../../../features";
import {ModalAboutInvitationButtons} from "../../../features/_modals/modal-about-invitation-buttons";
import {ButtonText} from "../../../shared";
import {ModalsEnumType, ModalsProperty, pages} from "../../../shared/config";
import {deleteMaskPhone, useAppDispatch, useAppSelector, useChangeLetter,} from "../../../shared/lib";
import {ws} from "../index";
import {setModals} from "../model";
import * as s from "./Modals-styles";
import {PromocodeType, PromoCodeType} from "../../../entities/discount/model";
import {
  checkPhoneThunk,
  checkSmsAnonThunk,
  checkSmsThunk,
  getOkRepostThunk,
  getVkRepostThunk,
  selectShared,
  sendSmsThunk,
  setDeleteNotification,
  setNotification,
  SmsType,
} from "../../../shared/model";
import {v4 as uuidv4} from "uuid";

type ModalsType = {
  action: ModalsEnumType;
  content?: ReactNode;
};

export const Modals: FC<ModalsType> = ({action, content}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(esAuth.model.selectAuthUser);
  const errorAuth = useAppSelector(esAuth.model.selectAuthError);
  const {error} = useAppSelector(selectShared);
  const resetModal = () => {
    dispatch(ws.model.setModals({action: null, content: null}));
  };

  const getModal = (action: ModalsEnumType) => {
    switch (action) {
      case ModalsProperty.MODAL_CONFIRMATION_EMAIL:
        const obj = content as unknown as {
          body: RegistrationRequestBodyType;
          invitationToken?: string;
        };
        console.log(obj);
        return (
          <ModalConfirmation
            buttonRedirectText={"К регистрации"}
            buttonTextBefore={"Отправить письмо"}
            buttonTextAfter={"Отправить письмо повторно через"}
            title={"Введите код из письма для подтверждения почты"}
            subText={`Мы отправили код на ${
              obj?.body.email ? obj.body.email : ""
            }. Письмо может попасть в папку «Спам».`}
            onShowModal={() => {
              resetModal();
              dispatch(esAuth.model.setError(null));
            }}
            error={errorAuth}
            lengthNumber={7}
            inputPlaceholder={"2564714"}
            onChangeInput={(code: string) => {
              if (code.length === 7) {
                dispatch(
                  authRegistrationThunk({
                    body: {
                      ...obj.body,
                      code: code,
                    },
                    invitationToken: obj.invitationToken,
                  })
                );
              }
            }}
            onClickButtonTime={async () => {
              await esAuth.api.authApi.email_confirmation({
                email: obj.body.email,
                name: obj.body.last_name,
              });

              dispatch(esAuth.model.setError(null));
            }}
          />
        );

      case ModalsProperty.MODAL_SUCCESSFULLY_REGISTERED:
        return (
          <ModalSuccessfullyRegistered
            onShowModal={resetModal}
            onClickButton={() => {
              dispatch(setAuthorization(true));
              dispatch(getUserThunk());
              navigate(pages.personalAreaProfile.link);
              resetModal();
            }}
          />
        );

      case ModalsProperty.MODAL_SEND_PHONE:
        const objModalSendPhone = content as unknown as {
          phone: string;
          isValidationPhone: boolean;
        };
        return (
          <ModalConfirmationPhone
            onShowModal={resetModal}
            phone={objModalSendPhone?.phone}
            onSubmit={async (body: SmsType) => {
              const responsePhone = objModalSendPhone?.isValidationPhone
                ? {availible: true}
                : await dispatch(
                  checkPhoneThunk({phone: objModalSendPhone.phone})
                );

              // @ts-ignore
              if (responsePhone.payload?.availible === false) {
                dispatch(
                  setModals({action: ModalsProperty.MODAL_CHECK_PHONE})
                );
              } else {
                const response = await dispatch(
                  sendSmsThunk({phone: deleteMaskPhone(body.phone)})
                );

                if (response.meta.requestStatus === "fulfilled") {
                  dispatch(
                    setModals({
                      action: ModalsProperty.MODAL_CONFIRMATION_PHONE,
                      content: {phone: body.phone},
                    })
                  );
                }
              }
            }}
          />
        );

      case ModalsProperty.MODAL_SEND_PHONE_ANON:
        const objModalSendPhoneAnon = content as unknown as {
          phone: string;
        };
        return (
          <ModalConfirmationPhone
            onShowModal={resetModal}
            phone={objModalSendPhoneAnon?.phone}
            onSubmit={async (body: SmsType) => {
              const responsePhone = await dispatch(
                checkPhoneThunk({phone: objModalSendPhoneAnon.phone})
              );

              // @ts-ignore
              if (responsePhone.payload?.availible === false) {
                dispatch(
                  setModals({action: ModalsProperty.MODAL_CHECK_PHONE})
                );
              } else {
                const response = await dispatch(
                  sendSmsThunk({phone: deleteMaskPhone(body.phone)})
                );

                if (response.meta.requestStatus === "fulfilled") {
                  dispatch(
                    setModals({
                      action: ModalsProperty.MODAL_CONFIRMATION_PHONE_ANON,
                      content: {phone: body.phone},
                    })
                  );
                }
              }
            }}
          />
        );

      case ModalsProperty.MODAL_CONFIRMATION_PHONE:
        const obj1 = content as unknown as { phone: string };
        return (
          <s.ModalConfirmationPhone
            buttonRedirectText={"К профилю"}
            buttonTextBefore={"Отправить код"}
            buttonTextAfter={"Отправить код повторно через"}
            title={
              <>
                Подтвердите номер телефона{" "}
                <s.SpanIconRoyal>
                  и&nbsp;получите <s.IconRoyal number={"5"} disabled={true}/>
                </s.SpanIconRoyal>
              </>
            }
            subText={
              <>
                <s.TextModalConfirmationPhone>
                  Введите код из смс
                </s.TextModalConfirmationPhone>
                Мы отправили его на&nbsp;номер {obj1.phone}{" "}
                <ButtonText
                  type={"button"}
                  text={"Изменить"}
                  onClickButtonText={async () => {
                    await resetModal();
                    await dispatch(esAuth.model.setError(null));
                    await dispatch(
                      setModals({
                        action: ModalsProperty.MODAL_SEND_PHONE,
                      })
                    );
                  }}
                />
              </>
            }
            onShowModal={resetModal}
            error={error}
            lengthNumber={5}
            inputPlaceholder={"25647"}
            onChangeInput={async (code: string) => {
              if (code.length === 5) {
                const response = await dispatch(
                  checkSmsThunk({
                    phone: deleteMaskPhone(obj1.phone),
                    code: Number(code),
                  })
                );
                if (response.meta.requestStatus === "fulfilled") {
                  await dispatch(getUserThunk());
                }
              }
            }}
            onClickButtonTime={() => {
              dispatch(sendSmsThunk({phone: deleteMaskPhone(obj1.phone)}));
            }}
          />
        );

      case ModalsProperty.MODAL_CONFIRMATION_PHONE_ANON:
        const obj1Anon = content as unknown as { phone: string };

        return (
          <s.ModalConfirmationPhone
            buttonRedirectText={"К профилю"}
            buttonTextBefore={"Отправить код"}
            buttonTextAfter={"Отправить код повторно через"}
            title={
              <>
                Подтвердите номер телефона{" "}
                <s.SpanIconRoyal>
                  и&nbsp;получите <s.IconRoyal number={"5"} disabled={true}/>
                </s.SpanIconRoyal>
              </>
            }
            subText={
              <>
                <s.TextModalConfirmationPhone>
                  Введите код из смс
                </s.TextModalConfirmationPhone>
                Мы отправили его на&nbsp;номер {obj1Anon.phone}{" "}
                <ButtonText
                  type={"button"}
                  text={"Изменить"}
                  onClickButtonText={async () => {
                    await resetModal();
                    await dispatch(esAuth.model.setError(null));
                    await dispatch(
                      setModals({
                        action: ModalsProperty.MODAL_SEND_PHONE,
                      })
                    );
                  }}
                />
              </>
            }
            onShowModal={resetModal}
            error={error}
            lengthNumber={5}
            inputPlaceholder={"25647"}
            onChangeInput={async (code: string) => {
              if (code.length === 5) {
                const response = await dispatch(
                  checkSmsAnonThunk({
                    phone: deleteMaskPhone(obj1Anon.phone),
                    code: Number(code),
                  })
                );
                if (response.meta.requestStatus === "fulfilled") {
                  localStorage.setItem("anon-sms-code", JSON.stringify(code));
                  dispatch(setDisabledButton(true));
                }
              }
            }}
            onClickButtonTime={() => {
              dispatch(
                sendSmsThunk({phone: deleteMaskPhone(obj1Anon.phone)})
              );
            }}
          />
        );

      case ModalsProperty.MODAL_CONFIRMATION_SUCCESSFULLY_ANON:
        return (
          <ModalConfirmationSuccessful
            buttonRedirectText={"К профилю"}
            title={"Номер телефона успешно потвержден"}
            text={
              "Начисленные роялсы отобразятся в Личном Кабинете после регистрации"
            }
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_CONFIRMATION_SUCCESSFULLY:
        return (
          <ModalConfirmationSuccessful
            buttonRedirectText={"К профилю"}
            title={"Номер телефона успешно потвержден"}
            text={"Пришлем смс на подтвержденный номер, когда начислим роялсы"}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_REF_EMAIL_FRIEND:
        return (
          <ModalRefEmailFriend
            buttonRedirectText={"К профилю"}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_CONFIRMATION_REF_EMAIL_FRIEND:
        return (
          <ModalConfirmationRefEmailFriend
            buttonRedirectText={"К профилю"}
            onShowModal={resetModal}
            onClickButton={async () => {
              await resetModal();
              dispatch(
                setModals({action: ModalsProperty.MODAL_REF_EMAIL_FRIEND})
              );
            }}
          />
        );

      case ModalsProperty.MODAL_RECEIPT:
        return <ModalReceipt onShowModal={resetModal}/>;

      case ModalsProperty.MODAL_ABOUT_INVITATION_ANIMAL:
        return (
          <ModalAboutInvitation
            title={"Приглашайте друзей с\u00A0питомцами"}
            onShowModal={resetModal}
            list={[
              {
                text: "Зайдите в Личный кабинет и нажмите на кнопку «Пригласить друга».",
              },

              {
                text: "Введите e-mail друга, на него будет отправлена ссылка для регистрации на Сайте.",
              },

              {
                text: "За каждого друга, который зарегистрируется по вашей ссылке и загрузит чеки в сумме на 5 роялсов, мы начислим 10 подарочных роялсов в течение 10 дней.",
              },
            ]}
          />
        );

      case ModalsProperty.MODAL_ABOUT_INVITATION_SOCIAL_MEDIA:
        return (
          <ModalAboutInvitation
            title={"Рассказывайте о программе в\u00A0социальных сетях"}
            onShowModal={resetModal}
            list={[
              {
                text: "Зайдите в Личный кабинет  в блок «Рассказывайте о нас в соц. сетях».",
              },

              {
                text: "Перейдите по ссылке в Одноклассники или Вконтакте и авторизируйтесь.",
              },

              {
                text: "Опубликуйте пост с информацией о Сайте и программе лояльности Роял Канин – Зоозавр.",
              },

              {
                text: "Получите по 5 роялсов за каждую соц. сеть в течение 10 дней.",
              },
            ]}
          />
        );

      case ModalsProperty.MODAL_ABOUT_INVITATION_ANIMAL_BUTTONS:
        return (
          <ModalAboutInvitationButtons
            title={
              <>
                Пригласите друга с питомцем{" "}
                <s.SpanIconRoyal>
                  и получите <s.IconRoyal number={"+10"} disabled={true}/>
                </s.SpanIconRoyal>
              </>
            }
            onShowModal={resetModal}
            list={[
              {
                text: "Зайдите в Личный кабинет и нажмите на кнопку «Пригласить друга».",
              },

              {
                text: "Введите e-mail друга, на него будет отправлена ссылка для регистрации на Сайте.",
              },

              {
                text: "За каждого друга, который зарегистрируется по вашей ссылке и загрузит чеки в сумме на 5 роялсов, мы начислим 10 подарочных роялсов в течение 10 дней.",
              },
            ]}
            buttons={[
              <s.ButtonInviteModal
                icon={<s.IconUser/>}
                onClickButtonInvite={() => {
                  resetModal();
                  dispatch(
                    setModals({action: ModalsProperty.MODAL_REF_EMAIL_FRIEND})
                  );
                }}
                text="Пригласить друга"
                type="button"
              />,
            ]}
          />
        );

      case ModalsProperty.MODAL_ABOUT_INVITATION_SOCIAL_MEDIA_BUTTONS:
        const onRepostVk = async () => {
          await dispatch(getVkRepostThunk());
          await dispatch(getUserThunk());
        };

        const onRepostOk = async () => {
          await dispatch(getOkRepostThunk());
          await dispatch(getUserThunk());
        };

        return (
          (!user.vk_repost || !user.ok_repost) && (
            <ModalAboutInvitationButtons
              title={
                <>
                  Сделайте репост в соц.сетях{" "}
                  <s.SpanIconRoyal>
                    и получите
                    <s.IconRoyal number={"+5"} disabled={true}/>
                  </s.SpanIconRoyal>
                </>
              }
              onShowModal={resetModal}
              list={[
                {
                  text: "Зайдите в Личный кабинет  в блок «Рассказывайте о нас в соц. сетях».",
                },

                {
                  text: "Перейдите по ссылке в Одноклассники или Вконтакте и авторизируйтесь.",
                },

                {
                  text: "Опубликуйте пост с информацией о Сайте и программой лояльности Роял Канин-Зоозавр.",
                },

                {
                  text: "Получите по 5 роялсов за каждую соц. сеть в течение 10 дней.",
                },
              ]}
              buttons={[
                !user.vk_repost && (
                  <a
                    href="https://vk.com/share.php?url=https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai&title=%D0%9F%D1%80%D0%B8%D1%81%D0%BE%D0%B5%D0%B4%D0%B8%D0%BD%D1%8F%D0%B9%D1%82%D0%B5%D1%81%D1%8C%20%D0%BA%20%D0%B0%D0%BA%D1%86%D0%B8%D0%B8%20%D0%97%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D1%8B%D0%B9%20%D0%BF%D0%B8%D1%82%D0%BE%D0%BC%D0%B5%D1%86.&image=https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/banners/banner-vk.jpg&noparse=true"
                    target={"_blank"}
                  >
                    <s.ButtonVK
                      icon={<s.IconButtonVk/>}
                      onClickButtonInvite={onRepostVk}
                      text="Вконтакте"
                      type="button"
                    />
                  </a>
                ),
                !user.ok_repost && (
                  <a
                    href="https://connect.ok.ru/offer?url=https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai"
                    target={"_blank"}
                  >
                    <s.ButtonOd
                      icon={<s.IconButtonOk/>}
                      onClickButtonInvite={onRepostOk}
                      text="Одноклассники"
                      type="button"
                    />
                  </a>
                ),
              ]}
            />
          )
        );

      case ModalsProperty.MODAL_FEEDING_TYPES_NATURAL_NEEDS:
        return (
          <ModalFeedingTypes
            onShowModal={resetModal}
            list={[
              {
                text: (
                  <>
                    Домашние собаки и кошки{" "}
                    <span>естественным образом выбирают</span> рацион с высоким
                    содержанием белка и жиров и относительно низким содержанием
                    углеводов, а также с высоким содержанием влаги*.
                  </>
                ),
              },
              {
                text: (
                  <>
                    Наибольшее соответствие этим потребностям питомцев{" "}
                    <span>
                      обеспечивается посредством смешивания влажного и сухого
                      рационов.
                    </span>
                  </>
                ),
              },
            ]}
            footnote={"*(Bradshaw JW, 1991, 2006)"}
          />
        );

      case ModalsProperty.MODAL_FEEDING_TYPES_OPTIMAL_WEIGHT:
        return (
          <ModalFeedingTypes
            onShowModal={resetModal}
            list={[
              {
                text: (
                  <>
                    Высокое содержание влаги во влажном рационе{" "}
                    <span>
                      обеспечивает снижение количества ежедневно потребляемых
                      калорий,
                    </span>{" "}
                    {""}
                    ускоряет наступление сытости и снижает скорость потребления
                    рациона.
                  </>
                ),
              },
              {
                text: (
                  <>
                    Набор массы тела можно предотвратить* при помощи диет с
                    содержанием влаги <span>как минимум 40%.</span>
                  </>
                ),
              },
            ]}
            footnote={"*(Morris et al, 2006)."}
          />
        );

      case ModalsProperty.MODAL_FEEDING_TYPES_UROLITHIASIS:
        return (
          <ModalFeedingTypes
            onShowModal={resetModal}
            list={[
              {
                text: (
                  <>
                    Риск развития заболеваний нижнего отдела мочевыводящих путей
                    обратно{" "}
                    <span>пропорционален содержанию влаги в рационе.</span>
                  </>
                ),
              },

              {
                text: (
                  <>
                    Рацион, который состоит как минимум на 25% из влажного
                    рациона, способствует увеличению объема мочи у животных и{" "}
                    <span>
                      помогает снизить риск образования мочевых камней*.
                    </span>
                  </>
                ),
              },
            ]}
            footnote={"*(Buckley et al, 2010, 2011)."}
          />
        );

      case ModalsProperty.MODAL_FEEDING_TYPES_DIABETES:
        return (
          <ModalFeedingTypes
            onShowModal={resetModal}
            list={[
              {
                text: (
                  <>
                    Эксперты сходятся во мнении*, что{" "}
                    <span>наилучшей мерой профилактики диабета</span> считается
                    применение рациона с высоким содержанием белка, умеренным
                    содержанием жиров и низким содержанием углеводов.
                  </>
                ),
              },

              {
                text: (
                  <>
                    Это можно реализовать посредством{" "}
                    <span>сочетания влажного и сухого рационов.</span>
                  </>
                ),
              },
            ]}
            footnote={
              "*(Slingerland LI, Fazilova VV, Plantinga EA, 2009; Rand JS, Farrow HA, Fleeman LM, et al, 2003)"
            }
          />
        );

      case ModalsProperty.MODAL_FEEDING_TYPES_OLD_PETS:
        return (
          <ModalFeedingTypes
            onShowModal={resetModal}
            list={[
              {
                text: (
                  <>
                    Благодаря мягкой текстуре{" "}
                    <span>влажные рационы легче захватывать</span> и поедать
                    животным с неполным набором зубов или повышенной
                    чувствительностью десен*.
                  </>
                ),
              },
            ]}
            footnote={
              <>
                (Fahey GC, Barry KA, Swanson KS, 2008; Harper&nbsp;EJ, Turner
                CL., 2000)
              </>
            }
          />
        );

      case ModalsProperty.MODAL_ADDITIONAL_ROYALS_HEART:
        return (
          <ModalAdditionalRoyals
            title={"Регистрируйтесь в программе лояльности"}
            list={[
              {
                text: "Нажмите кнопку «Зарегистрироваться» на Сайте.",
              },

              {
                text: "Заполните обязательные личные данные – получите 10 роялсов.",
              },

              {
                text: "Зарегистрируйте первого питомца – получите 15 роялсов.",
              },

              {
                text: "Подтвердите номер телефона – получите 5 роялсов.",
              },
            ]}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_ADDITIONAL_ROYALS_WINGS:
        return (
          <ModalAdditionalRoyals
            title={"Приглашайте друзей с питомцами"}
            list={[
              {
                text: "Зайдите в Личный кабинет и нажмите на кнопку «Пригласить друга».",
              },

              {
                text: "Введите e-mail друга, на него будет отправлена ссылка для регистрации на Сайте.",
              },

              {
                text: "За каждого друга, который зарегистрируется по вашей ссылке и загрузит чеки в сумме на 5 роялсов, мы начислим 10 подарочных роялсов в течение 10 дней.",
              },
            ]}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_ADDITIONAL_ROYALS_STAR:
        return (
          <ModalAdditionalRoyals
            title={"Рассказывайте о программе в\u00A0социальных сетях"}
            list={[
              {
                text: "Зайдите в Личный кабинет в блок «Рассказывайте о нас в\u00A0соц. сетях».",
              },

              {
                text: "Перейдите по ссылке в Одноклассники или Вконтакте и\u00A0авторизируйтесь.",
              },

              {
                text: "Опубликуйте пост с информацией о Сайте и программой лояльности Роял Канин-Зоозавр.",
              },

              {
                text: "Получите по 5 роялсов за каждую соц. сеть в течение 10 дней.",
              },
            ]}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_HISTORY_OPERATIONS:
        return <ModalHistoryOperations onShowModal={resetModal}/>;

      case ModalsProperty.MODAL_ADD_ANIMAL:
        return <ModalAddAnimal onShowModal={resetModal}/>;

      case ModalsProperty.MODAL_PROMO_CODE:
        const obj4 = content as unknown as PromocodeType;

        return (
          <ModalPromoCode
            onShowModal={resetModal}
            tags={obj4.tags}
            assignment={obj4.assignment}
            description={obj4.description}
            title={useChangeLetter({
              text: obj4.title || "",
              icon: <s.RubleIcon/>,
              targetChar: "₽",
            })}
          />
        );

      case ModalsProperty.MODAL_PROMO_CODE_TYPE:
        const promoCodeType = content as unknown as PromoCodeType & {
          isButton: boolean;
          titleModal?: string;
        };
        const getPromoCode = async (id: number) => {
          const idv4 = uuidv4();
          await dispatch(esDiscount.model.sendPromoCodeThunk(id));
          await dispatch(esAuth.model.getUserThunk());
          await dispatch(
            setNotification({
              text: "Промокод получен!",
              id: idv4,
            })
          );
          await setTimeout(() => {
            dispatch(setDeleteNotification(idv4));
          }, 10000);
        };
        return (
          <ModalPromoCodeType
            title={promoCodeType.titleModal}
            counter={String(promoCodeType.price)}
            isRoyals={!!promoCodeType.price}
            list={promoCodeType.description}
            text={promoCodeType.title}
            isButton={promoCodeType.isButton}
            isDisabledButton={promoCodeType.available}
            onShowModal={resetModal}
            onClickButton={async () => {
              await getPromoCode(promoCodeType.id);
              resetModal();
            }}
          />
        );
      case ModalsProperty.MODAL_QUESTION:
        return (
          <ModalQuestion
            onShowModal={resetModal}
            onSubmit={() => {
              dispatch(
                setModals({action: ModalsProperty.MODAL_QUESTION_SENT})
              );
            }}
          />
        );

      case ModalsProperty.MODAL_QUESTION_SENT:
        return <ModalQuestionSent onShowModal={resetModal}/>;

      case ModalsProperty.MODAL_FREQUENTLY_ASKED_QUESTION_QUESTION:
        const modalFrequentlyAskedQuestionTitle = content as unknown as string;
        return (
          <ModalFrequentlyAskedQuestion
            title={modalFrequentlyAskedQuestionTitle}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_EXIT:
        return <ModalExit onShowModal={resetModal}/>;

      case ModalsProperty.MODAL_CHECK_EMAIL:
        return (
          <ModalCheckEmail
            text={"Данный E-mail уже занят"}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_CHECK_PHONE:
        return (
          <ModalCheckEmail
            text={"Данный телефон уже занят"}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_CHECK_EMAIL_PHONE:
        return (
          <ModalCheckEmail
            text={"Данные телефон и E-mail уже заняты"}
            onShowModal={resetModal}
          />
        );

      case ModalsProperty.MODAL_ERROR_IMAGE:
        return (
          <s.ModalErrorImage
            onClickButtonBg={resetModal}
            onClickButtonIcon={resetModal}
          >
            <s.ModalErrorImageTitle>
              Не удается загрузить изображение
            </s.ModalErrorImageTitle>

            <s.ModalErrorImageText>
              Этот формат недопустим. Используйте изображения в следующих
              форматах - png, jpeg, jpg, webp.
            </s.ModalErrorImageText>
          </s.ModalErrorImage>
        );
      default:
        return "";
    }
  };

  return <>{getModal(action)}</>;
};
