import {type ActionReducerMapBuilder, createSlice, PayloadAction,} from "@reduxjs/toolkit";
import {type NoInfer} from "react-redux";
import {AuthStateType, initialStateAuth as initialState, UserType,} from "../type/auth-type";
import {getUserThunk} from "../thunk/auth-thunk";

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setError: (state: AuthStateType, action: PayloadAction<string | null>) => {
      if (!!action.payload) {
        if (action.payload?.length > 500) {
          state.error = "Произошла ошибка попробуйте повторить позже"
        } else {
          state.error = action.payload;
        }
      }
    },
    setAuthorization: (
      state: AuthStateType,
      action: PayloadAction<boolean>
    ) => {
      state.isAuthorization = action.payload;
    },

    setDisabledButton: (
      state: AuthStateType,
      action: PayloadAction<boolean>
    ) => {
      state.isDisabledButton = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<NoInfer<any>>) => {
    builder.addCase(
      getUserThunk.fulfilled.type,
      (state: AuthStateType, action: PayloadAction<UserType>) => {
        state.user = action.payload;
      }
    );
  },

  selectors: {
    selectAuth: (state) => state,
    selectAuthError: (state) => state.error,
    selectAuthUser: (state) => state.user,
  },
});

export const {reducer} = authSlice;
export const authReducer = reducer;
export const {setError, setAuthorization, setDisabledButton} = authSlice.actions;
export const {selectAuth, selectAuthError, selectAuthUser} =
  authSlice.selectors;
