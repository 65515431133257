import React, {FC} from "react";
import * as s from "./Modal-confirmation-successful-styles";

type ModalConfirmationSuccessfulType = {
  className?: string;
  buttonRedirectText: string;
  title?: string;
  text?: string;
  onShowModal: () => void;
};

export const ModalConfirmationSuccessful: FC<
  ModalConfirmationSuccessfulType
> = ({className, buttonRedirectText, title, text, onShowModal}) => {
  return (
    <s.ModalConfirmationSuccessful className={className}>
      <s.Modal
        buttonRedirectText={buttonRedirectText}
        onClickButtonBg={onShowModal}
        onClickButtonIcon={onShowModal}
      >
        <s.Title>{title}</s.Title>

        <s.Text>
          {text}
        </s.Text>
      </s.Modal>
    </s.ModalConfirmationSuccessful>
  );
};
