import * as React from "react";
import { FC, ReactNode } from "react";
import * as s from "./Banner-profile-info-styles";
import { IconUpload } from "../../atom";

type InfoItem = {
  icon: ReactNode;
  text: string;
  subText?: string;
};

type InfoType = {
  title: string;
  list: Array<InfoItem>;
};

type BannerProfileInfoTypo = {
  className?: string;
  list: Array<InfoType>;
  onClickButtonUpload?: () => void;
};

export const BannerProfileInfo: FC<BannerProfileInfoTypo> = ({
  className,
  list,
  onClickButtonUpload,
}) => {
  return (
    <s.BannerProfileInfo className={className}>
      <s.Wrap>
        {list.map((el, index) => (
          <s.WrapList key={index}>
            <s.Title>{el.title}</s.Title>

            <s.List>
              {el.list.map((item, number) => (
                <s.Item key={number}>
                  {item.icon}

                  <s.Text>
                    {item.text}
                    {item.subText ? <s.Span>{item.subText}</s.Span> : ""}
                  </s.Text>
                </s.Item>
              ))}
            </s.List>
          </s.WrapList>
        ))}
      </s.Wrap>

      <s.Button
        textUploadButton={"Загрузить чек"}
        typeUploadButton={"button"}
        iconUploadButton={<IconUpload />}
        onClickButtonUpload={onClickButtonUpload}
      />
    </s.BannerProfileInfo>
  );
};
