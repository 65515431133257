import React, {FC} from "react";

export const IconDoc: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 36 36"}
    fill="none"
    {...props}
  >
    <rect width={36} height={36} fill="#EEE" rx={10}/>
    <path
      stroke="#444"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M28 16v5c0 5-2 7-7 7h-6c-5 0-7-2-7-7v-6c0-5 2-7 7-7h5"
    />
    <path
      stroke="#444"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M28 16h-4c-3 0-4-1-4-4V8l8 8Z"
    />
  </svg>
);
