import React, {FC, useEffect} from "react";
import * as s from "./Root-styles";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {Footer} from "../../../shared";
import {useAppDispatch} from "../../../shared/lib";
import {setModals} from "../../../widgets/modals/model";
import {ModalsProperty, pages} from "../../../shared/config";
import {getQrTransition} from "../../../shared/model";

type AuthType = {};

export const Root: FC<AuthType> = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onOpenModalQuestion = () => {
    dispatch(setModals({action: ModalsProperty.MODAL_QUESTION}));
  };

  useEffect(() => {
    if (location.hash.includes("#Qr-code")) {
      navigate(pages.main.link);
      dispatch(getQrTransition());
    }
  }, []);

  return (
    <s.Root>
      <s.Content>
        <Outlet/>
      </s.Content>

      <s.RootContainer>
        <Footer
          id={"footer"}
          links={[
            {
              title: "Корпоративная политика",
              items: [
                {
                  text: "Правила использования сайта",
                  href: "https://www.mars.com/global/policies/legal/ld-russian",
                },

                {
                  text: "Конфиденциальность",
                  href: "https://www.mars.com/global/policies/privacy/pp-russian",
                },

                {
                  text: "Доступность",
                  href: "https://www.mars.com/accessibility-russian",
                },

                {
                  text: "Cookies",
                  href: "https://www.mars.com/cookies-russia",
                },
              ],
            },

            {
              title: "Об акции",
              items: [
                {
                  text: "Пользовательское соглашение",
                  href: "https://www.royalcanin.com/ru/about-us/terms-and-conditions",
                },

                {
                  text: "Правила акции",
                  href: "https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/rules/Правила_акции__Здоровый_питомец.pdf",
                },
              ],
            },
          ]}
          onClickButton={onOpenModalQuestion}
        />
      </s.RootContainer>
    </s.Root>
  );
};
