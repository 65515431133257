import React, { FC } from "react";

export const IconDocSquare: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 24 24"}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 18.293V16.5005H17.7925L16 18.293ZM6.5 18.5005V5.50049C6.5 5.22449 6.7245 5.00049 7 5.00049H18C18.2755 5.00049 18.5 5.22449 18.5 5.50049V15.5005H15.5C15.224 15.5005 15 15.724 15 16.0005V19.0005H7C6.7245 19.0005 6.5 18.776 6.5 18.5005ZM19.5 15.586V5.50049C19.5 4.67299 18.827 4.00049 18 4.00049H7C6.173 4.00049 5.5 4.67299 5.5 5.50049V18.5005C5.5 19.3275 6.173 20.0005 7 20.0005H15.0855C15.297 20.0005 15.5005 19.9545 15.688 19.8715C15.696 19.8685 15.7025 19.8635 15.71 19.86C15.8695 19.7865 16.0185 19.689 16.146 19.561L19.0605 16.6465C19.1885 16.5185 19.2865 16.3695 19.36 16.21C19.3635 16.2025 19.368 16.1965 19.3715 16.1885C19.454 16.001 19.5 15.7975 19.5 15.586Z"
      fill="#767676"
    />
  </svg>
);
