import React, {FC, useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {ButtonProperty, pages} from "../../../shared/config";
import * as s from "./Recover-password-restored-styles";
import {useScrollToTop} from "../../../shared/lib";

type RecoverPasswordRestoredType = {};

export const RecoverPasswordRestored: FC<
  RecoverPasswordRestoredType
> = ({}) => {
  useScrollToTop();

  const navigate = useNavigate();

  const routeToSignIn = useCallback(() => {
    navigate(pages.signIn.link);
  }, [navigate]);

  return (
    <s.RecoverPasswordRestored>
      <s.Title>Новый пароль отправлен на указанную почту</s.Title>

      <s.Subtitle>Письмо может попасть в папку Спам. Нажмите "Войти", чтобы авторизоваться</s.Subtitle>

      <s.ButtonToSignIn
        property={ButtonProperty.SECONDARY}
        type={"button"}
        text={"Войти"}
        onClickButton={routeToSignIn}
      />
    </s.RecoverPasswordRestored>
  );
};
