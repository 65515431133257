import React, { FC } from "react";

export const IconMenuUpload: FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 65"
    fill="none"
    {...props}
  >
    <rect width={64} height={64} y={0.5} fill="#E2001A" rx={32} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M26.023 32.06c.6.6 1.573.6 2.174 0l4.16-4.16 4.16 4.16a1.537 1.537 0 0 0 2.173-2.173l-5.246-5.247c-.6-.6-1.574-.6-2.174 0l-5.247 5.247c-.6.6-.6 1.573 0 2.173Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M32.358 48.251c.849 0 1.537-.688 1.537-1.537V25.87a1.537 1.537 0 0 0-3.074 0v20.844c0 .849.688 1.537 1.537 1.537Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M17.787 34.04c.85 0 1.537-.688 1.537-1.537 0-3.58 1.212-6.739 3.353-8.993 2.13-2.241 5.268-3.683 9.323-3.683 4.055 0 7.194 1.442 9.323 3.683 2.14 2.254 3.353 5.414 3.353 8.993a1.537 1.537 0 1 0 3.074 0c0-4.273-1.453-8.22-4.198-11.11-2.756-2.902-6.724-4.64-11.552-4.64-4.828 0-8.796 1.738-11.552 4.64-2.745 2.89-4.198 6.837-4.198 11.11 0 .849.688 1.537 1.537 1.537Z"
      clipRule="evenodd"
    />
  </svg>
);
